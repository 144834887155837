import React from "react";
import { Checkbox, Button, Select, SelectItem } from "@nextui-org/react";

const Roleaccess = () => {
    return (
        <div className="main-inner-wrapper leavemenu-wrapper">
            <div className="px-4">
                <div 
                    className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl"
                >
                    <div 
                        style={{ 
                            display: "flex", 
                            justifyContent: "space-between", 
                            alignItems: "center", 
                            overflow: "hidden" 
                        }}
                    >
                        <h1 
                            className="report-heading" 
                            style={{ 
                                marginLeft: "0px", 
                                whiteSpace: "nowrap", 
                                // overflow: "hidden", 
                                // textOverflow: "ellipsis" 
                            }}
                        >
                            Role Permission
                        </h1>
                        <Select
                            style={{ 
                                marginLeft: "500px", 
                                maxWidth: "300px" 
                            }}
                            label="Select Role"
                            className="custom-select"
                            labelPlacement="outside"
                            variant="bordered"
                            fullWidth
                        >
                            <SelectItem key="Admin" value="Admin">
                                Administrative Staff
                            </SelectItem>
                            <SelectItem key="Purchase" value="Purchase">
                                Examination Officer
                            </SelectItem>
                            <SelectItem key="Accounts" value="Accounts">
                                Head of Department
                            </SelectItem>
                            <SelectItem key="Accounts" value="Accounts">
                                Coordinator
                            </SelectItem>
                            <SelectItem key="Accounts" value="Accounts">
                                Professor
                            </SelectItem>
                            <SelectItem key="Accounts" value="Accounts">
                                Assistant Professor
                            </SelectItem>
                            <SelectItem key="Accounts" value="Accounts">
                                Lecturer
                            </SelectItem>
                            <SelectItem key="Accounts" value="Accounts">
                                Lab Assistant
                            </SelectItem>
                        </Select>
                    </div>

                    <div className="form-container">
                        <form>
                            <div className="mb-6">
                                <div className="flex flex-col gap-1 font-bold">Organization</div>
                                <div className="form-row space-x-4">
                                    <Checkbox>Edit</Checkbox>
                                    <Checkbox>View</Checkbox>
                                </div>
                            </div>

                            <div className="mb-6">
                                <div className="flex flex-col gap-1 font-bold">Department</div>
                                <div className="form-row space-x-4">
                                    <Checkbox>Create</Checkbox>
                                    <Checkbox>Edit</Checkbox>
                                    <Checkbox>View</Checkbox>
                                    <Checkbox>Delete</Checkbox>
                                </div>
                            </div>

                            <div className="mb-6">
                                <div className="flex flex-col gap-1 font-bold">Role</div>
                                <div className="form-row space-x-4">
                                    <Checkbox>Create</Checkbox>
                                    <Checkbox>Edit</Checkbox>
                                    <Checkbox>View</Checkbox>
                                    <Checkbox>Delete</Checkbox>
                                </div>
                            </div>

                            <div className="mb-6">
                                <div className="flex flex-col gap-1 font-bold">Staff Creation</div>
                                <div className="form-row space-x-4">
                                    <Checkbox>Create</Checkbox>
                                    <Checkbox>Edit</Checkbox>
                                    <Checkbox>View</Checkbox>
                                    <Checkbox>Delete</Checkbox>
                                </div>
                            </div>

                            <div className="mb-6">
                                <div className="flex flex-col gap-1 font-bold">Student Creation</div>
                                <div className="form-row space-x-4">
                                    <Checkbox>Create</Checkbox>
                                    <Checkbox>Edit</Checkbox>
                                    <Checkbox>View</Checkbox>
                                    <Checkbox>Delete</Checkbox>
                                </div>
                            </div>

                            <div className="mb-6">
                                <div className="flex flex-col gap-1 font-bold">Internal Mark Setup</div>
                                <div className="form-row space-x-4">
                                    <Checkbox>Create</Checkbox>
                                    <Checkbox>Edit</Checkbox>
                                    <Checkbox>View</Checkbox>
                                    <Checkbox>Delete</Checkbox>
                                </div>
                            </div>

                            <div className="mb-6">
                                <div className="flex flex-col gap-1 font-bold">External mark setup</div>
                                <div className="form-row space-x-4">
                                    <Checkbox>Create</Checkbox>
                                    <Checkbox>Edit</Checkbox>
                                    <Checkbox>View</Checkbox>
                                    <Checkbox>Delete</Checkbox>
                                </div>
                            </div>

                            <div className="mb-6">
                                <div className="flex flex-col gap-1 font-bold">Fee setup </div>
                                <div className="form-row space-x-4">
                                    <Checkbox>Create</Checkbox>
                                    <Checkbox>Edit</Checkbox>
                                    <Checkbox>View</Checkbox>
                                    <Checkbox>Delete</Checkbox>
                                </div>
                            </div>

                            <div className="mb-6">
                                <div className="flex flex-col gap-1 font-bold">Payment</div>
                                <div className="form-row space-x-4">
                                    <Checkbox>Create</Checkbox>
                                    <Checkbox>Edit</Checkbox>
                                    <Checkbox>View</Checkbox>
                                    <Checkbox>Delete</Checkbox>
                                </div>
                            </div>

                            <div className="flex justify-center items-center gap-4 mt-4">
                                <Button color="danger" variant="flat">
                                    Cancel
                                </Button>
                                <Button color="primary">
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Roleaccess;
