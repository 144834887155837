import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Select, SelectItem, Input } from "@nextui-org/react";
import "../components/baseComponent/ModelForm.css";

const DownloadStudent = () => {
    const navigate = useNavigate();
    const [selectedDownload, setSelectedDownload] = useState("")
    const [pdfUrl, setPdfUrl] = useState(""); // State to hold the PDF URL


    useEffect(() => {
        const handleSelectChange = () => {
            const collegeName = localStorage.getItem('UserCollege');
            let pdfUrl = "";

            if (selectedDownload === "Download_Hall_Ticket") {
                switch (collegeName) {
                    case "SCE":
                        pdfUrl = `${process.env.PUBLIC_URL}/Hall_Ticket_Saranathan.pdf`;
                        break;
                    case "CGI":
                        pdfUrl = `${process.env.PUBLIC_URL}/Hall_Ticket_CARE.pdf`;
                        break;
                    case "MAM":
                        pdfUrl = `${process.env.PUBLIC_URL}/Hall_Ticket_MAM.pdf`;
                        break;
                    case "MIET":
                        pdfUrl = `${process.env.PUBLIC_URL}/Hall_Ticket_MIET.pdf`;
                        break;
                    case "Vels":
                        pdfUrl = `${process.env.PUBLIC_URL}/Hall_Ticket_VELS.pdf`;
                        break;
                }
            } else if (selectedDownload === "Download_Result") {
                switch (collegeName) {
                    case "SCE":
                        pdfUrl = `${process.env.PUBLIC_URL}/result_saranathan.pdf`;
                        break;
                    case "CGI":
                        pdfUrl = `${process.env.PUBLIC_URL}/result_care.pdf`;
                        break;
                    case "MAM":
                        pdfUrl = `${process.env.PUBLIC_URL}/result_mam.pdf`;
                        break;
                    case "MIET":
                        pdfUrl = `${process.env.PUBLIC_URL}/Result_miet.pdf`;
                        break;
                    case "Vels":
                        pdfUrl = `${process.env.PUBLIC_URL}/Result_vels.pdf`;
                        break;
                }
            } else if (selectedDownload === "Download_TimeTable") {
                pdfUrl = `${process.env.PUBLIC_URL}/timetable.pdf`;
            } else {
                pdfUrl = "";
            }

            setPdfUrl(pdfUrl);
        };

        if (selectedDownload) {
            handleSelectChange();
        } else {
            setPdfUrl(""); // Clear the PDF URL if no valid selection is made
        }

    }, [selectedDownload]);

    const handleDownloadClick = () => {
        if (selectedDownload == "Download_TimeTable") {
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = `timetable.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else {
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = `${localStorage.getItem('UserCollege')}_${selectedDownload}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };


    return (
        <div className="main-inner-wrapper leavemenu-wrapper">
            <div className="px-4">
                <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
                    <h1 className="report-heading" style={{ marginLeft: "0px", marginBottom: "10px", fontSize: "25px", fontWeight: "bold" }}>
                        Download
                    </h1>
                    <form>

                        <div className="form-row">
                            <Select label="Select Download" labelPlacement="outside" className={`max-w-[250px]`} variant="bordered">
                                <SelectItem onClick={_ => setSelectedDownload("Download_Result")} key="Download_Result" value="Download_Result">Download Result</SelectItem>
                                <SelectItem onClick={_ => setSelectedDownload("Download_Hall_Ticket")} key="Download_Hall_Ticket" value="Download_Hall_Ticket">Download Hall Ticket</SelectItem>
                                <SelectItem onClick={_ => setSelectedDownload("Download_TimeTable")} key="Download_TimeTable" value="Download_TimeTable">Download TimeTable</SelectItem>
                                <SelectItem onClick={_ => setSelectedDownload("")} key="Download_Consolidate_Marksheet" value="Download_Consolidate_Marksheet">Download Consolidate Marksheet</SelectItem>
                            </Select>

                            <Button className="bg-[#36A2EB] max-w-[200px] mt-6 font-bold text-white" onClick={handleDownloadClick}>
                                Download
                            </Button>
                        </div>

                        <div>
                            {pdfUrl && (
                                <div className="pdf-preview">
                                    <iframe
                                        src={pdfUrl}
                                        width="100%"
                                        height="600px"
                                        title="PDF Preview"
                                    ></iframe>
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default DownloadStudent;
