const calculateGrade = (marks) => {
  if (marks >= 90) {
    return 'O Grade';
  } else if (marks >= 80 && marks <= 89) {
    return 'A Grade';
  } else if (marks >= 70 && marks <= 79) {
    return 'B Grade';
  } else if (marks >= 60 && marks <= 69) {
    return 'C Grade';
  } else if (marks >= 50 && marks <= 59) {
    return 'D Grade';
  } else if (marks < 50) {
    return 'Fail';
  } else {
    return ''; // In case marks are invalid
  }
};

const departments = [
  {
    "name": "Civil",
    "years": [
      {
        "year": 1,
        "semesters": [
          {
            "semester": 1,
            "subjects": [
              {
                "dummyNo": [
                  "KHSAD1001", "KHSAD1002", "KHSAD1003", "KHSAD1004", "KHSAD1005",
                  "KHSAD1006", "KHSAD1007", "KHSAD1008", "KHSAD1009", "KHSAD1010"
                ],
                "code": "CIV101",
                "name": "Structural Analysis"
              },
              {
                "dummyNo": [
                  "IJGES1001", "IJGES1002", "IJGES1003", "IJGES1004", "IJGES1005",
                  "IJGES1006", "IJGES1007", "IJGES1008", "IJGES1009", "IJGES1010"
                ],
                "code": "CIV102",
                "name": "Geotechnical Engineering"
              }
            ]
          },
          {
            "semester": 2,
            "subjects": [
              {
                "dummyNo": [
                  "LKHDP1001", "LKHDP1002", "LKHDP1003", "LKHDP1004", "LKHDP1005",
                  "LKHDP1006", "LKHDP1007", "LKHDP1008", "LKHDP1009", "LKHDP1010"
                ],
                "code": "CIV103",
                "name": "Hydraulics"
              },
              {
                "dummyNo": [
                  "QXTER1001", "QXTER1002", "QXTER1003", "QXTER1004", "QXTER1005",
                  "QXTER1006", "QXTER1007", "QXTER1008", "QXTER1009", "QXTER1010"
                ],
                "code": "CIV104",
                "name": "Transportation Engineering"
              },
              {
                "dummyNo": [
                  "WTEEV1001", "WTEEV1002", "WTEEV1003", "WTEEV1004", "WTEEV1005",
                  "WTEEV1006", "WTEEV1007", "WTEEV1008", "WTEEV1009", "WTEEV1010"
                ],
                "code": "CIV105",
                "name": "Environmental Engineering"
              }
            ]
          }
        ]
      },
      {
        "year": 2,
        "semesters": [
          {
            "semester": 3,
            "subjects": [
              {
                "dummyNo": [
                  "RFSVT1001", "RFSVT1002", "RFSVT1003", "RFSVT1004", "RFSVT1005",
                  "RFSVT1006", "RFSVT1007", "RFSVT1008", "RFSVT1009", "RFSVT1010"
                ],
                "code": "CIV106",
                "name": "Surveying"
              },
              {
                "dummyNo": [
                  "MBCTP1001", "MBCTP1002", "MBCTP1003", "MBCTP1004", "MBCTP1005",
                  "MBCTP1006", "MBCTP1007", "MBCTP1008", "MBCTP1009", "MBCTP1010"
                ],
                "code": "CIV107",
                "name": "Concrete Technology"
              }
            ]
          },
          {
            "semester": 4,
            "subjects": [
              {
                "dummyNo": [
                  "VHSDQ1001", "VHSDQ1002", "VHSDQ1003", "VHSDQ1004", "VHSDQ1005",
                  "VHSDQ1006", "VHSDQ1007", "VHSDQ1008", "VHSDQ1009", "VHSDQ1010"
                ],
                "code": "CIV108",
                "name": "Structural Dynamics"
              },
              {
                "dummyNo": [
                  "ZRCMW1001", "ZRCMW1002", "ZRCMW1003", "ZRCMW1004", "ZRCMW1005",
                  "ZRCMW1006", "ZRCMW1007", "ZRCMW1008", "ZRCMW1009", "ZRCMW1010"
                ],
                "code": "CIV109",
                "name": "Construction Management"
              },
              {
                "dummyNo": [
                  "XWREP1001", "XWREP1002", "XWREP1003", "XWREP1004", "XWREP1005",
                  "XWREP1006", "XWREP1007", "XWREP1008", "XWREP1009", "XWREP1010"
                ],
                "code": "CIV110",
                "name": "Water Resources Engineering"
              }
            ]
          }
        ]
      },
      {
        "year": 3,
        "semesters": [
          {
            "semester": 5,
            "subjects": [
              {
                "dummyNo": [
                  "HACTS1001", "HACTS1002", "HACTS1003", "HACTS1004", "HACTS1005",
                  "HACTS1006", "HACTS1007", "HACTS1008", "HACTS1009", "HACTS1010"
                ],
                "code": "CIV201",
                "name": "Advanced Concrete Technology"
              },
              {
                "dummyNo": [
                  "LWBMK1001", "LWBMK1002", "LWBMK1003", "LWBMK1004", "LWBMK1005",
                  "LWBMK1006", "LWBMK1007", "LWBMK1008", "LWBMK1009", "LWBMK1010"
                ],
                "code": "CIV202",
                "name": "Building Materials"
              }
            ]
          },
          {
            "semester": 6,
            "subjects": [
              {
                "dummyNo": [
                  "DPT1001", "DPT1002", "DPT1003", "DPT1004", "DPT1005",
                  "DPT1006", "DPT1007", "DPT1008", "DPT1009", "DPT1010"
                ],
                "code": "CIV203",
                "name": "Project Management"
              },
              {
                "dummyNo": [
                  "FHQ1001", "FHQ1002", "FHQ1003", "FHQ1004", "FHQ1005",
                  "FHQ1006", "FHQ1007", "FHQ1008", "FHQ1009", "FHQ1010"
                ],
                "code": "CIV204",
                "name": "Urban Planning"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "name": "Mechanical",
    "years": [
      {
        "year": 1,
        "semesters": [
          {
            "semester": 1,
            "subjects": [
              {
                "dummyNo": [
                  "JXH1001", "JXH1002", "JXH1003", "JXH1004", "JXH1005",
                  "JXH1006", "JXH1007", "JXH1008", "JXH1009", "JXH1010"
                ],
                "code": "MECH101",
                "name": "Engineering Mechanics"
              },
              {
                "dummyNo": [
                  "QTP1001", "QTP1002", "QTP1003", "QTP1004", "QTP1005",
                  "QTP1006", "QTP1007", "QTP1008", "QTP1009", "QTP1010"
                ],
                "code": "MECH102",
                "name": "Thermodynamics"
              }
            ]
          },
          {
            "semester": 2,
            "subjects": [
              {
                "dummyNo": [
                  "XYP1001", "XYP1002", "XYP1003", "XYP1004", "XYP1005",
                  "XYP1006", "XYP1007", "XYP1008", "XYP1009", "XYP1010"
                ],
                "code": "MECH103",
                "name": "Fluid Mechanics"
              },
              {
                "dummyNo": [
                  "RZN2001", "RZN2002", "RZN2003", "RZN2004", "RZN2005",
                  "RZN2006", "RZN2007", "RZN2008", "RZN2009", "RZN2010"
                ],
                "code": "MECH104",
                "name": "Engineering Drawing"
              },
              {
                "dummyNo": [
                  "LQJ3001", "LQJ3002", "LQJ3003", "LQJ3004", "LQJ3005",
                  "LQJ3006", "LQJ3007", "LQJ3008", "LQJ3009", "LQJ3010"
                ],
                "code": "MECH105",
                "name": "Manufacturing Processes"
              }
            ]
          }
        ]
      },
      {
        "year": 2,
        "semesters": [
          {
            "semester": 3,
            "subjects": [
              {
                "dummyNo": [
                  "MGB4001", "MGB4002", "MGB4003", "MGB4004", "MGB4005",
                  "MGB4006", "MGB4007", "MGB4008", "MGB4009", "MGB4010"
                ],
                "code": "MECH106",
                "name": "Heat Transfer"
              },
              {
                "dummyNo": [
                  "KYT5001", "KYT5002", "KYT5003", "KYT5004", "KYT5005",
                  "KYT5006", "KYT5007", "KYT5008", "KYT5009", "KYT5010"
                ],
                "code": "MECH107",
                "name": "Machine Design"
              }
            ]
          },
          {
            "semester": 4,
            "subjects": [
              {
                "dummyNo": [
                  "VFR6001", "VFR6002", "VFR6003", "VFR6004", "VFR6005",
                  "VFR6006", "VFR6007", "VFR6008", "VFR6009", "VFR6010"
                ],
                "code": "MECH108",
                "name": "Materials Science"
              },
              {
                "dummyNo": [
                  "BZW7001", "BZW7002", "BZW7003", "BZW7004", "BZW7005",
                  "BZW7006", "BZW7007", "BZW7008", "BZW7009", "BZW7010"
                ],
                "code": "MECH109",
                "name": "Dynamics of Machinery"
              },
              {
                "dummyNo": [
                  "NDH8001", "NDH8002", "NDH8003", "NDH8004", "NDH8005",
                  "NDH8006", "NDH8007", "NDH8008", "NDH8009", "NDH8010"
                ],
                "code": "MECH110",
                "name": "Manufacturing Technology"
              }
            ]
          }
        ]
      },
      {
        "year": 3,
        "semesters": [
          {
            "semester": 5,
            "subjects": [
              {
                "dummyNo": [
                  "JPL9001", "JPL9002", "JPL9003", "JPL9004", "JPL9005",
                  "JPL9006", "JPL9007", "JPL9008", "JPL9009", "JPL9010"
                ],
                "code": "MECH201",
                "name": "Refrigeration and Air Conditioning"
              },
              {
                "dummyNo": [
                  "QST1001", "QST1002", "QST1003", "QST1004", "QST1005",
                  "QST1006", "QST1007", "QST1008", "QST1009", "QST1010"
                ],
                "code": "MECH202",
                "name": "Automobile Engineering"
              }
            ]
          },
          {
            "semester": 6,
            "subjects": [
              {
                "dummyNo": [
                  "RFZ1101", "RFZ1102", "RFZ1103", "RFZ1104", "RFZ1105",
                  "RFZ1106", "RFZ1107", "RFZ1108", "RFZ1109", "RFZ1110"
                ],
                "code": "MECH203",
                "name": "Control Systems"
              },
              {
                "dummyNo": [
                  "GJY1201", "GJY1202", "GJY1203", "GJY1204", "GJY1205",
                  "GJY1206", "GJY1207", "GJY1208", "GJY1209", "GJY1210"
                ],
                "code": "MECH204",
                "name": "Robotics"
              },
              {
                "dummyNo": [
                  "TBL1301", "TBL1302", "TBL1303", "TBL1304", "TBL1305",
                  "TBL1306", "TBL1307", "TBL1308", "TBL1309", "TBL1310"
                ],
                "code": "MECH205",
                "name": "Power Plant Engineering"
              }
            ]
          }
        ]
      },
      {
        "year": 4,
        "semesters": [
          {
            "semester": 7,
            "subjects": [
              {
                "dummyNo": [
                  "CKD1401", "CKD1402", "CKD1403", "CKD1404", "CKD1405",
                  "CKD1406", "CKD1407", "CKD1408", "CKD1409", "CKD1410"
                ],
                "code": "MECH206",
                "name": "Finite Element Analysis"
              },
              {
                "dummyNo": [
                  "LPW1501", "LPW1502", "LPW1503", "LPW1504", "LPW1505",
                  "LPW1506", "LPW1507", "LPW1508", "LPW1509", "LPW1510"
                ],
                "code": "MECH207",
                "name": "Advanced Machine Design"
              }
            ]
          },
          {
            "semester": 8,
            "subjects": [
              {
                "dummyNo": [
                  "XDJ1601", "XDJ1602", "XDJ1603", "XDJ1604", "XDJ1605",
                  "XDJ1606", "XDJ1607", "XDJ1608", "XDJ1609", "XDJ1610"
                ],
                "code": "MECH208",
                "name": "Industrial Engineering"
              },
              {
                "dummyNo": [
                  "SPO1701", "SPO1702", "SPO1703", "SPO1704", "SPO1705",
                  "SPO1706", "SPO1707", "SPO1708", "SPO1709", "SPO1710"
                ],
                "code": "MECH209",
                "name": "Engineering Management"
              },
              {
                "dummyNo": [
                  "ZTR1801", "ZTR1802", "ZTR1803", "ZTR1804", "ZTR1805",
                  "ZTR1806", "ZTR1807", "ZTR1808", "ZTR1809", "ZTR1810"
                ],
                "code": "MECH210",
                "name": "Thermal Engineering"
              }
            ]
          }
        ]
      }
    ]
  },
  {
      name: "CSE",
      years: [
          {
              year: 1,
              semesters: [
                  {
                      semester: 1,
                      subjects: [
                          { 
                              dummyNo: [
                                  "IPR1001", "IPR1002", "IPR1003", "IPR1004", "IPR1005", 
                                  "IPR1006", "IPR1007", "IPR1008", "IPR1009", "IPR1010"
                              ], 
                              code: "CSE101", 
                              name: "Introduction to Programming" 
                          },
                          { 
                              dummyNo: [
                                  "COG2001", "COG2002", "COG2003", "COG2004", "COG2005", 
                                  "COG2006", "COG2007", "COG2008", "COG2009", "COG2010"
                              ], 
                              code: "CSE102", 
                              name: "Computer Organization" 
                          }
                      ]
                  },
                  {
                      semester: 2,
                      subjects: [
                          { 
                              dummyNo: [
                                  "DM1011", "DM1012", "DM1013", "DM1014", "DM1015", 
                                  "DM1016", "DM1017", "DM1018", "DM1019", "DM1020"
                              ], 
                              code: "CSE103", 
                              name: "Discrete Mathematics" 
                          },
                          { 
                              dummyNo: [
                                  "DBS1021", "DBS1022", "DBS1023", "DBS1024", "DBS1025", 
                                  "DBS1026", "DBS1027", "DBS1028", "DBS1029", "DBS1030"
                              ], 
                              code: "CSE104", 
                              name: "Database Management Systems" 
                          },
                          { 
                              dummyNo: [
                                  "OS3031", "OS3032", "OS3033", "OS3034", "OS3035", 
                                  "OS3036", "OS3037", "OS3038", "OS3039", "OS3040"
                              ], 
                              code: "CSE105", 
                              name: "Operating Systems" 
                          }
                      ]
                  }
              ]
          },
          {
              year: 2,
              semesters: [
                  {
                      semester: 3,
                      subjects: [
                          { 
                              dummyNo: [
                                  "DS4051", "DS4052", "DS4053", "DS4054", "DS4055", 
                                  "DS4056", "DS4057", "DS4058", "DS4059", "DS4060"
                              ], 
                              code: "CSE106", 
                              name: "Data Structures" 
                          },
                          { 
                              dummyNo: [
                                  "ALG5071", "ALG5072", "ALG5073", "ALG5074", "ALG5075", 
                                  "ALG5076", "ALG5077", "ALG5078", "ALG5079", "ALG5080"
                              ], 
                              code: "CSE107", 
                              name: "Algorithms" 
                          }
                      ]
                  },
                  {
                      semester: 4,
                      subjects: [
                          { 
                              dummyNo: [
                                  "SE6091", "SE6092", "SE6093", "SE6094", "SE6095", 
                                  "SE6096", "SE6097", "SE6098", "SE6099", "SE6100"
                              ], 
                              code: "CSE108", 
                              name: "Software Engineering" 
                          },
                          { 
                              dummyNo: [
                                  "CN7101", "CN7102", "CN7103", "CN7104", "CN7105", 
                                  "CN7106", "CN7107", "CN7108", "CN7109", "CN7110"
                              ], 
                              code: "CSE109", 
                              name: "Computer Networks" 
                          },
                          { 
                              dummyNo: [
                                  "TOC8121", "TOC8122", "TOC8123", "TOC8124", "TOC8125", 
                                  "TOC8126", "TOC8127", "TOC8128", "TOC8129", "TOC8130"
                              ], 
                              code: "CSE110", 
                              name: "Theory of Computation" 
                          }
                      ]
                  }
              ]
          },
          {
              year: 3,
              semesters: [
                  {
                      semester: 5,
                      subjects: [
                          { 
                              dummyNo: [
                                  "OSD9131", "OSD9132", "OSD9133", "OSD9134", "OSD9135", 
                                  "OSD9136", "OSD9137", "OSD9138", "OSD9139", "OSD9140"
                              ], 
                              code: "CSE201", 
                              name: "Operating Systems Design" 
                          },
                          { 
                              dummyNo: [
                                  "DBD1011", "DBD1012", "DBD1013", "DBD1014", "DBD1015", 
                                  "DBD1016", "DBD1017", "DBD1018", "DBD1019", "DBD1020"
                              ], 
                              code: "CSE202", 
                              name: "Database Design" 
                          }
                      ]
                  },
                  {
                      semester: 6,
                      subjects: [
                          { 
                              dummyNo: [
                                  "AI1121", "AI1122", "AI1123", "AI1124", "AI1125", 
                                  "AI1126", "AI1127", "AI1128", "AI1129", "AI1130"
                              ], 
                              code: "CSE203", 
                              name: "Artificial Intelligence" 
                          },
                          { 
                              dummyNo: [
                                  "WT2131", "WT2132", "WT2133", "WT2134", "WT2135", 
                                  "WT2136", "WT2137", "WT2138", "WT2139", "WT2140"
                              ], 
                              code: "CSE204", 
                              name: "Web Technologies" 
                          },
                          { 
                              dummyNo: [
                                  "CYB3141", "CYB3142", "CYB3143", "CYB3144", "CYB3145", 
                                  "CYB3146", "CYB3147", "CYB3148", "CYB3149", "CYB3150"
                              ], 
                              code: "CSE205", 
                              name: "Cybersecurity" 
                          }
                      ]
                  }
              ]
          },
          {
              year: 4,
              semesters: [
                  {
                      semester: 7,
                      subjects: [
                          { 
                              dummyNo: [
                                  "ML4151", "ML4152", "ML4153", "ML4154", "ML4155", 
                                  "ML4156", "ML4157", "ML4158", "ML4159", "ML4160"
                              ], 
                              code: "CSE206", 
                              name: "Machine Learning" 
                          },
                          { 
                              dummyNo: [
                                  "BDA5161", "BDA5162", "BDA5163", "BDA5164", "BDA5165", 
                                  "BDA5166", "BDA5167", "BDA5168", "BDA5169", "BDA5170"
                              ], 
                              code: "CSE207", 
                              name: "Big Data Analytics" 
                          }
                      ]
                  },
                  {
                      semester: 8,
                      subjects: [
                          { 
                              dummyNo: [
                                  "CC6181", "CC6182", "CC6183", "CC6184", "CC6185", 
                                  "CC6186", "CC6187", "CC6188", "CC6189", "CC6190"
                              ], 
                              code: "CSE208", 
                              name: "Cloud Computing" 
                          },
                          { 
                              dummyNo: [
                                  "HCI7191", "HCI7192", "HCI7193", "HCI7194", "HCI7195", 
                                  "HCI7196", "HCI7197", "HCI7198", "HCI7199", "HCI7200"
                              ], 
                              code: "CSE209", 
                              name: "Human-Computer Interaction" 
                          },
                          { 
                              dummyNo: [
                                  "PW8201", "PW8202", "PW8203", "PW8204", "PW8205", 
                                  "PW8206", "PW8207", "PW8208", "PW8209", "PW8210"
                              ], 
                              code: "CSE210", 
                              name: "Project Work" 
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      name: "EEE",
      years: [
          {
              year: 1,
              semesters: [
                  {
                      semester: 1,
                      subjects: [
                          { 
                              dummyNo: [
                                  "BEE0001", "BEE0002", "BEE0003", "BEE0004", "BEE0005", 
                                  "BEE0006", "BEE0007", "BEE0008", "BEE0009", "BEE0010"
                              ], 
                              code: "EEE101", 
                              name: "Basic Electrical Engineering" 
                          },
                          { 
                              dummyNo: [
                                  "CTY0101", "CTY0102", "CTY0103", "CTY0104", "CTY0105", 
                                  "CTY0106", "CTY0107", "CTY0108", "CTY0109", "CTY0110"
                              ], 
                              code: "EEE102", 
                              name: "Circuit Theory" 
                          }
                      ]
                  },
                  {
                      semester: 2,
                      subjects: [
                          { 
                              dummyNo: [
                                  "ELC0201", "ELC0202", "ELC0203", "ELC0204", "ELC0205", 
                                  "ELC0206", "ELC0207", "ELC0208", "ELC0209", "ELC0210"
                              ], 
                              code: "EEE103", 
                              name: "Electronics" 
                          },
                          { 
                              dummyNo: [
                                  "EMF0301", "EMF0302", "EMF0303", "EMF0304", "EMF0305", 
                                  "EMF0306", "EMF0307", "EMF0308", "EMF0309", "EMF0310"
                              ], 
                              code: "EEE104", 
                              name: "Electromagnetic Fields" 
                          },
                          { 
                              dummyNo: [
                                  "ELM0401", "ELM0402", "ELM0403", "ELM0404", "ELM0405", 
                                  "ELM0406", "ELM0407", "ELM0408", "ELM0409", "ELM0410"
                              ], 
                              code: "EEE105", 
                              name: "Electrical Machines" 
                          }
                      ]
                  }
              ]
          },
          {
              year: 2,
              semesters: [
                  {
                      semester: 3,
                      subjects: [
                          { 
                              dummyNo: [
                                  "PWS0501", "PWS0502", "PWS0503", "PWS0504", "PWS0505", 
                                  "PWS0506", "PWS0507", "PWS0508", "PWS0509", "PWS0510"
                              ], 
                              code: "EEE106", 
                              name: "Power Systems" 
                          },
                          { 
                              dummyNo: [
                                  "CSY0601", "CSY0602", "CSY0603", "CSY0604", "CSY0605", 
                                  "CSY0606", "CSY0607", "CSY0608", "CSY0609", "CSY0610"
                              ], 
                              code: "EEE107", 
                              name: "Control Systems" 
                          }
                      ]
                  },
                  {
                      semester: 4,
                      subjects: [
                          { 
                              dummyNo: [
                                  "DEL0701", "DEL0702", "DEL0703", "DEL0704", "DEL0705", 
                                  "DEL0706", "DEL0707", "DEL0708", "DEL0709", "DEL0710"
                              ], 
                              code: "EEE108", 
                              name: "Digital Electronics" 
                          },
                          { 
                              dummyNo: [
                                  "SAS0801", "SAS0802", "SAS0803", "SAS0804", "SAS0805", 
                                  "SAS0806", "SAS0807", "SAS0808", "SAS0809", "SAS0810"
                              ], 
                              code: "EEE109", 
                              name: "Signals and Systems" 
                          },
                          { 
                              dummyNo: [
                                  "MPU0901", "MPU0902", "MPU0903", "MPU0904", "MPU0905", 
                                  "MPU0906", "MPU0907", "MPU0908", "MPU0909", "MPU0910"
                              ], 
                              code: "EEE110", 
                              name: "Microprocessors" 
                          }
                      ]
                  }
              ]
          },
          {
              year: 3,
              semesters: [
                  {
                      semester: 5,
                      subjects: [
                          { 
                              dummyNo: [
                                  "PEP1001", "PEP1002", "PEP1003", "PEP1004", "PEP1005", 
                                  "PEP1006", "PEP1007", "PEP1008", "PEP1009", "PEP1010"
                              ], 
                              code: "EEE201", 
                              name: "Power Electronics" 
                          },
                          { 
                              dummyNo: [
                                  "RES1101", "RES1102", "RES1103", "RES1104", "RES1105", 
                                  "RES1106", "RES1107", "RES1108", "RES1109", "RES1110"
                              ], 
                              code: "EEE202", 
                              name: "Renewable Energy Systems" 
                          }
                      ]
                  },
                  {
                      semester: 6,
                      subjects: [
                          { 
                              dummyNo: [
                                  "EPD1201", "EPD1202", "EPD1203", "EPD1204", "EPD1205", 
                                  "EPD1206", "EPD1207", "EPD1208", "EPD1209", "EPD1210"
                              ], 
                              code: "EEE203", 
                              name: "Electrical Power Distribution" 
                          },
                          { 
                              dummyNo: [
                                  "INS1301", "INS1302", "INS1303", "INS1304", "INS1305", 
                                  "INS1306", "INS1307", "INS1308", "INS1309", "INS1310"
                              ], 
                              code: "EEE204", 
                              name: "Instrumentation" 
                          },
                          { 
                              dummyNo: [
                                  "EDR1401", "EDR1402", "EDR1403", "EDR1404", "EDR1405", 
                                  "EDR1406", "EDR1407", "EDR1408", "EDR1409", "EDR1410"
                              ], 
                              code: "EEE205", 
                              name: "Electrical Drives" 
                          }
                      ]
                  }
              ]
          },
          {
              year: 4,
              semesters: [
                  {
                      semester: 7,
                      subjects: [
                          { 
                              dummyNo: [
                                  "APS1501", "APS1502", "APS1503", "APS1504", "APS1505", 
                                  "APS1506", "APS1507", "APS1508", "APS1509", "APS1510"
                              ], 
                              code: "EEE206", 
                              name: "Advanced Power Systems" 
                          },
                          { 
                              dummyNo: [
                                  "HV1611", "HV1612", "HV1613", "HV1614", "HV1615", 
                                  "HV1616", "HV1617", "HV1618", "HV1619", "HV1620"
                              ], 
                              code: "EEE207", 
                              name: "High Voltage Engineering" 
                          }
                      ]
                  },
                  {
                      semester: 8,
                      subjects: [
                          { 
                              dummyNo: [
                                  "ES1701", "ES1702", "ES1703", "ES1704", "ES1705", 
                                  "ES1706", "ES1707", "ES1708", "ES1709", "ES1710"
                              ], 
                              code: "EEE208", 
                              name: "Embedded Systems" 
                          },
                          { 
                              dummyNo: [
                                  "SG1801", "SG1802", "SG1803", "SG1804", "SG1805", 
                                  "SG1806", "SG1807", "SG1808", "SG1809", "SG1810"
                              ], 
                              code: "EEE209", 
                              name: "Smart Grids" 
                          },
                          { 
                              dummyNo: [
                                  "PW1901", "PW1902", "PW1903", "PW1904", "PW1905", 
                                  "PW1906", "PW1907", "PW1908", "PW1909", "PW1910"
                              ], 
                              code: "EEE210", 
                              name: "Project Work" 
                          }
                      ]
                  }
              ]
          }
      ]
  }
];

const incidentintegrationcolumn = [
  { name: " REPORT NUMBER", uid: "report_number", sortable: true },
  { name: "DEPARTMENT", uid: "department", sortable: true },
  { name: "DATE&TIME", uid: "date_time", sortable: true },
  { name: "REPORTED BY", uid: "reported_by", sortable: true },
  { name: "INCIDENT TYPE", uid: "incident_type" },
  { name: "RESPONSIBLE EMPLOYEE", uid: "witness", sortable: true },
  { name: "ACTIONS", uid: "actions" },
]
const incidentintegrationlist = [
  {
    id: 1,
    report_number: "INC001",
    department: "Manufacturing",
    date_time: "26-04-2024 08:00 AM",
    reported_by: "Olivia",
    incident_type: "Defibrillator Mishandling",
    witness: "John",
  },
  {
    id: 1,
    report_number: "INC002",
    department: "Pharmaceutical",
    date_time: "13-04-2024 12:33 PM",
    reported_by: "Olivia",
    incident_type: "Contaminated Supplies Issue",
    witness: "SMITH",
  }
]

const Usercolumn = [
  { name: "Name", uid: "user_name", sortable: true },
  { name: "Email", uid: "email", sortable: true },
  { name: "Phone Number", uid: "phone_number", sortable: true },
  { name: "Department", uid: "department", sortable: true },
  { name: "Role", uid: "role", sortable: true },
  { name: "ACTIONS", uid: "actions" },

]
const Userlist = [
  {
    id: 1,
    user_name: "Raja",
    email: "raja@gmail.com",
    phone_number: "8766789986",
    department: "Research",
    role: "Clinical Research Associate",
  },
]



const Hallcolumn = [
  { name: "YEAR", uid: "year", sortable: true },
  { name: "SEMESTER", uid: "hall_sem", sortable: true },
  { name: "DEPARTMENT", uid: "department", sortable: true },
  { name: "TOTAL  STUDENT", uid: "total_student", sortable: true },
  { name: "STUDENTS PAID", uid: "fee_paid", sortable: true },
  { name: "STUDENTS UNPAID", uid: "fee_unpaid", sortable: true },
  { name: "ACTIONS", uid: "actions" },

]
const Halllist = [
  {
    id: 1,
    year: "1",
    hall_sem: "2",
    department: "B.Tech Electronics and Communication Engineering",
    total_student: "90",
    fee_paid: "80",
    fee_unpaid: "10",
  },
  {
    id: 2,
    year: "2",
    hall_sem: "4",
    department: "B.E Computer Science Engineering",
    total_student: "70",
    fee_paid: "65",
    fee_unpaid: "05",

  },
  {
    id: 3,
    year: "3",
    hall_sem: "6",
    department: "B.E Instrumentation and Control Engineering",
    total_student: "120",
    fee_paid: "89",
    fee_unpaid: "31",
  },
  {
    id: 4,
    year: "4",
    hall_sem: "8",
    department: "B.Tech Artificial Intelligence and Data Science",
    total_student: "142",
    fee_paid: "102",
    fee_unpaid: "40",
  },
  {
    id: 5,
    year: "3",
    hall_sem: "6",
    department: "B.E Aerospace engineering",
    total_student: "130",
    fee_paid: "80",
    fee_unpaid: "50",
  },
]

const IssueResultcolumn = [
  { name: "YEAR", uid: "issue_result_year", sortable: true },
  { name: "SEMESTER", uid: "issue_result_sem", sortable: true },
  { name: "DEPARTMENT", uid: "issue_result_department", sortable: true },
  { name: "TOTAL  STUDENT", uid: "issue_result_total_student", sortable: true },
  { name: "ACTIONS", uid: "actions" },

]
const IssueResultlist = [
  {
    id: 1,
    issue_result_year: "2",
    issue_result_sem: "Semester 4",
    issue_result_department: "B.Tech Electronics and Communication Engineering",
    issue_result_total_student: "80",
  },
  {
    id: 2,
    issue_result_year: "2",
    issue_result_sem: "Semester 4",
    issue_result_department: "B.E Computer Science Engineering",
    issue_result_total_student: "70",
  },
  {
    id: 3,
    issue_result_year: "3",
    issue_result_sem: "Semester 6",
    issue_result_department: "B.E Instrumentation and Control Engineering",
    issue_result_total_student: "120",
  },
  {
    id: 4,
    issue_result_year: "2",
    issue_result_sem: "Semester 4",
    issue_result_department: "B.Tech Artificial Intelligence and Data Science",
    issue_result_total_student: "142",
  },
  {
    id: 5,
    issue_result_year: "3",
    issue_result_sem: "Semester 6",
    issue_result_department: "B.E Aerospace engineering",
    issue_result_total_student: "130",
  },

]


const ExamTimetablecolumn = [
  { name: "SEMESTER", uid: "exametable_semester", sortable: true },
  { name: "SUBJECT CODE", uid: "exametable_subject_code", sortable: true },
  { name: "SUBJECT NAME", uid: "examtable_subject_name", sortable: true },
  { name: "EXAM DATE", uid: "examtable_date", sortable: true },
  { name: "SESSION", uid: "exametable_session", sortable: true },
  { name: "ACTIONS", uid: "actions" },

]

const ExameTimetableList = [
  {
    id: 1,
    exametable_semester: "Semester 4",
    exametable_subject_code: "AD8402",
    examtable_subject_name: "Artificial Intelligence I",
    examtable_date: "04-DEC-2024",
    exametable_session: "FN "
  },
  {
    id: 2,
    exametable_semester: "Semester 4",
    exametable_subject_code: "AD8401",
    examtable_subject_name: "Database Design and Management",
    examtable_date: "07-DEC-2024",
    exametable_session: "FN "
  },
  {
    id: 3,
    exametable_semester: "Semester 3",
    exametable_subject_code: "CS8392",
    examtable_subject_name: "Object Oriented Programming",
    examtable_date: "13-DEC-2024",
    exametable_session: "AN "
  },
  {
    id: 4,
    exametable_semester: "Semester 4",
    exametable_subject_code: "AD8001",
    examtable_subject_name: "Software Development Processes",
    examtable_date: "16-DEC-2024",
    exametable_session: "FN "
  },
  {
    id: 5,
    exametable_semester: "Semester 4",
    exametable_subject_code: "EC8691",
    examtable_subject_name: "Microprocessors and Microcontrollers",
    examtable_date: "24-DEC-2024",
    exametable_session: "FN "
  },
  {
    id: 6,
    exametable_semester: "Semester 4",
    exametable_subject_code: "AD8003",
    examtable_subject_name: "Mobile Applications Development",
    examtable_date: "27-DEC-2024",
    exametable_session: "FN "
  },
  {
    id: 7,
    exametable_semester: "Semester 4",
    exametable_subject_code: "AD8411",
    examtable_subject_name: "Data Science Laboratory",
    examtable_date: "27-NOV-2024",
    exametable_session: "FN "
  },
  {
    id: 8,
    exametable_semester: "Semester 4",
    exametable_subject_code: "CS8481",
    examtable_subject_name: "Artificial Intelligence Laboratory",
    examtable_date: "28-NOV-2024",
    exametable_session: "FN "
  },
]

const UserListcolumn = [
  { name: "NAME", uid: "ad_user_name", sortable: true },
  { name: "USER", uid: "ad_user_user", sortable: true },
  { name: "ROLL NO/STAFF ID", uid: "ad_roll_no", sortable: true },
  { name: "DEPARTMENT", uid: "ad_dept", sortable: true },
  { name: "ACTIONS", uid: "actions" },

]

const UserdataList = [
  {
    id: 1,
    ad_user_name: "Siva kumar",
    ad_user_user: "Staff",
    ad_roll_no: "STAFF32",
    ad_dept: "CSE "
  },
  {
    id: 2,
    ad_user_name: "Newton",
    ad_user_user: "Student",
    ad_roll_no: "STD02",
    ad_dept: "MECH "
  },
  {
    id: 3,
    ad_user_name: "Rajasekar",
    ad_user_user: "Staff",
    ad_roll_no: "STAFF09",
    ad_dept: "ADMIN "
  },
  {
    id: 4,
    ad_user_name: "Azarudhin",
    ad_user_user: "Student",
    ad_roll_no: "STD06",
    ad_dept: "AIDS "
  },
  {
    id: 5,
    ad_user_name: "Priya",
    ad_user_user: "Staff",
    ad_roll_no: "STAFF45",
    ad_dept: "IT "
  },
  {
    id: 6,
    ad_user_name: "Calvin",
    ad_user_user: "Student",
    ad_roll_no: "STD23",
    ad_dept: "CSE "
  },
  {
    id: 7,
    ad_user_name: "Dinesh",
    ad_user_user: "Student",
    ad_roll_no: "STD56",
    ad_dept: "MECH "
  },
  {
    id: 8,
    ad_user_name: "Gokula Krishnan",
    ad_user_user: "Staff",
    ad_roll_no: "STAFF56",
    ad_dept: "Civil "
  },

]

const StudentHallticketcolumn = [
  { name: "SEMESTER", uid: "student_exametable_semester", sortable: true },
  { name: "SUBJECT CODE", uid: "student_exametable_subject_code", sortable: true },
  { name: "SUBJECT NAME", uid: "student_examtable_subject_name", sortable: true },
  { name: "EXAM DATE", uid: "student_examtable_date", sortable: true },
  { name: "SESSION", uid: "student_exametable_session", sortable: true },
  { name: "ACTIONS", uid: "actions" },

]

const StudentArrearHistoryColumn = [
  { name: "S NO", uid: "student_arrear_serial_number", sortable: true },
  { name: "SEMESTER", uid: "student_arrear_semester", sortable: true },
  { name: "DEPARTMENT", uid: "student_arrear_department", sortable: true },
  { name: "SUBJECT", uid: "student_arrear_subject_name", sortable: true },
  { name: "SUBJECT CODE", uid: "student_arrear_subject_code", sortable: true },
  { name: "DATE", uid: "student_arrear_date", sortable: true },

]

const StudentArrearHistoryList = [
  {
    id: 1,
    student_arrear_serial_number: 1,
    student_arrear_semester: "Semester 2",
    student_arrear_subject_code: "CSE101",
    student_arrear_subject_name: "Introduction to Programming",
    student_arrear_date: "05-JUL-2024",
    student_arrear_department: "CSE"
  },
  {
    id: 2,
    student_arrear_serial_number: 2,
    student_arrear_semester: "Semester 4",
    student_arrear_subject_code: "CSE204",
    student_arrear_subject_name: "Computer Networks",
    student_arrear_date: "04-DEC-2024",
    student_arrear_department: "CSE"
  },
  {
    id: 3,
    student_arrear_serial_number: 3,
    student_arrear_semester: "Semester 6",
    student_arrear_subject_code: "CSE304",
    student_arrear_subject_name: "Web Technologies",
    student_arrear_date: "21-MAR-2025",
    student_arrear_department: "CSE"
  },
  {
    id: 4,
    student_arrear_serial_number: 4,
    student_arrear_semester: "Semester 8",
    student_arrear_subject_code: "CSE403",
    student_arrear_subject_name: "Cloud Computing",
    student_arrear_date: "05-JUL-2025",
    student_arrear_department: "CSE"
  },

]

const StudentHallTicketList = [
  {
    id: 1,
    student_exametable_semester: "Semester 4",
    student_exametable_subject_code: "AD8402",
    student_examtable_subject_name: "Artificial Intelligence I",
    student_examtable_date: "04-DEC-2024",
    student_exametable_session: "FN "
  },
  {
    id: 2,
    student_exametable_semester: "Semester 4",
    student_exametable_subject_code: "AD8401",
    student_examtable_subject_name: "Database Design and Management",
    student_examtable_date: "07-DEC-2024",
    student_exametable_session: "FN "
  },
  {
    id: 3,
    student_exametable_semester: "Semester 3",
    student_exametable_subject_code: "CS8392",
    student_examtable_subject_name: "Object Oriented Programming",
    student_examtable_date: "13-DEC-2024",
    student_exametable_session: "AN "
  },
  {
    id: 4,
    student_exametable_semester: "Semester 4",
    student_exametable_subject_code: "AD8001",
    student_examtable_subject_name: "Software Development Processes",
    student_examtable_date: "16-DEC-2024",
    student_exametable_session: "FN "
  },
  {
    id: 5,
    student_exametable_semester: "Semester 4",
    student_exametable_subject_code: "EC8691",
    student_examtable_subject_name: "Microprocessors and Microcontrollers",
    student_examtable_date: "24-DEC-2024",
    student_exametable_session: "FN "
  },
  {
    id: 6,
    student_exametable_semester: "Semester 4",
    student_exametable_subject_code: "AD8003",
    student_examtable_subject_name: "Mobile Applications Development",
    student_examtable_date: "27-DEC-2024",
    student_exametable_session: "FN "
  },
  {
    id: 7,
    student_exametable_semester: "Semester 4",
    student_exametable_subject_code: "AD8411",
    student_examtable_subject_name: "Data Science Laboratory",
    student_examtable_date: "27-NOV-2024",
    student_exametable_session: "FN "
  },
  {
    id: 8,
    student_exametable_semester: "Semester 4",
    student_exametable_subject_code: "CS8481",
    student_examtable_subject_name: "Artificial Intelligence Laboratory",
    student_examtable_date: "28-NOV-2024",
    student_exametable_session: "FN "
  },
]

const Rolecolumn = [

  { name: "DEPARTMENT NAME", uid: "department_name", sortable: true },
  { name: "ROLE NAME", uid: "role_name", sortable: true },
  { name: "ACTIONS", uid: "actions" },
]
const Rolelist = [
  {
    id: 1,
    department_name: "CSE",
    role_name: "Professor",
  },
  {
    id: 2,
    department_name: "Admin",
    role_name: "Administrative Staff",
  },
  {
    id: 3,
    department_name: "MECH",
    role_name: "Lab Assistant",
  },
  {
    id: 4,
    department_name: "COE",
    role_name: "Examination Officer",
  },
  {
    id: 5,
    department_name: "ECE",
    role_name: "Lecturer",
  },
  {
    id: 5,
    department_name: "COE",
    role_name: "Coordinator",
  },
  {
    id: 7,
    department_name: "CSE",
    role_name: "Assisant Professor",
  },
  {
    id: 8,
    department_name: "ECE",
    role_name: "Lab Assistant",
  },
]


const Departmentcolumn = [
  { name: "DEPARTMENT CODE", uid: "department_code", sortable: true },
  { name: "DEPARTMENT NAME", uid: "department_name", sortable: true },
  { name: "ACTIONS", uid: "actions" },
]
const Departmentlist = [
  {
    id: 1,
    department_code: "D001",
    department_name: "Admin",
  },
  {
    id: 2,
    department_code: "D002",
    department_name: "COE",
  },
  {
    id: 3,
    department_code: "D003",
    department_name: "CSE",
  },
  {
    id: 4,
    department_code: "D004",
    department_name: "ECE",
  },
  {
    id: 5,
    department_code: "D005",
    department_name: "IT",
  },
  {
    id: 6,
    department_code: "D006",
    department_name: "AIDS",
  },
  {
    id: 7,
    department_code: "D007",
    department_name: "CHEMICAL",
  },
]

const Subjectcolumn = [
  { name: "DEPARTMENT NAME", uid: "department_name", sortable: true },
  { name: "SEMESTER", uid: "semester", sortable: true },
  { name: "SUBJECT CODE", uid: "subject_code", sortable: true },
  { name: "SUBJECT NAME", uid: "subject_name", sortable: true },
  { name: "ACTIONS", uid: "actions" },
]
const Subjectlist = [
  {
    id: 1,
    department_name: "AIDS",
    semester: "4",
    subject_code: "AD8001",
    subject_name: "Software Development Processes"
  },
  {
    id: 2,
    department_name: "AIDS",
    semester: "4",
    subject_code: "EC8691",
    subject_name: "Microprocessors and Microcontrollers"
  },
  {
    id: 3,
    department_name: "AIDS",
    semester: "4",
    subject_code: "AD8401",
    subject_name: "Database Design and Management"
  },
  {
    id: 4,
    department_name: "AIDS",
    semester: "4",
    subject_code: "AD8402",
    subject_name: "Artificial Intelligence I"
  },
  {
    id: 5,
    department_name: "AIDS",
    semester: "4",
    subject_code: "AD8003",
    subject_name: "Mobile Applications Development"
  }

]
const StudentResultcolumn = [
  { name: "SEMESTER", uid: "student_result_sem", sortable: true },
  { name: "SUBJECT CODE", uid: "student_result_sub_code", sortable: true },
  { name: "SUBJECT NAME", uid: "student_result_sub_name", sortable: true },
  { name: "GRADE", uid: "student_result_grade", sortable: true },
  { name: "RESULT", uid: "student_result_result", sortable: true },
  { name: "ACTIONS", uid: "actions" },
]
const StudentResultlist = [
  {
    id: 1,
    student_result_sem: "Semester 4",
    student_result_sub_code: "AD8401",
    student_result_sub_name: "Database Design and Management",
    student_result_grade: "A+",
    student_result_result: "Pass",
  },
  {
    id: 2,
    student_result_sem: "Semester 4",
    student_result_sub_code: "AD8001",
    student_result_sub_name: "Software Development Processes",
    student_result_grade: "B+",
    student_result_result: "Pass",
  },
  {
    id: 3,
    student_result_sem: "Semester 4",
    student_result_sub_code: "EC8691",
    student_result_sub_name: "Microprocessors and Microcontrollers",
    student_result_grade: "B",
    student_result_result: "Pass",
  },
  {
    id: 4,
    student_result_sem: "Semester 4",
    student_result_sub_code: "AD8003",
    student_result_sub_name: "Mobile Applications Development",
    student_result_grade: "A+",
    student_result_result: "Pass",
  },
  {
    id: 5,
    student_result_sem: "Semester 4",
    student_result_sub_code: "CS8481",
    student_result_sub_name: "Artificial Intelligence Laboratory",
    student_result_grade: "A+",
    student_result_result: "Pass",
  },
  {
    id: 6,
    student_result_sem: "Semester 4",
    student_result_sub_code: "AD8411",
    student_result_sub_name: "Data Science Laboratory",
    student_result_grade: "A+",
    student_result_result: "Pass",
  },
  {
    id: 7,
    student_result_sem: "Semester 4",
    student_result_sub_code: "AD8402",
    student_result_sub_name: "Artificial Intelligence I",
    student_result_grade: "B",
    student_result_result: "Pass",
  },
  {
    id: 8,
    student_result_sem: "Semester 3",
    student_result_sub_code: "CS8392",
    student_result_sub_name: "Object Oriented Programming",
    student_result_grade: "B+",
    student_result_result: "Pass",
  },

]
const GradeSetupcolumn = [
  { name: "MARK FROM", uid: "grade_mark_from", sortable: true },
  { name: "MARK TO", uid: "grade_mark_to", sortable: true },
  { name: "GRADE", uid: "grade_grade", sortable: true },
  { name: "ACTIONS", uid: "actions" },
]
const GradeSetuplist = [
  {
    id: 1,
    grade_mark_from: "90",
    grade_mark_to: "100",
    grade_grade: "O",
  },
  {
    id: 2,
    grade_mark_from: "80",
    grade_mark_to: "89",
    grade_grade: "A+",
  },
  {
    id: 3,
    grade_mark_from: "70",
    grade_mark_to: "79",
    grade_grade: "A",
  },
  {
    id: 4,
    grade_mark_from: "60",
    grade_mark_to: "69",
    grade_grade: "B+",
  },
  {
    id: 5,
    grade_mark_from: "46",
    grade_mark_to: "59",
    grade_grade: "B",
  },
  {
    id: 6,
    grade_mark_from: "0",
    grade_mark_to: "45",
    grade_grade: "F",
  },

]

const RevalutionList = [
  {
    id: 1,
    regNo: "CV015",
    name: "Alex",
    semester: 4,
    dept: "Civil",
    progress: "Pending",
    subject: "Structural Dynamics",
    subjectCode: "CIV108",
    mark: 17,
    grade: calculateGrade(17)
  },
  {
    id: 2,
    regNo: "CSE009",
    progress: "Pending",
    name: "Emily",
    semester: 2,
    dept: "CSE",
    subject: "Introduction to Programming",
    subjectCode: "CSE101",
    mark: 39,
    grade: calculateGrade(39)
  },
  {
    id: 3,
    regNo: "MECH010",
    name: "James",
    progress: "Pending",
    semester: 5,
    dept: "Mechanical",
    subject: "Finite Element Analysis",
    subjectCode: "MECH401",
    mark: 72,
    grade: calculateGrade(72)
  },
  {
    id: 4,
    regNo: "EEE024",
    name: "Sarah",
    semester: 6,
    dept: "EEE",
    subject: "High Voltage Engineering",
    subjectCode: "EEE402",
    progress: "Pending",
    mark: 27,
    grade: calculateGrade(27)
  },
  {
    id: 5,
    regNo: "MECH020",
    name: "Michael",
    semester: 8,
    dept: "Mechanical",
    progress: "Pending",
    subject: "Advanced Machine Design",
    subjectCode: "MECH402",
    mark: 82,
    grade: calculateGrade(82)
  },
  {
    id: 6,
    regNo: "CSE012",
    progress: "Pending",
    name: "Olivia",
    semester: 3,
    dept: "CSE",
    subject: "Data Structures",
    subjectCode: "CSE201",
    mark: 20,
    grade: calculateGrade(20)
  },
  {
    id: 7,
    regNo: "MECH005",
    name: "David",
    semester: 1,
    progress: "Pending",
    dept: "Mechanical",
    subject: "Thermodynamics",
    subjectCode: "MECH102",
    mark: 39,
    grade: calculateGrade(39)
  },
]

const RevaluationColumn = [
  { name: "ACTIONS", uid: "actions" },
  { name: "Register No", uid: "reg_no", sortable: true },
  { name: "Name", uid: "name", sortable: true },
  { name: "Semester", uid: "semester", sortable: true },
  { name: "Department", uid: "department", sortable: true },
  { name: "Subject", uid: "subject", sortable: true },
  { name: "Code", uid: "subject_code", sortable: true },
  { name: "Mark", uid: "mark", sortable: true },
  { name: "Grade", uid: "grade", sortable: true },
]

const Examtimetablesetupcolumn = [
  { name: "DEPARTMENT", uid: "department", sortable: true },
  { name: "YEAR", uid: "year", sortable: true },
  { name: "SEMESTER", uid: "semester", sortable: true },
  { name: "SUBJECT NAME", uid: "subject_name", sortable: true },
  { name: "SUBJECT CODE", uid: "subject_code", sortable: true },
  { name: "SESSION", uid: "session", sortable: true },
  { name: "ACTIONS", uid: "actions" },

]

const Exametimetablesetuplist = [
  {
    id: 1,
    department: "CSE",
    year: "1",
    semester: "Semester 4",
    subject_name: "Artificial Intelligence",
    subject_code: "AFI012",
    session: "FN "
  },
]

const CoeArrearHistoryColumn = [
  { name: "S NO", uid: "arrear_serial_number", sortable: true },
  { name: "SEMESTER", uid: "arrear_semester", sortable: true },
  { name: "DEPARTMENT", uid: "arrear_department", sortable: true },
  { name: "YEAR", uid: "arrear_year", sortable: true },
  { name: "SUBJECT", uid: "arrear_subject_name", sortable: true },
  { name: "SUBJECT CODE", uid: "arrear_subject_code", sortable: true },
  { name: "DATE", uid: "arrear_date", sortable: true },
  { name: "ACTIONS", uid: "actions" },

]

const CoeArrearHistoryList = [
  {
    id: 1,
    arrear_serial_number: 1,
    arrear_semester: "Semester 2",
    arrear_year: "First Year",
    arrear_subject_code: "CSE101",
    arrear_subject_name: "Introduction to Programming",
    arrear_date: "05-JUL-2024",
    arrear_department: "CSE"
  },

  {
    id: 2,
    arrear_serial_number: 2,
    arrear_semester: "Semester 4",
    arrear_year: "Second Year",
    arrear_subject_code: "CSE204",
    arrear_subject_name: "Computer Networks",
    arrear_date: "04-DEC-2024",
    arrear_department: "EEE"
  },
  {
    id: 3,
    arrear_serial_number: 3,
    arrear_semester: "Semester 6",
    arrear_year: "Third Year",
    arrear_subject_code: "CSE304",
    arrear_subject_name: "Web Technologies",
    arrear_date: "21-MAR-2025",
    arrear_department: "MECH"
  },
  {
    id: 4,
    arrear_serial_number: 4,
    arrear_semester: "Semester 8",
    arrear_year: "Final Year",
    arrear_subject_code: "CSE403",
    arrear_subject_name: "Cloud Computing",
    arrear_date: "05-JUL-2025",
    arrear_department: "CVE"
  },
  {
    id: 5,
    arrear_serial_number: 2,
    arrear_semester: "Semester 4",
    arrear_year: "Second Year",
    arrear_subject_code: "CSE204",
    arrear_subject_name: "Computer Networks",
    arrear_date: "04-DEC-2024",
    arrear_department: "MECH"
  },
]

const RevaluationEditColumn = [
  { name: "ACTIONS", uid: "actions" },
  { name: "Register No", uid: "reg_no", sortable: true },
  { name: "Name", uid: "name", sortable: true },
  { name: "Semester", uid: "semester", sortable: true },
  { name: "Department", uid: "department", sortable: true },
  { name: "Subject", uid: "subject", sortable: true },
  { name: "Code", uid: "subject_code", sortable: true },
  { name: "Mark", uid: "mark", sortable: true },
  { name: "Progress", uid: "progress", sortable: true },
  { name: "Grade", uid: "grade", sortable: true },
]

const StudentPlacementColumn = [
  { name: "S NO.", uid: "s_place_sno", sortable: true },
  { name: "COMPANY NAME", uid: "s_place_Companyname", sortable: true },
  { name: "WEBSITE", uid: "s_place_website", sortable: true },
  { name: "CATEGORY", uid: "s_place_category", sortable: true },
  { name: "DATE", uid: "s_place_date", sortable: true },
  { name: "TIME", uid: "s_place_time", sortable: true },
  { name: "PLACE", uid: "s_place_place", sortable: true },
  { name: "RESPOND", uid: "s_place_respond", sortable: true },
  { name: "SUBMIT RESULT", uid: "s_place_result", sortable: true },
  { name: "PLACED?", uid: "s_place_placed", sortable: true },
  { name: "CTC/REASON", uid: "s_place_ctcreason", sortable: true },

]

const StudentPlacementList = [
  {
    id: 1,
    s_place_sno: 1,
    s_place_Companyname: "Cognizant",
    s_place_website: "https://www.cognizant.com/in/en",
    s_place_category: "Digital Marketing",
    s_place_date: "15-09-2024",
    s_place_time: "10.00 AM",
    s_place_place: "Venue 1",
    s_place_placed: "Yes",
    s_place_ctcreason: "4L"
  },
  {
    id: 2,
    s_place_sno: 2,
    s_place_Companyname: "Zoho",
    s_place_website: "https://www.zoho.com/",
    s_place_category: "Software Developer",
    s_place_date: "02-10-2024",
    s_place_time: "9.00 AM",
    s_place_place: "CSE lab",
    s_place_placed: "No",
    s_place_ctcreason: "Not clear round 3"
  },
  {
    id: 3,
    s_place_sno: 3,
    s_place_Companyname: "HCL",
    s_place_website: "https://www.hcl.com/",
    s_place_category: "Sofware Testing",
    s_place_date: "20-10-2024",
    s_place_time: "10.30 AM",
    s_place_place: "Venue 3",
    s_place_placed: "Yes",
    s_place_ctcreason: "3L"
  },

]
const PlacementColumn = [
  { name: "Company Name", uid: "company_name", sortable: true },
  { name: "Date", uid: "date", sortable: true },
  { name: "Specilization", uid: "specilization", sortable: true },
  { name: "Year", uid: "year", sortable: true },
  { name: "Total Students", uid: "total_student", sortable: true },
  { name: "Responded", uid: "responded", sortable: true },
  { name: "Not Responded", uid: "not_responded", sortable: true },
  { name: "ACTIONS", uid: "actions" },

]

const PlacementList = [
  {
    id: 1,
    company_name: "xyz",
    date:  "05-JUL-2024",
    specilization: "Data Science",
    year: "Second Year",
    total_student: "50",
    responded: "1",
    not_responded: "1",
  },
  {
    id: 2,
    company_name: "abx",
    date:  "05-JUL-2024",
    specilization: "Airtificial Intelligence",
    year: "First Year",
    total_student: "50",
    responded: "1",
    not_responded: "1",
  },
  {
    id: 3,
    company_name: "xyz",
    date:  "05-JUL-2024",
    specilization: "Big Data",
    year: "Second Year",
    total_student: "55",
    responded: "1",
    not_responded: "1",
  },
  {
    id: 4,
    company_name: "xyz",
    date:  "05-JUL-2024",
    specilization: "Data Analytics and Visualization",
    year: "Second Year",
    total_student: "60",
    responded: "1",
    not_responded: "1",
  },
  {
    id: 5,
    company_name: "xyz",
    date:  "05-JUL-2024",
    specilization: "Machine Learning",
    year: "First Year",
    total_student: "50",
    responded: "1",
    not_responded:"1",
  },

]

const PlacementReportColumn = [
  { name: "STUDENT NAME", uid: "p_report_name", sortable: true },
  { name: "MAJOR", uid: "p_report_major", sortable: true },
  { name: "SPECILIZATION", uid: "p_report_spec", sortable: true },
  { name: "YEAR", uid: "p_report_year", sortable: true },
  { name: "COMPANY NAME", uid: "p_report_company", sortable: true },
  { name: "DATE", uid: "p_report_date", sortable: true },
  { name: "PLACED?", uid: "p_report_placed", sortable: true },
  { name: "CTC/REASON", uid: "p_report_ctcreason", sortable: true },
  { name: "ACTIONS", uid: "actions" },

]

const PlacementReportList = [
  {
    id: 1,
    p_report_name: "Devaraj",
    p_report_major:  "UG",
    p_report_spec: "CSE",
    p_report_year: "4",
    p_report_company: "ZOHO",
    p_report_date: "22-08-2024",
    p_report_placed: "Yes",
    p_report_ctcreason: "6L",
  },
  {
    id: 2,
    p_report_name: "Abinaya",
    p_report_major:  "UG",
    p_report_spec: "IT",
    p_report_year: "4",
    p_report_company: "Cognizant",
    p_report_date: "08-07-2024",
    p_report_placed: "Yes",
    p_report_ctcreason: "3.5L",
  },
  {
    id: 3,
    p_report_name: "Siva kumar",
    p_report_major:  "PG",
    p_report_spec: "Data Science",
    p_report_year: "3",
    p_report_company: "HCL",
    p_report_date: "03-09-2024",
    p_report_placed: "No",
    p_report_ctcreason: "Not Attended",
  },
  {
    id: 1,
    p_report_name: "Raja Ganesh",
    p_report_major:  "UG",
    p_report_spec: "ECE",
    p_report_year: "4",
    p_report_company: "Tech mahindra ",
    p_report_date: "11-08-2024",
    p_report_placed: "Yes",
    p_report_ctcreason: "4L",
  },

]

const PlacementRequirementcolumn = [
  { name: "NAME", uid: "student_name", sortable: true },
  { name: "MAJOR", uid: "major", sortable: true },
  { name: "SPECILIZATION", uid: "specilization", sortable: true },
  { name: "YEAR", uid: "year", sortable: true },
  { name: "10th Mark", uid: "tenth_mark", sortable: true },
  { name: "12th Mark", uid: "Twelfth_mark", sortable: true },
  { name: "CGPA?", uid: "cgpa", sortable: true },
  { name: "Backlogs", uid: "backlogs", sortable: true },
  { name: "ACTIONS", uid: "actions" },

]
const PlacementRequirementlist = [
  {
    id: 1,
    student_name: "Salomi",
    major:  "UG",
    specilization: "EEE",
    year: "4",
    tenth_mark: "430",
    Twelfth_mark: "550",
    cgpa: "8.9",
    backlogs: "NO",
  },
  {
    id: 2,
    student_name: "Davincy",
    major:  "PG",
    specilization: "CSE",
    year: "4",
    tenth_mark: "338",
    Twelfth_mark: "546",
    cgpa: "7.8",
    backlogs: "Yes",
  },

]
export {
  incidentintegrationcolumn, incidentintegrationlist, Usercolumn, Userlist, Rolecolumn, Rolelist,
  ExamTimetablecolumn, ExameTimetableList, UserListcolumn, UserdataList, Hallcolumn, Halllist,
  StudentHallticketcolumn, StudentHallTicketList, Departmentcolumn, Departmentlist,
  StudentResultcolumn, StudentResultlist, Subjectcolumn, Subjectlist, GradeSetupcolumn, GradeSetuplist, RevaluationColumn,
  IssueResultcolumn, IssueResultlist, RevalutionList, calculateGrade, StudentArrearHistoryColumn, StudentArrearHistoryList, Examtimetablesetupcolumn,
  Exametimetablesetuplist, CoeArrearHistoryColumn, CoeArrearHistoryList, RevaluationEditColumn , departments,
  StudentPlacementColumn, StudentPlacementList,PlacementColumn,PlacementList, PlacementReportColumn, PlacementReportList,
PlacementRequirementcolumn,PlacementRequirementlist,
}