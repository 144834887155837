import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Input,
    Table,
    TableHeader,
    TableBody,
    TableCell,
    TableColumn,
    TableRow,
    Button
} from "@nextui-org/react";
// import "./ModelForm.css";
import { useMark } from "../data/Context";

const ResultTable = () => {
    const { result } = useMark()
    const [data, setData] = useState(result);

    useEffect(_ => {
        console.log("result ::: ", result);
        console.log("data ::: ", data);
    },[])

    return (
        <div className="main-inner-wrapper leavemenu-wrapper">
            <div className="px-4">
                <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
                    <h1 className="report-heading" id="report-heading">Result Details</h1>
                    <Table aria-label="Sections Table" className="mb-6">
                        <TableHeader>
                            <TableColumn className="text-center">S No</TableColumn>
                            <TableColumn className="text-center">Reg No</TableColumn>
                            <TableColumn className="text-center">Year</TableColumn>
                            <TableColumn className="text-center">Department</TableColumn>
                            <TableColumn className="text-center">Subject</TableColumn>
                            <TableColumn className="text-center">Code</TableColumn>
                            <TableColumn className="text-center">Mark</TableColumn>
                            <TableColumn className="text-center">Result</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {data.map((res, index) => (
                                <TableRow key={index} className="text-center">
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{res.regNo}</TableCell>
                                    <TableCell>{res.year}</TableCell>
                                    <TableCell>{res.dept}</TableCell>
                                    <TableCell>{res.subject}</TableCell>
                                    <TableCell>{res.subCode}</TableCell>
                                    <TableCell>{res.total}</TableCell>
                                    <TableCell>{res.result}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </div>
        </div>
    );
};

export default ResultTable;
