import React, { useEffect, useState } from "react";
import { Input, Button, Select, SelectItem } from "@nextui-org/react";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { FaPrint } from 'react-icons/fa';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


// Define colors for different departments
const departmentColors = {
  Civil: "#FFDDC1",
  Mechanical: "#FFABAB",
  EEE: "#FFC3A0",
  CSE: "#FF677D",
  Aeronautical: "#D4A5A5",
  ITE: "#F7A4A4",
};

// Define grid options
const gridOptions = [
  { label: "4x5", rows: 4, cols: 5 },
  { label: "5x4", rows: 5, cols: 4 },
  { label: "10x2", rows: 10, cols: 2 },
];

// Function to generate registration numbers
const generateRegistrationNumbers = (prefix, count) => {
  return Array.from({ length: count }, (_, i) => `${prefix}${String(i + 1).padStart(3, "0")}`);
};

const SeatArrangement = () => {
  const [halls, setHalls] = useState([
    {
      id: "Hall 1",
      name: "Hall 1",
      departments: {
        Civil: generateRegistrationNumbers("CV", 10),
        Mechanical: generateRegistrationNumbers("MECH", 10),
        EEE: generateRegistrationNumbers("EEE", 10),
        CSE: generateRegistrationNumbers("CSE", 10),
        Aeronautical: generateRegistrationNumbers("AERO", 10),
        ITE: generateRegistrationNumbers("ITE", 10),
      },
    },
    {
      id: "Hall 2",
      name: "Hall 2",
      departments: {
        Civil: generateRegistrationNumbers("CV", 20),
        Mechanical: generateRegistrationNumbers("MECH", 20),
        EEE: generateRegistrationNumbers("EEE", 20),
        CSE: generateRegistrationNumbers("CSE", 20),
        Aeronautical: generateRegistrationNumbers("AERO", 20),
        ITE: generateRegistrationNumbers("ITE", 20),
      },
    },
    {
      id: "Hall 3",
      name: "Hall 3",
      departments: {
        Civil: generateRegistrationNumbers("CV", 30),
        Mechanical: generateRegistrationNumbers("MECH", 30),
        EEE: generateRegistrationNumbers("EEE", 30),
        CSE: generateRegistrationNumbers("CSE", 30),
        Aeronautical: generateRegistrationNumbers("AERO", 30),
        ITE: generateRegistrationNumbers("ITE", 30),
      },
    },
    {
      id: "Hall 4",
      name: "Hall 4",
      departments: {
        Civil: generateRegistrationNumbers("CV", 40),
        Mechanical: generateRegistrationNumbers("MECH", 40),
        EEE: generateRegistrationNumbers("EEE", 40),
        CSE: generateRegistrationNumbers("CSE", 40),
        Aeronautical: generateRegistrationNumbers("AERO", 40),
        ITE: generateRegistrationNumbers("ITE", 40),
      },
    },
  ]);

  const [departmentList, setDepartmentList] = useState(Object.keys(departmentColors));
  const [showHallInput, setShowHallInput] = useState(false);
  const [showDepartmentInput, setShowDepartmentInput] = useState(false);
  const [newHallName, setNewHallName] = useState("");
  const [selectedHall, setSelectedHall] = useState("");
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [newDepartmentName, setNewDepartmentName] = useState("");
  const [newDepartmentCode, setNewDepartmentCode] = useState("");
  const [seatCount, setSeatCount] = useState(0);
  const [selectedGrid, setSelectedGrid] = useState(false);
  const [showGrid, setShowGrid] = useState(false);
  const [error, setError] = useState("");
  const [totalSeats, setTotalSeats] = useState(0);
  const [departmentFields, setDepartmentFields] = useState({});
  const [seatAssignments, setSeatAssignments] = useState({});
  const [gridSize, setGridSize] = useState({ rows: selectedGrid.rows, cols: selectedGrid.cols });
  const [grid, setGrid] = useState([]);
  const [secondRender, setSecondRender] = useState(false);
  const [isAllocationCompleted, setIsAllocationCompleted] = useState(false);

  // Handler to delete a hall
  const handleDelete = (hallId) => {
    setHalls(halls.filter((hall) => hall.id !== hallId));
  };

  // Handler to add a new hall
  const handleAddHall = () => {
    if (newHallName.trim() !== "") {
      const newHallId = `hall${halls.length + 1}`;
      const newHall = { id: newHallId, name: newHallName, departments: {} };
      setHalls([...halls, newHall]);
      setNewHallName("");
      setShowHallInput(false);
      setSelectedHall(newHallId);
    }
  };

  useEffect(() => {
    setGrid(Array.from({ length: selectedGrid.rows }, () =>
      Array(selectedGrid.cols).fill(null)
    ));
  }, [selectedGrid]);


  // Handler to add a new department to the selected hall
  const handleAddDepartment = () => {
    if (selectedHall && newDepartmentName && newDepartmentCode) {
      const hallIndex = halls.findIndex((hall) => hall.id === selectedHall);
      if (hallIndex !== -1) {
        const updatedHalls = [...halls];
        updatedHalls[hallIndex].departments[newDepartmentName] = generateRegistrationNumbers(newDepartmentCode, seatCount);
        setHalls(updatedHalls);

        if (!departmentList.includes(newDepartmentName)) {
          setDepartmentList([...departmentList, newDepartmentName]);
        }

        setNewDepartmentName("");
        setNewDepartmentCode("");
        setSeatCount(0);
        setShowDepartmentInput(false);
      }
    }
  };

  // Handler for hall selection change
  const handleSelectChange = (e) => {
    console.log(e.target.value);
    // debugger
    setSelectedHall(e.target.value);
  };

  // Handler for department selection change
  const handleDepartmentSelect = (e) => {
    const value = e.target.value;
    setSelectedDepartments((prev) =>
      prev.includes(value) ? prev.filter((dept) => dept !== value) : [...prev, value]
    );
    setDepartmentFields((prev) => ({
      ...prev,
      [value]: prev[value] || { from: "", to: "" },
    }));
  };

  // Handler for grid size change
  const handleGridChange = (e) => {
    const selectedOption = gridOptions.find((option) => option.label === e.target.value);
    setSelectedGrid(selectedOption);
  };

  const allocateSeats = () => {
    let newGrid = Array.from({ length: selectedGrid.rows }, () =>
      Array(selectedGrid.cols).fill(null)
    );

    // Function to check if adjacent seats have students from the same department (only horizontal and vertical)
    const isAdjacentSameDept = (row, col, department) => {
      const directions = [
        [0, 1], // Right
        [1, 0], // Down
        [0, -1], // Left
        [-1, 0] // Up
      ];
      return directions.some(([dr, dc]) => {
        const r = row + dr, c = col + dc;
        if (r >= 0 && r < selectedGrid.rows && c >= 0 && c < selectedGrid.cols && newGrid[r][c]) {
          return newGrid[r][c].department === department;
        }
        return false;
      });
    };

    // Initialize assignments and track seat allocations
    const assignments = {};
    let allocatedSeats = 0;

    // Allocate seats for each department based on 'from' and 'to' registration numbers
    selectedDepartments.forEach(department => {
      const { from, to } = departmentFields[department];
      const departmentSeats = halls.find(hall => hall.id === selectedHall).departments[department];
      const fromIndex = departmentSeats.indexOf(from);
      const toIndex = departmentSeats.indexOf(to);

      if (fromIndex !== -1 && toIndex !== -1) {
        for (let i = fromIndex; i <= toIndex; i++) {
          // Find an empty spot on the grid
          let allocated = false;
          for (let row = 0; row < selectedGrid.rows; row++) {
            for (let col = 0; col < selectedGrid.cols; col++) {
              if (!newGrid[row][col] && !isAdjacentSameDept(row, col, department)) {
                newGrid[row][col] = { seat: departmentSeats[i], department, color: departmentColors[department] || "#000" };
                assignments[departmentSeats[i]] = {
                  department,
                  color: departmentColors[department] || "#f0f0f0",
                };
                allocated = true;
                allocatedSeats++;
                break;
              }
            }
            if (allocated) break;
          }
        }
      }
    });

    setSeatAssignments(assignments);
    setTotalSeats(allocatedSeats);
    setGrid(newGrid);
    setShowGrid(true);
    setIsAllocationCompleted(true);
  };

  // Handler to update department fields
  const handleFieldChange = (department, field, value) => {
    setDepartmentFields((prev) => ({
      ...prev,
      [department]: {
        ...prev[department],
        [field]: value,
      },
    }));
  };

  const handlePrintAll = _ => {
    let pdfUrl = `${process.env.PUBLIC_URL}/PrintAllSeatArrangements.pdf`;
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `All Seat Arrangements.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handlePrint = async () => {
    // Check if allocation is completed
    if (!isAllocationCompleted) {
      alert("Please allocate seats first.");
      return;
    }

    const doc = new jsPDF();

    // Add Hall number and department details
    doc.text(`Hall Number : ${selectedHall}`, 10, 10);
    doc.text(`Departments : ${selectedDepartments.join(', ')}`, 10, 20);

    // Add department details and registration number ranges
    let yOffset = 30; // Starting y-offset for the department data
    selectedDepartments.forEach(department => {
      const { from, to } = departmentFields[department];
      doc.text(`${department} : ${from} to ${to}`, 10, yOffset);
      yOffset += 10; // Move y-offset for next department
    });

    // Capture the seat grid as an image
    const seatGridElement = document.getElementById('seat-grid');
    const canvas = await html2canvas(seatGridElement);
    const imgData = canvas.toDataURL('image/png');

    // Adjust yOffset to be after the last department information
    const imageYOffset = yOffset + 10;

    // Add the image to the PDF
    doc.addImage(imgData, 'PNG', 10, imageYOffset, 190, 100); // Adjust position and size

    // Save the PDF
    doc.save('SeatAllocation.pdf');
  };

  const hall = halls.find((hall) => hall.id === selectedHall);
  const { rows, cols } = selectedGrid;

  useEffect(_ => {
    if (selectedGrid && secondRender) {
      allocateSeats();
    }
  }, [selectedGrid])

  return (
    <div className="main-inner-wrapper leavemenu-wrapper">
      <div className="px-4">
        <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
          <div className="flex justify-between items-center mb-4">
            <h1 className="report-heading" style={{ marginLeft: "0px" }}>Seat Arrangements</h1>
            <div className="flex gap-4">
              <Button
                className="bg-[#36A2EB] font-bold text-white"
                onClick={handlePrintAll}
              >
                <FaPrint />
                Print All
              </Button>
              <Button
                className="bg-[#36A2EB] font-bold text-white"
                onClick={handlePrint}
              >
                <FaPrint />
                Print
              </Button>
            </div>
          </div>
          <div className="form-container">
            <form>
              <div className="flex flex-col gap-4 mb-9 items-start">
                <div className="flex items-center gap-4">
                  <label htmlFor="hallNumber">Hall Number</label>
                  <Select
                    id="hallNumber"
                    variant="bordered"
                    className="w-[200px]"
                    onChange={handleSelectChange}
                    value={selectedHall}
                  >
                    {halls.map((hall) => (
                      <SelectItem key={hall.id} textValue={hall.name} value={hall.id}>
                        <div className="flex items-center justify-between w-full">
                          <span>{hall.name}</span>
                          <AiOutlineClose
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(hall.id);
                            }}
                            className="cursor-pointer text-red-500 ml-2"
                          />
                        </div>
                      </SelectItem>
                    ))}
                  </Select>
                  <AiOutlinePlus
                    onClick={() => setShowHallInput(true)}
                    className="cursor-pointer text-green-500"
                  />
                </div>

                {showHallInput && (
                  <div className="flex flex-col gap-4">
                    <Input
                      placeholder="Enter Hall Name"
                      value={newHallName}
                      variant="bordered"
                      onChange={(e) => setNewHallName(e.target.value)}
                    />
                    <Button onClick={handleAddHall}>Add Hall</Button>
                  </div>
                )}

                <div className="flex items-center gap-4">
                  <label htmlFor="department">Departments</label>
                  <Select
                    id="department"
                    variant="bordered"
                    className="w-[200px]"
                    multiple
                    onChange={handleDepartmentSelect}
                    value={selectedDepartments}
                  >
                    {departmentList.map((dept) => (
                      <SelectItem key={dept} value={dept}>
                        {dept}
                      </SelectItem>
                    ))}
                  </Select>
                  {/* <AiOutlinePlus
                    onClick={() => setShowDepartmentInput(true)}
                    className="cursor-pointer text-green-500"
                  /> */}
                </div>

                {selectedDepartments.length >= 1 && (
                  <div className="flex flex-col gap-4">
                    {selectedDepartments.map((department) => (
                      <div key={department} className="flex items-center gap-4">
                        <span>{department}</span>
                        <Input
                          placeholder="From Registration Number"
                          value={departmentFields[department]?.from || ""}
                          variant="bordered"
                          onChange={(e) =>
                            handleFieldChange(department, "from", e.target.value)
                          }
                        />
                        <Input
                          placeholder="To Registration Number"
                          value={departmentFields[department]?.to || ""}
                          variant="bordered"
                          onChange={(e) =>
                            handleFieldChange(department, "to", e.target.value)
                          }
                        />
                      </div>
                    ))}
                  </div>
                )}

                <div className="flex items-center gap-4">
                  <label htmlFor="grid">Select Grid Size</label>
                  <Select
                    id="grid"
                    variant="bordered"
                    className="w-[200px]"
                    onChange={handleGridChange}
                    value={selectedGrid.label}
                  >
                    {gridOptions.map((option) => (
                      <SelectItem key={option.label} value={option.label}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </Select>
                </div>
              </div>

              <Button
                onClick={_ => { allocateSeats(); setSecondRender(true); }}
                className="mt-4 bg-[#36A2EB] font-bold text-white"

              >
                Allocate Seats
              </Button>
              {error && <p className="text-red-500">{error}</p>}
            </form>
          </div>

          {showGrid && (
            <div className="mt-6" id="seat-grid">
              <div
                className="grid"
                style={{
                  gridTemplateColumns: `repeat(${cols}, 1fr)`,
                  gap: "8px",
                }}
              >
                {grid.map((row, rowIndex) => row.map((cell, colIndex) => (
                  <div
                    key={`${rowIndex}-${colIndex}`}
                    className="seat"
                    style={{
                      backgroundColor: cell ? cell.color : "#fff",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      padding: "10px",
                      textAlign: "center",
                      position: "relative",
                    }}
                  >
                    {cell ?
                      <span className="reg-no">{cell.seat}</span>
                      :
                      <span className="empty-seat">Empty</span>
                    }
                  </div>
                )))}
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default SeatArrangement;
