import React from 'react';
import { useNavigate } from 'react-router-dom';


const LandingPage = () => {
    const images = [
        { src: "/images/students.jpeg", label: "Students", role: "student" },
        { src: "/images/staff.jpg", label: "Staff", role: "staff" },
        { src: "/images/coe.jpg", label: "COE", role: "coe" },
        { src: "/images/admin.jpg", label: "Admin", role: "admin" },
        { src: "/images/placement.jpg", label: "Placement", role: "placement" }

    ];

    const navigate = useNavigate();

    const handleLogin = (role) => {
        localStorage.setItem("identity", role);
        navigate('/login');
    };

    return (
        <section className="auth-section flex items-center justify-center h-screen">
            <div
                className="w-full flex-col h-full justify-evenly flex items-center"
                style={{
                    background: `url('/images/landerpage.jpg')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundAttachment : "fixed",
                }}
            >   
                <img src="/images/EDG.png" width="200px" height="200px" alt="EDG" />
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 px-6">
                    {images.map((image, index) => (
                        <div
                            key={index}
                            className="relative group w-full h-64 bg-cover bg-center cursor-pointer LandingLoginImage"
                            style={{
                                backgroundImage: `url(${image.src})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                            }}
                            onClick={() => handleLogin(image.role)}
                        >
                            <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center">
                                <h3 className="text-white text-2xl font-bold">{image.label}</h3>
                                <p className="text-white">Click here</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default LandingPage;
