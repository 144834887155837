import React, { createContext, useState, useContext } from 'react';
import { calculateGrade } from './data';

// Create a context with a default value
const EdGraderContext = createContext();

// Create a provider component
export const MarkProvider = ({ children }) => {
    const [markSetup, setMarkSetup] = useState([]); // Replace null with your initial state
    const [studentData, setStudentData] = useState([
        {
            dummy: "CSE001",
            details: {
                dept: "CSE",
                internal: 20,
                questionModal: [
                    { sectionName: 'Section A ', numOfQuestions: 10, mark: 2, totalMark: 20 },
                    { sectionName: 'Section B ', numOfQuestions: 4, mark: 5, totalMark: 20 },
                    { sectionName: 'Section C', numOfQuestions: 3, mark: 10, totalMark: 30 }
                ],
                subCode: "CSE105",
                subject: "Operating Systems",
                theory: 80,
                year: 1,
                semester: 2
            }
        },
        {
            dummy: "EEE001",
            details: {
                dept: "EEE",
                internal: 20,
                questionModal: [
                    { sectionName: 'Section A ', numOfQuestions: 10, mark: 2, totalMark: 20 },
                    { sectionName: 'Section B ', numOfQuestions: 4, mark: 5, totalMark: 20 },
                    { sectionName: 'Section C', numOfQuestions: 3, mark: 10, totalMark: 30 }
                ],
                subCode: "EEE205",
                subject: "Microprocessors",
                theory: 80,
                year: 2,
                semester: 4
            }
        },

    ]);
    const [result, setResult] = useState([
        {
            regNo : "MEC020",
            year : "2",
            dept : "Mechanical",
            subject : "Materials Science",
            subCode : "MECH203",
            total : 76,
            result : calculateGrade(76)
        },
        {
            regNo: "CV001",
            year: "1",
            dept: "Civil",
            subject: "Structural Analysis",
            subCode: "CIV101",
            total: 85,
            result: calculateGrade(85)
        },
        {
            regNo: "MEC002",
            year: "2",
            dept: "Mechanical",
            subject: "Materials Science",
            subCode: "MECH203",
            total: 76,
            result: calculateGrade(76)
        },
        {
            regNo: "CS003",
            year: "3",
            dept: "CSE",
            subject: "Artificial Intelligence",
            subCode: "CSE303",
            total: 92,
            result: calculateGrade(92)
        },
        {
            regNo: "EEE004",
            year: "4",
            dept: "EEE",
            subject: "Embedded Systems",
            subCode: "EEE403",
            total: 88,
            result: calculateGrade(88)
        },
        {
            regNo: "CV005",
            year: "2",
            dept: "Civil",
            subject: "Concrete Technology",
            subCode: "CIV107",
            total: 73,
            result: calculateGrade(73)
        }
    ]);

    const updateResult = (res) => {
        setResult(res);
    };

    const updateMarkSetup = (newMark) => {
        setMarkSetup(newMark);
    };

    const [RevaluationEditDetails, setRevaluationEditDetails] = useState([]);

    const updateRevaluationEditDetails = res => {
        // Check if res is an array
        if (Array.isArray(res)) {
            // Spread the elements of res and merge with RevaluationEditDetails
            let data = [...res];
            setRevaluationEditDetails(data);
        } else {
            // If res is not an array, just add it as a single item
            let data = [...RevaluationEditDetails, res];
            setRevaluationEditDetails(data);
        }
    };


    return (
        <EdGraderContext.Provider value={{ markSetup, updateMarkSetup, studentData, result, updateResult, RevaluationEditDetails, updateRevaluationEditDetails }}>
            {children}
        </EdGraderContext.Provider>
    );
};

// Custom hook for using the context
export const useMark = () => useContext(EdGraderContext);
