import React, { useState } from "react";
import {
    Button,
    Input,
    Select,
    SelectItem,
    Table,
    TableHeader,
    TableBody,
    TableCell,
    TableColumn,
    TableRow,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "@nextui-org/react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../components/baseComponent/ModelForm.css";

const InternalMarkSetup = () => {
    const [sections, setSections] = useState([]);
    const [sectionName, setSectionName] = useState("");
    const [mark, setMark] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedDept, setSelectedDept] = useState("");

    const departments = ["Civil", "Mechanical", "CSE", "EEE", "AIDS", "IT"];
    const years = [1, 2, 3, 4];

    const handleAddSection = () => {
        if (selectedYear && selectedDept) {
            setIsModalVisible(true); // Show modal only if year and department are selected
        }
    };

    const handleCloseModal = () => {
        setIsModalVisible(false); // Hide modal
    };

    const handleSectionSubmit = () => {
        if (sectionName && mark && selectedYear && selectedDept) {
            const newSection = {
                sectionName,
                mark: Number(mark),
                year: selectedYear,
                department: selectedDept
            };
            setSections([...sections, newSection]);
            setSectionName("");
            setMark("");
            handleCloseModal(); // Hide modal after submit
        } else {
            // Optionally handle validation or display error
            // console.log("Section Name, Mark, Year, and Department are required.");
        }
    };

    const handleSubmit = () => {
        toast.success("Internal setup submitted successfully!");
        // Add any additional logic you need for submission
    };

    // Calculate total marks
    const totalMarks = sections.reduce((acc, section) => acc + section.mark, 0);

    // Determine if the button should be enabled
    const isAddSectionButtonEnabled = selectedYear && selectedDept;

    return (
        <div className="main-inner-wrapper leavemenu-wrapper">
            <div className="px-4">
                <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
                    <h1 className="report-heading" style={{ marginBottom: "40px", fontSize: "25px", fontWeight: "bold", marginLeft: "0px" }}>
                        Internal Mark Setup
                    </h1>
                    <form>
                        <div className="form-row">
                            <Select
                                label="Year"
                                value={selectedYear}
                                onChange={(e) => {
                                    setSelectedYear(e.target.value + " Year");
                                }}
                            >
                                {years.map(year => (
                                    <SelectItem key={year} textValue={String(year) + " Year"} value={String(year)}>{year} Year</SelectItem>
                                ))}
                            </Select>
                            <Select
                                label="Department"
                                value={selectedDept}
                                onChange={(e) => setSelectedDept(e.target.value)}
                            >
                                {departments.map(dept => (
                                    <SelectItem key={dept} value={dept}>{dept}</SelectItem>
                                ))}
                            </Select>
                        </div>
                        <div className="form-row" style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                style={{ maxWidth: '200px', width: '100%' }}
                                className="incidentSubmitBtn"
                                onClick={handleAddSection}
                                disabled={!isAddSectionButtonEnabled}
                            >
                                Add Section
                            </Button>
                        </div>
                    </form>

                    <Table aria-label="Sections Table">
                        <TableHeader>
                            <TableColumn>Year</TableColumn>
                            <TableColumn>Department</TableColumn>
                            <TableColumn>Section Name</TableColumn>
                            <TableColumn>Mark</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {sections.map((section, index) => (
                                <TableRow key={index}>
                                    <TableCell>{section.year}</TableCell>
                                    <TableCell>{section.department}</TableCell>
                                    <TableCell>{section.sectionName}</TableCell>
                                    <TableCell>{section.mark}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <div className="flex justify-end items-center gap-4 mt-4">
                        <p><strong>Total Internal Marks:</strong> {totalMarks}</p>
                        <Button auto className="incidentSubmitBtn" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </div>

                    {/* Modal/Form */}
                    {isModalVisible && (
                        <Modal
                            isOpen={isModalVisible}
                            onOpenChange={handleCloseModal}
                            backdrop="opaque"
                            placement="center"
                            className="custom-modal"
                            style={{ maxWidth: "500px", width: "100%" }}
                        >
                            <ModalContent>
                                <ModalHeader>
                                    <h3 id="modal-title">Add Section</h3>
                                </ModalHeader>
                                <ModalBody>
                                    <div className="flex mb-6 gap-6">
                                        <Input
                                            labelPlacement="outside"
                                            label="Section Name"
                                            value={sectionName}
                                            onChange={(e) => setSectionName(e.target.value)}
                                        />
                                        <Input
                                            labelPlacement="outside"
                                            label="Mark"
                                            type="number"
                                            value={mark}
                                            onChange={(e) => setMark(e.target.value)}
                                        />
                                    </div>
                                </ModalBody>
                                <ModalFooter className="flex justify-center items-center gap-4">
                                    <Button auto className="bg-[#FF4D4F99]" onClick={handleCloseModal}>
                                        Close
                                    </Button>
                                    <Button auto className="incidentSubmitBtn" onClick={handleSectionSubmit}>
                                        Submit
                                    </Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    )}
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default InternalMarkSetup;
