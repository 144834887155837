import React from "react";
import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Input,
    Button,
    DropdownTrigger,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    User,
    Pagination,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Switch,
    Select,
    SelectItem
} from "@nextui-org/react";

import { VerticalDotsIcon } from "./VerticalDotsIcon";
import { ChevronDownIcon } from "./ChevronDownIcon";
import { StudentPlacementColumn, StudentPlacementList } from "../../data/data";
import { capitalize } from "../../utils/common";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS

const INITIAL_VISIBLE_COLUMNS = [
    "s_place_sno",
    "s_place_Companyname",
    "s_place_category",
    "s_place_date",
    "s_place_time",
    "s_place_respond",
    "s_place_result",
    "s_place_placed",
    "s_place_ctcreason",
];
export default function StudentPlacementTable() {
    const [isModelBox, setIsModelBox] = React.useState(false);
    const handleModelBoxClick = () => {
        setIsModelBox(!isModelBox);
    };

    const [filterValue, setFilterValue] = React.useState("");
    const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [isPlaced, setIsPlaced] = React.useState(false);

    const handleToggleChange = () => {
        setIsPlaced(!isPlaced);
    };


    const handleCloseModal = () => {
        setIsModalVisible(false); // Hide modal
    };

    const handleOpenAssignModal = () => {
        setIsModalVisible(false);
    }


    const [visibleColumns, setVisibleColumns] = React.useState(
        new Set(INITIAL_VISIBLE_COLUMNS)
    );
    const [statusFilter, setStatusFilter] = React.useState("all");
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [sortDescriptor, setSortDescriptor] = React.useState({
        column: "age",
        direction: "ascending",
    });
    const [page, setPage] = React.useState(1);

    const hasSearchFilter = Boolean(filterValue);

    const headerColumns = React.useMemo(() => {
        if (visibleColumns === "all") return StudentPlacementColumn;

        return StudentPlacementColumn.filter((column) =>
            Array.from(visibleColumns).includes(column.uid)
        );
    }, [visibleColumns]);

    const filteredItems = React.useMemo(() => {
        let filteredUsers = [...StudentPlacementList];
        if (hasSearchFilter) {
            filteredUsers = filteredUsers.filter((user) =>
                user.s_place_Companyname.toLowerCase().includes(filterValue.toLowerCase()) ||
                user.s_place_website.toLowerCase().includes(filterValue.toLowerCase()) ||
                user.s_place_category.toLowerCase().includes(filterValue.toLowerCase()) ||
                user.s_place_date.toLowerCase().includes(filterValue.toLowerCase()) ||
                user.s_place_time.toLowerCase().includes(filterValue.toLowerCase()) ||
                user.s_place_placed.toLowerCase().includes(filterValue.toLowerCase()) ||
                user.s_place_ctcreason.toLowerCase().includes(filterValue.toLowerCase())
            );
        }
        return filteredUsers;
    }, [hasSearchFilter, filterValue]);

    const pages = Math.ceil(filteredItems.length / rowsPerPage);

    const items = React.useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [page, filteredItems, rowsPerPage]);


    const sortedItems = React.useMemo(() => {
        return [...items].sort((a, b) => {
            const first = a[sortDescriptor.column];
            const second = b[sortDescriptor.column];
            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
    }, [sortDescriptor, items]);


    const handleIssueHallTicket = () => {
        toast.success("Submitted successfully!", {
            // position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
        });
    };

    const renderCell = React.useCallback((user, columnKey) => {
        const cellValue = user[columnKey];

        switch (columnKey) {
            case "name":
                return (
                    <User
                        avatarProps={{ radius: "lg", src: user.avatar }}
                        description={user.email}
                        name={cellValue}
                    >
                        {user.email}
                    </User>
                );
            case "s_place_result":
                return (
                    <Button className="incidentSubmitBtn" onClick={_ => { setIsModalVisible(true) }}>
                        Result
                    </Button>
                );

            case "s_place_respond":
                return (
                    <Button
                        className="incidentSubmitBtn"
                        type="submit"
                        onClick={handleIssueHallTicket}
                    >
                        Respond
                    </Button>
                );
            case "actions":
                return (
                    <div className="relative flex justify-center items-center gap-2">
                        <Dropdown className="dark:bg-neutral-800">
                            <DropdownTrigger>
                                <Button isIconOnly size="sm" variant="light">
                                    <VerticalDotsIcon className="text-default-300" />
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu>
                                <DropdownItem onClick={handleModelBoxClick}>View</DropdownItem>
                                {/* <DropdownItem onClick={handleModelBoxClick}>Edit</DropdownItem> */}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                );
            default:
                return cellValue;
        }
    }, []);

    const onRowsPerPageChange = React.useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    }, []);

    const onSearchChange = React.useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue("");
        }
    }, []);

    const onClear = React.useCallback(() => {
        setFilterValue("");
        setPage(1);
    }, []);

    const topContent = React.useMemo(() => {
        return (
            <div className="flex flex-col gap-4">
                <div className="flex justify-between gap-1 items-center">

                </div>
                <div className="flex justify-between items-center">
                    <span className="text-default-400 text-small">
                        Total {StudentPlacementList.length} Placement
                    </span>

                    <div className="flex justify-between basis-[40%] gap-4">
                        <Input
                            isClearable
                            className="w-full"
                            placeholder="Search"
                            value={filterValue}
                            onClear={() => onClear()}
                            onValueChange={onSearchChange}
                        />
                        <div className="flex gap-3">
                            <Dropdown>
                                <DropdownTrigger className="hidden sm:flex">
                                    <Button
                                        endContent={<ChevronDownIcon className="text-small" />}
                                        variant="flat"
                                    >
                                        Columns
                                    </Button>
                                </DropdownTrigger>
                                <DropdownMenu
                                    disallowEmptySelection
                                    aria-label="Table Columns"
                                    closeOnSelect={false}
                                    selectedKeys={visibleColumns}
                                    selectionMode="multiple"
                                    onSelectionChange={setVisibleColumns}
                                >
                                    {StudentPlacementColumn.map((column) => (
                                        <DropdownItem key={column.uid} className="capitalize">
                                            {capitalize(column.name)}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </div>

                        <label className="flex items-center text-default-400 text-small">
                            Rows:
                            <select
                                className="bg-transparent outline-none text-default-400 text-small"
                                onChange={onRowsPerPageChange}
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                            </select>
                        </label>
                    </div>
                </div>
            </div>
        );
    }, [
        onClear,
        filterValue,
        statusFilter,
        visibleColumns,
        onRowsPerPageChange,
        onSearchChange,
    ]);

    const bottomContent = React.useMemo(() => {
        return (
            <div className="py-2 px-2 flex justify-between items-center">
                <span className="w-[30%] text-small text-default-400">
                    {selectedKeys === "all"
                        ? "All items selected"
                        : `${selectedKeys.size} of ${filteredItems.length} selected`}
                </span>
                <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="secondary"
                    id="pagination"
                    page={page}
                    total={pages}
                    onChange={setPage}
                />
            </div>
        );
    }, [selectedKeys, filteredItems.length, page, pages]);

    return (
        <>
            <Table
                aria-label="Example table with custom cells, pagination and sorting"
                isHeaderSticky
                bottomContent={bottomContent}
                bottomContentPlacement="outside"
                classNames={{
                    wrapper: "max-h-[382px] scrollbar-thin scrollbar-thumb-gray scrollbar-track-gray-200 pt-0 pb-4 px-0",
                }}
                sortDescriptor={sortDescriptor}
                topContent={topContent}
                topContentPlacement="outside"
                onSelectionChange={setSelectedKeys}
                onSortChange={setSortDescriptor}
            >
                <TableHeader columns={headerColumns}>
                    {(column) => (
                        <TableColumn
                            key={column.uid}
                            align={column.uid === "actions" ? "center" : "start"}
                            allowsSorting={column.sortable}
                        >
                            {column.name}
                        </TableColumn>
                    )}
                </TableHeader>
                <TableBody emptyContent={"No users found"} items={sortedItems}>
                    {(item) => (
                        <TableRow key={item.id}>
                            {(columnKey) => (
                                <TableCell>{renderCell(item, columnKey)}</TableCell>
                            )}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <Modal
                isOpen={isModalVisible}
                onOpenChange={handleCloseModal}
                backdrop="opaque"
                placement="center"
                className="custom-modal"
                style={{ maxWidth: "500px", width: "100%" }}
            >
                <ModalContent>
                    <ModalHeader className="text-xl font-bold text-[#36A2EB]">
                        Submit Result
                    </ModalHeader>
                    <ModalBody>
                        <div className="flex flex-col gap-4 text-lg">
                            <div className="flex justify-between items-center">
                                <label>Placed?</label>
                                <div className="flex items-center">
                                    <Switch checked={isPlaced} onChange={handleToggleChange} />
                                </div>
                            </div>

                            {isPlaced ? (
                                <><div className="flex items-center">
                                    <span className={`ml-0 ${isPlaced ? 'text-green-500' : 'text-red-500'}`}>Yes</span>

                                </div>
                                    <Input label="CTC - PA" type="number" required />
                                    <Select
                                        label="Role"
                                    >
                                        <SelectItem key="male" value="male">
                                            Software Developer
                                        </SelectItem>
                                        <SelectItem key="female" value="female">
                                            Software Testing
                                        </SelectItem>
                                        <SelectItem key="female" value="female">
                                            Digital Marketing
                                        </SelectItem>
                                        <SelectItem key="female" value="female">
                                            Data Analyst
                                        </SelectItem>
                                    </Select>
                                    <Select
                                        label="Expected Skills"
                                    >
                                        <SelectItem key="API Development" value="API Development">
                                        API Development
                                        </SelectItem>
                                        <SelectItem key="Security Testing" value="Security Testing">
                                        Security Testing
                                        </SelectItem>
                                        <SelectItem key="Graphic Design" value="Graphic Design">
                                        Graphic Design
                                        </SelectItem>
                                        <SelectItem key="Statistical Analysis" value="Statistical Analysis">
                                        Statistical Analysis
                                        </SelectItem>
                                    </Select>
                                </>
                            ) : (<>

                                <span className={`mr-2 ${!isPlaced ? 'text-red-500' : 'text-green-500'}`}>No</span>
                                <Input label="Reason" placeholder="Enter Reason" type="text" required /></>
                            )}
                        </div>
                    </ModalBody>
                    <ModalFooter className="flex justify-center items-center gap-4">
                        <Button auto className="bg-[#FF4D4F99]" onClick={handleCloseModal}>
                            Close
                        </Button>
                        <Button auto className="incidentSubmitBtn" onClick={handleIssueHallTicket}>
                            Submit
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <ToastContainer />
        </>
    );
}
