import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "@nextui-org/react";
import "./ModelForm.css";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const DepartmentForm = ({ isOpen, onOpenChange, backdrop = "blur" }) => {
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent form from refreshing the page
    // Add your form submission logic here
    // console.log("Form submitted!");

    // Show success toast
    toast.success("Department added successfully!");
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        backdrop={backdrop}
        placement="center"
        style={{ maxWidth: "700px", width: "100%" }}
      >
        <ModalContent>
          {({ onClose }) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Add Department
              </ModalHeader>
              <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
                <form onSubmit={handleSubmit}>
                  <div className="form-row">
                    <Input
                      variant="bordered"
                      label="Department Code"
                      labelPlacement="outside"
                      required
                    />
                    <Input
                      variant="bordered"
                      label="Department Name"
                      labelPlacement="outside"
                      required
                    />
                  </div>
                  <ModalFooter className="flex justify-center items-center gap-4">
                    <Button color="danger" variant="flat" onClick={onClose}>
                      Close
                    </Button>
                    <Button className="incidentSubmitBtn" type="submit">
                      Submit
                    </Button>
                  </ModalFooter>
                </form>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default DepartmentForm;
