import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@nextui-org/react";
import "../components/baseComponent/ModelForm.css";

const PaymentDisplayScreenPage = () => {
  const navigate = useNavigate();

  const handleDownloadClick = () => {
    // Retrieve the college name from local storage
    const collegeName = localStorage.getItem('UserCollege');
    let pdfUrl;
    switch (collegeName) {
      case "SCE":
        pdfUrl = `${process.env.PUBLIC_URL}/invoice1_saranathan.pdf`;
        break;
      case "CGI":
        pdfUrl = `${process.env.PUBLIC_URL}/invoice1_care.pdf`;
        break;
      case "MAM":
        pdfUrl = `${process.env.PUBLIC_URL}/invoice1_mam.pdf`;
        break;
      case "MIET":
        pdfUrl = `${process.env.PUBLIC_URL}/invoice1_miet.pdf`;
        break;
      case "Vels":
        pdfUrl = `${process.env.PUBLIC_URL}/invoice1_vels.pdf`;
        break;
    }

    // Create a link element to download the PDF
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `${collegeName}_PaymentInvoice.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleBackClick = () => {
    // navigate(-1); 
    navigate("/student/Subjectdetails");
  };

  return (
    <div className="main-inner-wrapper leavemenu-wrapper">
      <div className="px-4">
        <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
          <h1 className="report-heading" style={{
            marginLeft: "0px", marginBottom: "40px", fontSize: "25px", fontWeight: "bold",
          }}>
            {/* Payment Message Page */}
          </h1>
          <p className="payment-success-message" style={{
            marginBottom: "20px", fontSize: "20px", fontWeight: "bold", textAlign: "center",
          }}>
            Payment completed successfully!
          </p>
          <div className="download-button-wrapper" style={{ textAlign: "center" }}>
            <Button
              className="download-invoice-button incidentSubmitBtn font-bold"
              onClick={handleDownloadClick}
            >
              Click to Download Receipt
            </Button>
          </div>
          <div className="back-button-wrapper" style={{ textAlign: "center", marginTop: "20px" }}>
            <Button
              className="back-button font-bold mb-4"
              onClick={handleBackClick}
            >
              Back
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDisplayScreenPage;
