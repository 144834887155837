import React, { useEffect, useState } from "react";
import {
    Button,
    Input,
    Select,
    SelectItem,
    Table,
    TableHeader,
    TableBody,
    TableCell,
    TableColumn,
    TableRow,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "@nextui-org/react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../components/baseComponent/ModelForm.css";
import { useMark } from "../data/Context";
import RevaluationEditTable from "./RevaluationEditTable";


const RevaluationEdit = () => {

    return (
        <div className="main-inner-wrapper leavemenu-wrapper">
            <div className="px-4">
                <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
                    <h1 className="report-heading" style={{ margin: "10px 0px", fontSize: "25px", fontWeight: "bold", marginLeft: "0px" }}>
                        Revaluation
                    </h1>
                    <RevaluationEditTable />

                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default RevaluationEdit;
