import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";

const SubjectPaymentDetailsForm = () => {
  const navigate = useNavigate();
  const [currentSemesterSubjects] = useState([
    { subject: "Artificial Intelligence I", code: "AD8402", semester: "04", amount: "300" },
    { subject: "Database Design and Management", code: "AD8401", semester: "04", amount: "300" },
    { subject: "Software Development Processes", code: "AD8001", semester: "04", amount: "300" },
    { subject: "Microprocessors and Microcontrollers", code: "EC8691", semester: "04", amount: "300" },
    { subject: "Mobile Applications Development", code: "AD8003", semester: "04", amount: "300" },
    { subject: "Object Oriented Programming", code: "CS8392", semester: "03", amount: "500" },
  ]);

  const [pastSemesterSubjects] = useState([
    { subject: "Data Science Laboratory", code: "AD8411", semester: "04", amount: "200" },
    { subject: "Artificial Intelligence Laboratory", code: "CS8481", semester: "04", amount: "200" },
  ]);

  const [sortConfig, setSortConfig] = useState({ key: 'semester', direction: 'asc' });

  const items = [...currentSemesterSubjects, ...pastSemesterSubjects];

  // Sorting function
  const sortedItems = [...items].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  // Handle header click for sorting
  const handleSort = (key) => {
    setSortConfig(prevSortConfig => {
      if (prevSortConfig.key === key) {
        return {
          key,
          direction: prevSortConfig.direction === 'asc' ? 'desc' : 'asc'
        };
      }
      return {
        key,
        direction: 'asc'
      };
    });
  };

  // Calculate the total fee
  const totalFee = sortedItems.reduce((total, subject) => total + parseFloat(subject.amount || 0), 0);

  return (
    <div className="main-inner-wrapper leavemenu-wrapper">
      <div className="px-4">
        <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
          <div className="flex justify-between gap-1 items-center mb-4">
            <h1 className="report-heading" style={{ marginLeft: "0px", fontSize: "25px", fontWeight: "bold" }}>Subject Details</h1>
            <div className="flex gap-4">
              <Input
                label="REGISTER NUMBER"
                type="text"
                labelPlacement="inside"
                defaultValue="812818114038"
                readOnly
                style={{ width: "135px" }}
              />
              <Input
                label="DEPARTMENT"
                type="text"
                labelPlacement="inside"
                defaultValue="AIDS"
                readOnly
                style={{ width: "90px" }}
              />
              <Input
                label="SEMESTER"
                type="text"
                labelPlacement="inside"
                defaultValue="04"
                readOnly
                style={{ width: "70px" }}
              />
            </div>
          </div>

          <Table aria-label="Subject Payment Details">
            <TableHeader>
              <TableColumn>
                <span onClick={() => handleSort('semester')}>
                  SEMESTER {sortConfig.key === 'semester' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
                </span>
              </TableColumn>
              <TableColumn>
                <span onClick={() => handleSort('code')}>
                  SUBJECT CODE {sortConfig.key === 'code' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
                </span>
              </TableColumn>
              <TableColumn>
                <span onClick={() => handleSort('subject')}>
                  SUBJECT NAME {sortConfig.key === 'subject' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
                </span>
              </TableColumn>
              <TableColumn>
                <span onClick={() => handleSort('amount')}>
                  FEE {sortConfig.key === 'amount' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
                </span>
              </TableColumn>
            </TableHeader>
            <TableBody>
              {sortedItems.map((subject, index) => (
                <TableRow key={index}>
                  <TableCell>{subject.semester}</TableCell>
                  <TableCell>{subject.code}</TableCell>
                  <TableCell>{subject.subject}</TableCell>
                  <TableCell>{subject.amount}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Grand Total
                </TableCell>
                <TableCell style={{ fontWeight: "bold", color: "red" }}>{totalFee}</TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <div className="flex justify-center items-center mt-4">
            <Button
              className="incidentSubmitBtn"
              onClick={() => navigate("/student/paymentforexam")}
            >
              Make Payment
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubjectPaymentDetailsForm;
