import React, { useState, useRef } from 'react';
import { useTheme } from 'next-themes';
import ArrearStaffTable from '../components/baseComponent/ArrearStaffTable';

const Arrearstaff = () => {
  
  const { theme } = useTheme();

  const fileInputRef = useRef(null);
  const [isModelBox, setIsModelBox] = useState(false);

  const handleModelBoxClick = () => {
    setIsModelBox(!isModelBox);
  };
  const handleDownloadClick = () => {
    // Retrieve the college name from local storage
    const collegeName = localStorage.getItem('UserCollege');
    let pdfUrl;
    switch (collegeName) {
      case "SCE":
        pdfUrl = `${process.env.PUBLIC_URL}/Hall_Ticket_Saranathan.pdf`;
        break;
      case "CGI":
        pdfUrl = `${process.env.PUBLIC_URL}/Hall_Ticket_CARE.pdf`;
        break;
      case "MAM":
        pdfUrl = `${process.env.PUBLIC_URL}/Hall_Ticket_MAM.pdf`;
        break;
      case "MIET":
        pdfUrl = `${process.env.PUBLIC_URL}/Hall_Ticket_MIET.pdf`;
        break;
    }
  
    // Create a link element to download the PDF
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `${collegeName}_HallTicket.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="main-inner-wrapper leavemenu-wrapper">
      <div className="px-4 mt-10">
        <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl" style={{ marginTop: '-50px' }}>
          <div className="flex justify-between items-center gap-4">
            <h1 className="report-heading"  style={{ marginLeft: "0px", fontWeight: "bold", fontSize: "25px" }}>Arrear History</h1>
          </div>
          <ArrearStaffTable />
        </div>
      </div>
      {/* <Userform isOpen={isModelBox} onOpenChange={handleModelBoxClick} backdrop="opaque"/> */}
    </div>
  );
};

export default Arrearstaff;
