import React, { useState, useEffect } from "react";
import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Input,
    Button,
    DropdownTrigger,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Pagination,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "@nextui-org/react";
import { ChevronDownIcon } from "../components/baseComponent/ChevronDownIcon";
import { RevaluationEditColumn, calculateGrade } from "../data/data";
import { capitalize } from "../utils/common";
import { toast } from "react-toastify";
import { useMark } from "../data/Context";

const INITIAL_VISIBLE_COLUMNS = [
    "actions",
    "reg_no",
    "name",
    "semester",
    "department",
    "subject",
    "progress",
    "subject_code",
    "mark",
    "grade",
];

export default function RevaluationEditTable() {
    const { RevaluationEditDetails, updateRevaluationEditDetails } = useMark();

    const [details, setDetails] = useState(RevaluationEditDetails || []);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [viewDetails, setViewDetails] = useState({});

    const handleCloseModal = () => {
        setIsModalVisible(false); // Hide modal
    };

    const handleSubmit = (indId, mark) => {
        const filteredDetails = details.map(val => {
            if (val.id === indId) {
                val.progress = "Completed";
                val.mark = mark;
                val.grade = calculateGrade(mark);
            }
            return val;
        });

        // Update the state and trigger the re-render
        setDetails(filteredDetails);
        updateRevaluationEditDetails([...filteredDetails]);

        toast.success("Revaluated successfully!", {
            autoClose: 1000, // 1 second
        });

        setIsModalVisible(false); // Close the modal
    };

    useEffect(() => {
        console.log("Updated details: ", details);
        console.log("Updated rev: ", RevaluationEditDetails);
    }, [details]);

    const [filterValue, setFilterValue] = useState("");
    const [selectedKeys, setSelectedKeys] = useState(new Set([]));
    const [visibleColumns, setVisibleColumns] = useState(new Set(INITIAL_VISIBLE_COLUMNS));
    const [statusFilter, setStatusFilter] = useState("all");
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [sortDescriptor, setSortDescriptor] = useState({
        column: "reg_no",
        direction: "ascending",
    });
    const [page, setPage] = useState(1);

    const headerColumns = visibleColumns === "all" ? RevaluationEditColumn : RevaluationEditColumn.filter(column => visibleColumns.has(column.uid));

    const filteredItems = details.filter(user => {
        if (!filterValue) return true;
        const checkField = field => typeof field === 'string' && field.toLowerCase().includes(filterValue.toLowerCase());
        return (
            checkField(user.regNo) ||
            checkField(user.name) ||
            checkField(user.semester) ||
            checkField(user.department) ||
            checkField(user.subject) ||
            checkField(user.subjectCode) ||
            checkField(user.mark) ||
            checkField(user.grade)
        );
    });

    const pages = Math.ceil(filteredItems.length / rowsPerPage);
    const items = filteredItems.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const sortedItems = items.sort((a, b) => {
        const first = a[sortDescriptor.column];
        const second = b[sortDescriptor.column];
        const cmp = first < second ? -1 : first > second ? 1 : 0;
        return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });

    const renderCell = (user, columnKey) => {
        const cellValue = user?.[columnKey];
        switch (columnKey) {
            case "name":
                return <p className="text-bold text-small whitespace-nowrap capitalize m-0">{user?.name}</p>;
            case "reg_no":
                return <p className="text-bold text-small whitespace-nowrap capitalize m-0">{user?.regNo}</p>;
            case "semester":
                return <p className="text-bold text-small whitespace-nowrap capitalize m-0">{user?.semester}</p>;
            case "department":
                return <p className="text-bold text-small whitespace-nowrap capitalize m-0">{user?.dept}</p>;
            case "progress":
                return <p className="text-bold text-small whitespace-nowrap capitalize m-0" style={{ color: user?.progress === "Pending" ? "red" : "green" }}>{user?.progress}</p>;
            case "subject":
                return <p className="text-bold text-small whitespace-nowrap capitalize m-0">{user?.subject}</p>;
            case "subject_code":
                return <p className="text-bold text-small whitespace-nowrap capitalize m-0">{user?.subjectCode}</p>;
            case "mark":
                return <p className="text-bold text-small whitespace-nowrap capitalize m-0">{user?.mark}</p>;
            case "grade":
                return <p className="text-bold text-small whitespace-nowrap capitalize m-0">{user?.grade}</p>;
            case "actions":
                return (
                    <div className="relative flex justify-center items-center gap-2">
                        <Button color="primary" size="sm" variant="light" onClick={() => { setViewDetails(user); user.progress == "Pending" ? setIsModalVisible(true) : setIsModalVisible(false); }}>
                            Review
                        </Button>
                    </div>
                );
            default:
                return cellValue;
        }
    };

    const onRowsPerPageChange = (e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    };

    const onSearchChange = (value) => {
        setFilterValue(value || "");
        setPage(1);
    };

    const onClear = () => {
        setFilterValue("");
        setPage(1);
    };

    const topContent = (
        <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center">
                <span className="text-default-400 text-small">Total {details.length} Revaluation</span>
                <div className="flex justify-between basis-[40%] gap-4">
                    <Input
                        isClearable
                        className="w-full"
                        placeholder="Search"
                        value={filterValue}
                        onClear={onClear}
                        onValueChange={onSearchChange}
                    />
                    <div className="flex gap-3">
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button endContent={<ChevronDownIcon className="text-small" />} variant="flat">
                                    Columns
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={visibleColumns}
                                selectionMode="multiple"
                                onSelectionChange={setVisibleColumns}
                            >
                                {RevaluationEditColumn.map((column) => (
                                    <DropdownItem key={column.uid} className="capitalize">
                                        {capitalize(column.name)}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <label className="flex items-center text-default-400 text-small">
                        Rows:
                        <select
                            className="bg-transparent outline-none text-default-400 text-small"
                            onChange={onRowsPerPageChange}
                        >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                        </select>
                    </label>
                </div>
            </div>
        </div>
    );

    const bottomContent = (
        <div className="py-2 px-2 flex justify-between items-center">
            <span className="w-[30%] text-small text-default-400">
                {selectedKeys === "all"
                    ? "All items selected"
                    : `${selectedKeys.size} of ${filteredItems.length} selected`}
            </span>
            <Pagination
                isCompact
                showControls
                showShadow
                color="secondary"
                id="pagination"
                page={page}
                total={pages}
                onChange={setPage}
            />
        </div>
    );

    return (
        <>
            <Table
                aria-label="Example table with custom cells, pagination and sorting"
                isHeaderSticky
                bottomContent={bottomContent}
                bottomContentPlacement="outside"
                classNames={{ wrapper: "max-h-[382px]" }}
                sortDescriptor={sortDescriptor}
                topContent={topContent}
                topContentPlacement="outside"
                onSelectionChange={setSelectedKeys}
                onSortChange={setSortDescriptor}
            >
                <TableHeader columns={headerColumns}>
                    {(column) => (
                        <TableColumn
                            key={column.uid}
                            align={column.uid === "actions" ? "center" : "start"}
                            allowsSorting={column.sortable}
                        >
                            {column.name}
                        </TableColumn>
                    )}
                </TableHeader>
                <TableBody>
                    {sortedItems.map((user) => (
                        <TableRow key={user.id}>
                            {headerColumns.map((column) => (
                                <TableCell key={column.uid}>
                                    {renderCell(user, column.uid)}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Modal
                isOpen={isModalVisible}
                onOpenChange={handleCloseModal}
                backdrop="opaque"
                placement="center"
                className="custom-modal"
                style={{ maxWidth: "500px", width: "100%" }}
            >
                <ModalContent>
                    <ModalHeader className="text-xl font-bold text-[#36A2EB]">
                        Revaluation
                    </ModalHeader>
                    <ModalBody>
                        <div className="flex gap-6 my-2">
                            <Input
                                variant="bordered"
                                label="Register No"
                                labelPlacement="inside"
                                className="bg-[#eee]"
                                readOnly
                                value={viewDetails.regNo}
                            />
                            <Input
                                variant="bordered"
                                label="Name"
                                labelPlacement="inside"
                                className="bg-[#eee]"
                                readOnly
                                value={viewDetails.name}
                            />
                        </div>
                        <div className="flex gap-6 my-2">
                            <Input
                                variant="bordered"
                                label="Semester"
                                labelPlacement="inside"
                                className="bg-[#eee]"
                                readOnly
                                value={viewDetails.semester}
                            />
                            <Input
                                variant="bordered"
                                label="Department"
                                labelPlacement="inside"
                                className="bg-[#eee]"
                                readOnly
                                value={viewDetails.dept}
                            />
                        </div>
                        <div className="flex gap-6 my-2">
                            <Input
                                variant="bordered"
                                label="Subject"
                                labelPlacement="inside"
                                className="bg-[#eee]"
                                readOnly
                                value={viewDetails.subject}
                            />
                            <Input
                                variant="bordered"
                                label="Subject Code"
                                labelPlacement="inside"
                                className="bg-[#eee]"
                                readOnly
                                value={viewDetails.subjectCode}
                            />
                        </div>
                        <div className="flex gap-6 my-2">
                            <Input
                                variant="bordered"
                                label="Mark"
                                labelPlacement="inside"
                                className="bg-[#fff]"
                                onChange={e => {
                                    const value = e.target.value;

                                    if(!isNaN(value) && value <= 100){
                                        setViewDetails({...viewDetails, mark : value})
                                    }
                                }}
                                value={viewDetails.mark}
                            />
                            <Input
                                variant="bordered"
                                label="Grade"
                                labelPlacement="inside"
                                className="bg-[#eee]"
                                readOnly
                                value={calculateGrade(Number(viewDetails.mark))}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter className="flex justify-center items-center gap-4">
                        <Button auto className="bg-[#FF4D4F99]" onClick={handleCloseModal}>
                            Close
                        </Button>
                        <Button auto className="incidentSubmitBtn" onClick={_ => handleSubmit(viewDetails.id , viewDetails.mark)}>
                            Update
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
