import React, { useState } from "react";
import {
    Button,
    Input,
    Select,
    SelectItem,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "@nextui-org/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MarkModeration = () => {
    const [year, setYear] = useState("");
    const [semester, setSemester] = useState("");
    const [department, setDepartment] = useState("");
    const [subjectCode, setSubjectCode] = useState("");
    const [subjectName, setSubjectName] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showPassCriteria, setShowPassCriteria] = useState(false);

    const gradeData = [
        { passNumber: 105, failNumber: 15, totalStudents: 120 },
    ];

    const studentData = [
        { registerNumber: "812818114038", mark: 39 },
        { registerNumber: "812818114255", mark: 37 },
        { registerNumber: "812818114067", mark: 38 },
        { registerNumber: "812818114090", mark: 37 },
        { registerNumber: "812818114117", mark: 39 },
        { registerNumber: "812818114204", mark: 39 },
        { registerNumber: "812818114066", mark: 32 },
        { registerNumber: "812818114274", mark: 39 },
        { registerNumber: "812818114565", mark: 38 },
        { registerNumber: "812818114273", mark: 25 },
    ];

    const handleSubmit = (event) => {
        event.preventDefault();
        toast.success("Mark moderation setup submitted successfully!");
    };

    const handleReviewClick = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setShowPassCriteria(false); // Reset pass criteria view on modal close
    };

    const handleSetPassCriteriaClick = () => {
        setShowPassCriteria(true);
    };

    const handleSubmitPassCriteria = () => {
        // Close the modal and show the success message
        handleModalClose();
        toast.success("Pass criteria set successfully!");
    };

    return (
        <div className="main-inner-wrapper leavemenu-wrapper">
            <div className="px-4">
                <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
                    <h1 className="report-heading" style={{ fontSize: "25px", fontWeight: "bold", marginBottom: "20px", marginLeft: "0px", marginTop: "8px" }}>
                        Mark Moderation
                    </h1>
                    <form onSubmit={handleSubmit}>
                        {/* First Row: Year, Semester, Department */}
                        <div className="form-row" style={{ marginBottom: "16px", display: 'flex', gap: '16px' }}>
                            <Select
                                label="Year"
                                value={year}
                                onChange={(value) => setYear(value)}
                                fullWidth
                            >
                                <SelectItem value="1">1st Year</SelectItem>
                                <SelectItem value="2">2nd Year</SelectItem>
                                <SelectItem value="3">3rd Year</SelectItem>
                                <SelectItem value="4">4th Year</SelectItem>
                            </Select>

                            <Select
                                label="Semester"
                                value={semester}
                                onChange={(value) => setSemester(value)}
                                fullWidth
                            >
                                <SelectItem value="1">Semester 1</SelectItem>
                                <SelectItem value="2">Semester 2</SelectItem>
                                <SelectItem value="3">Semester 3</SelectItem>
                                <SelectItem value="4">Semester 4</SelectItem>
                                <SelectItem value="5">Semester 5</SelectItem>
                                <SelectItem value="6">Semester 6</SelectItem>
                                <SelectItem value="7">Semester 7</SelectItem>
                                <SelectItem value="8">Semester 8</SelectItem>
                            </Select>

                            <Select
                                label="Department"
                                value={department}
                                onChange={(value) => setDepartment(value)}
                                fullWidth
                            >
                                <SelectItem key="be_marine" value="be_marine">
                                    B.E Marine Engineering
                                </SelectItem>
                                <SelectItem key="be_areo" value="be_areo">
                                    B.E Aerospace Engineering
                                </SelectItem>
                                <SelectItem key="be_mine" value="be_mine">
                                    B.E Mining Engineering
                                </SelectItem>
                                <SelectItem key="btech_cse" value="btech_cse">
                                    B.E Computer Science Engineering
                                </SelectItem>
                                <SelectItem key="btech_it" value="btech_it">
                                    B.Tech Information Technology
                                </SelectItem>
                                <SelectItem key="btech_aids" value="btech_aids">
                                    B.Tech Artificial Intelligence and Data Science
                                </SelectItem>
                                <SelectItem key="btech_ece" value="btech_ece">
                                    B.E Electronics and Communication Engineering
                                </SelectItem>
                                <SelectItem key="btech_mechanical" value="btech_mechanical">
                                    B.E Mechanical Engineering
                                </SelectItem>
                                <SelectItem key="btech_civil" value="btech_civil">
                                    B.E Civil Engineering
                                </SelectItem>
                            </Select>
                        </div>

                        {/* Second Row: Subject Code, Subject Name */}
                        <div className="form-row" style={{ marginBottom: "16px", display: 'flex', gap: '16px' }}>
                            <Input
                                variant="bordered"
                                label="Subject Code"
                                required
                                name="subjectCode"
                                value={subjectCode}
                                onChange={(e) => setSubjectCode(e.target.value)}
                                fullWidth
                            />
                            <Input
                                variant="bordered"
                                label="Subject Name"
                                required
                                name="subjectName"
                                value={subjectName}
                                onChange={(e) => setSubjectName(e.target.value)}
                                fullWidth
                            />
                        </div>

                        {/* Table for Mark Moderation */}
                        <Table aria-label="Mark Moderation Table">
                            <TableHeader>
                                <TableColumn>Total Number of Students</TableColumn>
                                <TableColumn>Pass Students</TableColumn>
                                <TableColumn>Fail Students</TableColumn>
                                <TableColumn>Action</TableColumn>
                            </TableHeader>
                            <TableBody>
                                {gradeData.map((data, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{data.totalStudents}</TableCell>
                                        <TableCell>{data.passNumber}</TableCell>
                                        <TableCell>{data.failNumber}</TableCell>
                                        <TableCell>
                                            <Button className="incidentSubmitBtn" onClick={handleReviewClick}>
                                                Review
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <div className="flex justify-center items-center gap-4 mt-10">
                            <Button className="incidentSubmitBtn" type="submit">
                                Submit
                            </Button>
                            <Button color="danger" variant="flat">
                                Cancel
                            </Button>
                        </div>
                    </form>
                </div>
            </div>

            {/* Modal for Review */}
            <Modal
                isOpen={isModalOpen}
                onOpenChange={handleModalClose}
                backdrop="blur"
                placement="center"
                style={{ maxWidth: "700px", width: "100%" }}
            >
                <ModalContent>
                    {({ onClose }) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">
                                Review Marks
                            </ModalHeader>
                            <ModalBody style={{ maxHeight: "500px", overflowY: "auto" }}>
                                {/* Table for Register Number and Marks */}
                                <Table aria-label="Student Marks Table" style={{ marginBottom: "16px" }}>
                                    <TableHeader>
                                        <TableColumn>Register Number</TableColumn>
                                        <TableColumn>Mark</TableColumn>
                                    </TableHeader>
                                    <TableBody>
                                        {studentData.map((student, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{student.registerNumber}</TableCell>
                                                <TableCell>{student.mark}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>

                                {/* Button to set Pass Criteria */}
                                {!showPassCriteria && (
                                    <div style={{ textAlign: "center", marginTop: "16px" }}>
                                        <Button className="incidentSubmitBtn" onClick={handleSetPassCriteriaClick}>
                                            Set Pass Criteria
                                        </Button>
                                    </div>
                                )}

                                {/* Pass Criteria Inputs */}
                                {showPassCriteria && (
                                    <>
                                        <div className="form-row" style={{ marginTop: "16px", display: "flex", gap: "16px" }}>
                                            <Input
                                                variant="bordered"
                                                label="Mark From"
                                                labelPlacement="outside"
                                                required
                                                fullWidth
                                            />
                                            <Input
                                                variant="bordered"
                                                label="Mark To"
                                                labelPlacement="outside"
                                                required
                                                fullWidth
                                            />
                                        </div>
                                        <ModalFooter className="flex justify-center items-center gap-4" style={{ marginTop: "16px" }}>
                                            <Button color="danger" variant="flat" onClick={handleModalClose}>
                                                Cancel
                                            </Button>
                                            <Button className="incidentSubmitBtn" type="button" onClick={handleSubmitPassCriteria}>
                                                Submit
                                            </Button>
                                        </ModalFooter>
                                    </>
                                )}
                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal>
            <ToastContainer />
        </div>
    );
};

export default MarkModeration;
