import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast , ToastContainer} from "react-toastify";
import {
    Input,
    Table,
    TableHeader,
    TableBody,
    TableCell,
    TableColumn,
    TableRow,
    Button,
    Tabs,
    Tab,
    Select,
    SelectItem
} from "@nextui-org/react";
// import "./ModelForm.css";
import { useMark } from "../data/Context";
import { departments } from "../data/data";

const MarkEntry2 = () => {
    const [dummyNumber, setDummyNumber] = useState([]);
    const [selectedYear, setSelectedYear] = useState(0);
    const [selectedDept, setSelectedDept] = useState("")
    const [selectedSubject, setSelectedSubject] = useState("")
    const [selectedSemester, setSelectedSemester] = useState("")
    const [subjectCode, setSubjectCode] = useState("")
    const [dummyFrom, setDummyFrom] = useState("")
    const [dummyTo, setDummyTo] = useState("")
    const [marks, setMarks] = useState({})
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState(""); // State to hold file name
    const fileInputRef = useRef(null);
    const [activeTab, setActiveTab] = useState(0);
    ;

    const handleMarkChange = (dummy, value) => {
        if (!isNaN(value) && value <= 100) {
            setMarks(prevMarks => ({ ...prevMarks, [dummy]: value }));
        }
    };



    useEffect(() => {
        if (selectedSubject) {
            const foundDept = departments.find(dept => dept.name === selectedDept);
            const foundYear = foundDept?.years.find(year => year.year === selectedYear);
            const foundSem = foundYear?.semesters.find(sem => sem.semester === selectedSemester);
            const foundSub = foundSem?.subjects.find(sub => sub.name === selectedSubject);

            setDummyNumber(foundSub?.dummyNo || []);
        }
    }, [selectedSubject, selectedDept, selectedYear, selectedSemester]);

    useEffect(() => {
        if (dummyNumber.length && dummyFrom && dummyTo) {
            const startIndex = dummyNumber.indexOf(dummyFrom);
            const endIndex = dummyNumber.indexOf(dummyTo) + 1; // +1 to include the end item
            const extracted = dummyNumber.slice(startIndex, endIndex);
            setDummyNumber(extracted);
        }
    }, [dummyFrom, dummyTo]);

    const handleSubmit = () => {
        // Reset state variables
        setDummyNumber([]);
        setSelectedYear(0);
        setSelectedDept("");
        setSelectedSubject("");
        setSelectedSemester("");
        setSubjectCode("");
        setDummyFrom("");
        setDummyTo("");
        setMarks({});

        toast.success("Mark has been successfully entered", {
            autoClose: 1000 // Duration in milliseconds
        });
    };

    
    const calSem = val => {
        if (val == 1) {
            return "1st Semester"
        }
        else if (val == 2) {
            return "2nd Semester"
        }
        else if (val == 3) {
            return "3rd Semester"
        }
        else if (val == 4) {
            return "4th Semester"
        }
        else if (val == 5) {
            return "5th Semester"
        }
        else if (val == 6) {
            return "6th Semester"
        }
        else if (val == 7) {
            return "7th Semester"
        }
        else if (val == 8) {
            return "8th Semester"
        }
    }

    useEffect(() => {
        // This effect will run when selectedSubject, selectedDept, or other relevant states change
        // You can add logic here if you need to fetch or reset data based on state changes
    }, [selectedSubject, selectedDept, selectedYear, selectedSemester]);

    const renderSem = val => {
        let num = 0;
        if (val == 1) {
            num = 2;
        } else if (val == 2) {
            num = 4;
        } else if (val == 3) {
            num = 6;
        } else if (val == 4) {
            num = 8;
        }
        return num
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setFileName(file ? file.name : "");
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const handleSubject = (nam, code) => {
        setSelectedSubject(nam);
        setSubjectCode(code)
    }

    const handleDept = nam => {
        setSelectedDept(nam);
        setSubjectCode("");
    }

    return (
        <div className="main-inner-wrapper leavemenu-wrapper">
            <div className="px-4">
                <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
                    <div className="flex justify-between items-center gap-4">
                        <h1 className="report-heading" id="report-heading">Mark Entry</h1>
                        <button className="py-2 mb-4 px-3 bg-indigo-500  text-xs font-semibold rounded-md shadow focus:outline-none incidentSubmitBtn" onClick={triggerFileInput}>
                            Import from excel
                        </button>
                        <input type='file' ref={fileInputRef} hidden onChange={handleFileChange} />
                    </div>
                    <div className="form-container">
                        <form>
                            <div className="flex justify-center gap-4 mb-6 items-center">
                                <Select
                                    labelPlacement="outside"
                                    label="Year"
                                    value={selectedYear}
                                >
                                    <SelectItem onClick={_ => { setSelectedYear(1); setSubjectCode("") }}>
                                        1st Year
                                    </SelectItem>
                                    <SelectItem onClick={_ => { setSelectedYear(2); setSubjectCode("") }}>
                                        2nd Year
                                    </SelectItem>
                                    <SelectItem onClick={_ => { setSelectedYear(3); setSubjectCode("") }}>
                                        3rd Year
                                    </SelectItem>
                                    <SelectItem onClick={_ => { setSelectedYear(4); setSubjectCode("") }}>
                                        4th Year
                                    </SelectItem>
                                </Select>
                                <Select
                                    labelPlacement="outside"
                                    label="Department"
                                // onChange={handleSelectChange}
                                >
                                    {
                                        departments.map((val, index) =>
                                            <SelectItem key={index} onClick={_ => handleDept(val.name)} value={val.name}>
                                                {val.name}
                                            </SelectItem>
                                        )
                                    }
                                </Select>
                                <Select
                                    labelPlacement="outside"
                                    label="Semester"
                                >
                                    {
                                        selectedDept && selectedYear &&
                                        Array.from({ length: renderSem(selectedYear) }, (_, i) => (
                                            <SelectItem
                                                key={i}
                                                onClick={() => setSelectedSemester(i + 1)}
                                                value={calSem(i + 1)}
                                            >
                                                {calSem(i + 1)}
                                            </SelectItem>
                                        ))

                                    }
                                </Select>
                                <Select
                                    labelPlacement="outside"
                                    label="Subject"
                                >
                                    {
                                        selectedDept && selectedYear && selectedSemester &&
                                        departments
                                            .find(dept => dept.name === selectedDept) // Find the department object
                                            ?.years.find(year => year.year === selectedYear) // Find the year object
                                            ?.semesters.find(sem => sem.semester === selectedSemester)
                                            ?.subjects.map(sub =>
                                                <SelectItem key={sub.code} onClick={() => handleSubject(sub.name, sub.code)} value={sub.name}>
                                                    {sub.name}
                                                </SelectItem>
                                            )
                                    }
                                </Select>

                            </div>
                            <div className="flex justify-center gap-4 mb-6 items-center">
                                <Input
                                    labelPlacement="outside"
                                    label="Subject Code"
                                    readOnly
                                    value={selectedSubject ? subjectCode : ""}
                                />
                                <Select
                                    labelPlacement="outside"
                                    label="Dummy No (From)"
                                >
                                    {
                                        selectedDept && selectedYear && selectedSemester &&
                                        departments
                                            .find(dept => dept.name === selectedDept) // Find the department object
                                            ?.years.find(year => year.year === selectedYear) // Find the year object
                                            ?.semesters.find(sem => sem.semester === selectedSemester)
                                            ?.subjects.find(sub => sub.name === selectedSubject)
                                            ?.dummyNo.map(dummy =>
                                                <SelectItem key={dummy} onClick={() => setDummyFrom(dummy)} value={dummy}>
                                                    {dummy}
                                                </SelectItem>
                                            )
                                    }
                                </Select>
                                <Select
                                    labelPlacement="outside"
                                    label="Dummy No (To)"
                                >
                                    {
                                        selectedDept && selectedYear && selectedSemester && dummyFrom &&
                                        departments
                                            .find(dept => dept.name === selectedDept)
                                            ?.years.find(year => year.year === selectedYear)
                                            ?.semesters.find(sem => sem.semester === selectedSemester)
                                            ?.subjects.find(sub => sub.name === selectedSubject)
                                            ?.dummyNo.slice(
                                                (departments
                                                    .find(dept => dept.name === selectedDept)
                                                    ?.years.find(year => year.year === selectedYear)
                                                    ?.semesters.find(sem => sem.semester === selectedSemester)
                                                    ?.subjects.find(sub => sub.name === selectedSubject)
                                                    ?.dummyNo.indexOf(dummyFrom) + 1)
                                            )
                                            .map(dummy =>
                                                <SelectItem key={dummy} onClick={() => setDummyTo(dummy)} value={dummy}>
                                                    {dummy}
                                                </SelectItem>
                                            )
                                    }
                                </Select>
                            </div>
                        </form>
                    </div>
                    <Tabs selectedIndex={activeTab} onChange={setActiveTab}>
                        <Tab title="Overall Mark">
                            {dummyTo &&
                                <>
                                    <div className="flex flex-col gap-6 mt-4 items-center">
                                        {
                                            dummyNumber.map(val =>
                                                <Input
                                                    key={val}
                                                    label={`${val} : `}
                                                    placeholder="Enter Total Marks"
                                                    labelPlacement="outside-left"
                                                    style={{ width: "150px" }}
                                                    value={marks[val] || ''}
                                                    onChange={(e) => handleMarkChange(val, e.target.value)}
                                                />
                                            )
                                        }
                                    </div>
                                    <div className="flex justify-center">
                                        <Button className="incidentSubmitBtn" onClick={handleSubmit}>
                                            Submit
                                        </Button>
                                    </div>
                                </>
                            }

                        </Tab>
                        {/* <Tab title="Section Marks">
                            <Table aria-label="Sections Table" className="mb-6">
                                <TableHeader>
                                    <TableColumn className="text-center">Section Name</TableColumn>
                                    <TableColumn className="text-center">No Of Questions</TableColumn>
                                    <TableColumn className="text-center">Mark</TableColumn>
                                    {Array.from({ length: maxQuestions }, (_, i) => (
                                        <TableColumn className="text-center" key={i + 1}>{i + 1}</TableColumn>
                                    ))}
                                </TableHeader>
                                <TableBody>
                                    {questionModal.map((section, sectionIndex) => (
                                        <TableRow key={sectionIndex} className="text-center">
                                            <TableCell>{section.sectionName}</TableCell>
                                            <TableCell>{section.numOfQuestions}</TableCell>
                                            <TableCell className="min-w-[80px]">{section.mark} Mark</TableCell>
                                            {Array.from({ length: maxQuestions }, (_, questionIndex) => (
                                                <TableCell>
                                                    {questionIndex < section.numOfQuestions &&
                                                        <Input
                                                            aria-label={`Input ${questionIndex + 1}`}
                                                            type="text" // Change type to text
                                                            className="min-w-[40px]"
                                                            value={inputValues[sectionIndex][questionIndex]}
                                                            onChange={(e) => handleInputChange(sectionIndex, questionIndex, e.target.value)}
                                                            max={section.mark}
                                                            min={0}
                                                        />
                                                    }
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <div className="flex mx-12 gap-7 mb-4 items-center justify-end ">
                                <Input
                                    placeholder="Total Marks"
                                    variant="bordered"
                                    className="max-w-[150px]"
                                    value={totalMarks}
                                    disabled
                                />
                                <Button className="incidentSubmitBtn" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </div>
                        </Tab> */}
                    </Tabs>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default MarkEntry2;
