import React, { useEffect, useState } from "react";
import { Button, Input, Select, SelectItem } from "@nextui-org/react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Feesetup = () => {
    const [selected, setSelected] = useState(new Set(["Separate Fees"]));
    // Inline styles
    const formRowStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '16px', // Adjust the gap between input fields
    };

    const inputGroupStyle = {
        display: 'flex',
        alignItems: 'center', // Align items vertically in the center
        gap: '10px', // Gap between label and input field
        width: '100%', // Ensure input fields take full width within flex row
        maxWidth: '500px', // Adjust as needed
    };

    const labelStyle = {
        flexShrink: '0', // Prevent label from shrinking
        width: '150px', // Set a fixed width for the label
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent form from refreshing the page

        // Show success toast
        toast.success("Fees setup successfully!");
    };

    return (
        <div className="main-inner-wrapper leavemenu-wrapper">
            <div className="px-4">
                <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
                    <h1 className="report-heading" id="report-heading">Fee Setup</h1>
                    <div className="form-container">
                        <form onSubmit={handleSubmit}>
                            <div className="form-row" style={formRowStyle}>
                                <Select
                                    label="Year"
                                    className="max-w-full"
                                    variant="bordered"
                                    required
                                >
                                    <SelectItem key="year1" value="year1">
                                        1 Year
                                    </SelectItem>
                                    <SelectItem key="year2" value="year2">
                                        2 Year
                                    </SelectItem>
                                    <SelectItem key="year3" value="year3">
                                        3 Year
                                    </SelectItem>
                                    <SelectItem key="year4" value="year4">
                                        4 Year
                                    </SelectItem>
                                </Select>
                                <Select
                                    label="Department"
                                    className="max-w-full"
                                    variant="bordered"
                                    required
                                >
                                    <SelectItem key="be_marine" value="be_marine">
                                        BE Marine Engineering
                                    </SelectItem>
                                    <SelectItem key="be_areo" value="be_areo">
                                        BE Aerospace Engineering
                                    </SelectItem>
                                    <SelectItem key="be_ice" value="be_ice">
                                        BE Instrumentation and Control Engineering
                                    </SelectItem>
                                    <SelectItem key="be_mine" value="be_mine">
                                        BE Mining Engineering
                                    </SelectItem>
                                    <SelectItem key="be_food" value="be_food">
                                        BE Food Technology
                                    </SelectItem>
                                    <SelectItem key="btech_cse" value="btech_cse">
                                        B.Tech Computer Science Engineering
                                    </SelectItem>
                                    <SelectItem key="btech_aids" value="btech_aids">
                                        B.Tech Artificial Intelligence and Data Science
                                    </SelectItem>
                                    <SelectItem key="btech_ece" value="btech_ece">
                                        B.Tech Electronics and Communication Engineering
                                    </SelectItem>
                                    <SelectItem key="btech_mechanical" value="btech_mechanical">
                                        B.Tech Mechanical Engineering
                                    </SelectItem>
                                    <SelectItem key="btech_civil" value="btech_civil">
                                        B.Tech Civil Engineering
                                    </SelectItem>
                                    <SelectItem key="btech_chemical" value="btech_chemical">
                                        B.Tech Chemical Engineering
                                    </SelectItem>
                                    <SelectItem key="btech_it" value="btech_it">
                                        B.Tech Information Technology
                                    </SelectItem>
                                </Select>
                                <Select
                                    label="Semester"
                                    className="max-w-full"
                                    variant="bordered"
                                    required
                                >
                                    <SelectItem key="sem1" value="sem1">
                                        Semester 1
                                    </SelectItem>
                                    <SelectItem key="sem2" value="sem2">
                                        Semester 2
                                    </SelectItem>
                                    <SelectItem key="sem3" value="sem3">
                                        Semester 3
                                    </SelectItem>
                                    <SelectItem key="sem4" value="sem4">
                                        Semester 4
                                    </SelectItem>
                                    <SelectItem key="sem5" value="sem5">
                                        Semester 5
                                    </SelectItem>
                                    <SelectItem key="sem6" value="sem6">
                                        Semester 6
                                    </SelectItem>
                                    <SelectItem key="sem7" value="sem7">
                                        Semester 7
                                    </SelectItem>
                                    <SelectItem key="sem8" value="sem8">
                                        Semester 8
                                    </SelectItem>
                                </Select>
                                <Select
                                    label="Fees Type"
                                    className="max-w-full"
                                    variant="bordered"
                                    required
                                    selectedKeys={selected}
                                    onSelectionChange={setSelected}
                                >
                                    <SelectItem key="Separate Fees" value="Separate Fees">
                                        Separate Fee
                                    </SelectItem>
                                    <SelectItem key="Combined Fee" value="Combined Fee">
                                        Combined Fee
                                    </SelectItem>
                                </Select>
                            </div>

                            {/* Current Semester Fees */}
                            <h2 className="text-xl font-semibold mt-4">Current Semester Fees</h2>
                            <div className="form-row" style={formRowStyle}>
                                {
                                    selected.values().next().value === "Separate Fees" &&
                                    <>
                                        <div className="input-group" style={inputGroupStyle}>
                                            <label htmlFor="currentTheory" style={labelStyle}>Theory</label>
                                            <Input
                                                id="currentTheory"
                                                variant="bordered"
                                                labelPlaceholder="Theory"
                                                type="number"
                                                required
                                            />
                                        </div>
                                        <div className="input-group" style={inputGroupStyle}>
                                            <label htmlFor="currentPractical" style={labelStyle}>Practical</label>
                                            <Input
                                                id="currentPractical"
                                                variant="bordered"
                                                labelPlaceholder="Practical"
                                                type="number"
                                                required
                                            />
                                        </div>
                                    </>
                                }
                                {
                                    selected.values().next().value == "Combined Fee" &&
                                    <div className="input-group" style={inputGroupStyle}>
                                        <label htmlFor="currentTheory" style={labelStyle}>Total Fee</label>
                                        <Input
                                            id="currentTheory"
                                            variant="bordered"
                                            className="max-w-[260px]"
                                            labelPlaceholder="Theory"
                                            type="number"
                                            required
                                        />
                                    </div>
                                }
                            </div>

                            {/* Arrear Semester Fees */}
                            <h2 className="text-xl font-semibold mt-4">Arrear Semester Fees</h2>
                            <div className="form-row" style={formRowStyle}>
                            {
                                    selected.values().next().value === "Separate Fees" &&
                                    <>
                                        <div className="input-group" style={inputGroupStyle}>
                                            <label htmlFor="currentTheory" style={labelStyle}>Theory</label>
                                            <Input
                                                id="currentTheory"
                                                variant="bordered"
                                                labelPlaceholder="Theory"
                                                type="number"
                                                required
                                            />
                                        </div>
                                        <div className="input-group" style={inputGroupStyle}>
                                            <label htmlFor="currentPractical" style={labelStyle}>Practical</label>
                                            <Input
                                                id="currentPractical"
                                                variant="bordered"
                                                labelPlaceholder="Practical"
                                                type="number"
                                                required
                                            />
                                        </div>
                                    </>
                                }
                                {
                                    selected.values().next().value == "Combined Fee" &&
                                    <div className="input-group" style={inputGroupStyle}>
                                        <label htmlFor="currentTheory" style={labelStyle}>Total Fee</label>
                                        <Input
                                            id="currentTheory"
                                            variant="bordered"
                                            className="max-w-[260px]"
                                            labelPlaceholder="Theory"
                                            type="number"
                                            required
                                        />
                                    </div>
                                }
                            </div>

                            {/* Late Payment */}
                            <h2 className="text-xl font-semibold mt-4">Late Payment</h2>
                            <div className="form-row" style={formRowStyle}>
                                <div className="input-group" style={inputGroupStyle}>
                                    <label htmlFor="latePayment" style={labelStyle}>Amount</label>
                                    <Input
                                        id="latePayment"
                                        variant="bordered"
                                        labelPlaceholder="Payment"
                                        type="number"
                                        required
                                    />
                                </div>

                                <div className="input-group" style={inputGroupStyle}>

                                </div>
                            </div>

                            <h2 className="text-xl font-semibold mt-4">Revalulation</h2>
                            <div className="form-row" style={formRowStyle}>
                                <div className="input-group" style={inputGroupStyle}>
                                    <label htmlFor="latePayment" style={labelStyle}>Amount</label>
                                    <Input
                                        id="latePayment"
                                        variant="bordered"
                                        labelPlaceholder="Payment"
                                        type="number"
                                        required
                                    />
                                </div>

                                <div className="input-group" style={inputGroupStyle}>

                                </div>
                            </div>

                            <div className="flex justify-center items-center gap-4 mt-4">
                                <Button className="incidentSubmitBtn" type="submit">
                                    Submit
                                </Button>
                                <Button color="danger" variant="flat">
                                    Cancel
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* Toast Container */}
            <ToastContainer />
        </div>
    );
};

export default Feesetup;
