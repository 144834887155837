import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Input,
    Select,
    SelectItem,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "@nextui-org/react";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";

const DummyNumber = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [startRegisterNumber, setStartRegisterNumber] = useState("");
    const [endRegisterNumber, setEndRegisterNumber] = useState("");
    const [dummyNumbers, setDummyNumbers] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    const [error, setError] = useState("");
    const [startRegisterNumberError, setStartRegisterNumberError] = useState("");
    const [endRegisterNumberError, setEndRegisterNumberError] = useState("");

    const [selectedDepartment, setSelectedDepartment] = useState(""); // State for selected department
    const [subjectCodes, setSubjectCodes] = useState([]); // State to store subject codes
    const [selectedSubjectCode, setSelectedSubjectCode] = useState(""); // State for selected subject code
    const [subjectName, setSubjectName] = useState(""); // State for subject name

    // Department and subject mapping
    const subjectPrefixes = {
        MEC101: "ABMEC",
        MEC102: "ABSSC",
        MEC103: "ABMTC",
        AER201: "ABAPC",
        AER202: "ABFDC",
        AER203: "ABASC",
        ICE301: "ABPCEC",
        ICE302: "ABIEC",
        ICE303: "ABCSC",

    };
    const departmentSubjects = {
        be_marine: {
            codes: ["MEC101", "MEC102", "MEC103"],
            names: {
                MEC101: "Marine Engineering Basics",
                MEC102: "Ship Structure and Construction",
                MEC103: "Marine Thermodynamics",
            },
        },
        be_areo: {
            codes: ["AER201", "AER202", "AER203"],
            names: {
                AER201: "Aerospace Propulsion",
                AER202: "Flight Dynamics",
                AER203: "Aircraft Structures",
            },
        },
        be_ice: {
            codes: ["ICE301", "ICE302", "ICE303"],
            names: {
                ICE301: "Process Control Engineering",
                ICE302: "Instrumentation Engineering",
                ICE303: "Control Systems",
            },
        },
        // Add more departments and their corresponding subject codes and names here...
    };

    useEffect(() => {
        if (selectedDepartment) {
            // Update subject codes based on selected department
            const departmentData = departmentSubjects[selectedDepartment];
            setSubjectCodes(departmentData.codes);
            setSelectedSubjectCode(""); // Reset selected subject code
            setSubjectName(""); // Reset subject name
        }
    }, [selectedDepartment]);

    useEffect(() => {
        if (selectedSubjectCode && selectedDepartment) {
            // Auto-populate the subject name based on the selected subject code
            const departmentData = departmentSubjects[selectedDepartment];
            const name = departmentData.names[selectedSubjectCode];
            setSubjectName(name);
        }
    }, [selectedSubjectCode, selectedDepartment]);

    const handleGenerate = (event) => {
        event.preventDefault();
        setError("");
        let hasError = false;

        if (!startRegisterNumber) {
            setStartRegisterNumberError("Register Number From  is required.");
            hasError = true;
        } else {
            setStartRegisterNumberError("");
        }

        if (!endRegisterNumber) {
            setEndRegisterNumberError("Register Number To is required.");
            hasError = true;
        } else {
            setEndRegisterNumberError("");
        }

        if (hasError) {
            return;
        }

        const generatedDummyNumbers = generateDummyNumbers(startRegisterNumber, endRegisterNumber);
        setDummyNumbers(generatedDummyNumbers);
        setIsModalOpen(true);
    };

    const generateDummyNumbers = (start, end) => {
        const dummyNumbers = [];
        for (let i = Number(start); i <= Number(end); i++) {
            const registerNumber = i.toString().padStart(3, '0'); // Convert register number to 3-digit format
            const generatedDummyNumber = generateDummyNumber(registerNumber);
            dummyNumbers.push({ registerNumber, dummyNumber: generatedDummyNumber });
        }
        return dummyNumbers;
    };

    const generateDummyNumber = (registerNumber) => {
        let result = "";


        // Prepend the appropriate prefix based on the selected subject code
        const prefix = subjectPrefixes[selectedSubjectCode] || "XXXX";
        return prefix + result + registerNumber; // Append the 3-digit register number
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setIsCopied(false);
    };

    const handleCopyDummyNumbers = () => {
        const numbersToCopy = dummyNumbers.map(item => `Register Number: ${item.registerNumber}, Dummy Number: ${item.dummyNumber}`).join("\n");
        navigator.clipboard.writeText(numbersToCopy).then(() => {
            setIsCopied(true);
        });
    };

    const downloadPDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(18);
        doc.text("Dummy Number Generation", doc.internal.pageSize.getWidth() / 2, 10, { align: "center" });
        doc.setFontSize(12);

        // Set up the table headers
        doc.text("Register Number", 40, 30);
        doc.text("Dummy Number", 140, 30);

        dummyNumbers.forEach((item, index) => {
            const yPosition = 40 + index * 10;
            doc.text(item.registerNumber.toString(), 40, yPosition);
            doc.text(item.dummyNumber, 140, yPosition);
        });

        doc.save("dummy_numbers.pdf");
    };


    const downloadExcel = () => {
        const worksheetData = [
            ["Dummy Number Generation"], // Title row
            ["Register Number", "Dummy Number"], // Headers
            ...dummyNumbers.map(item => [item.registerNumber, item.dummyNumber]) // Data rows
        ];

        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Dummy Numbers");

        // Apply some formatting
        worksheet['!cols'] = [{ width: 20 }, { width: 30 }]; // Set column widths
        worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }]; // Merge title row

        XLSX.writeFile(workbook, "dummy_numbers.xlsx");
    };


    return (
        <div className="main-inner-wrapper leavemenu-wrapper">
            <div className="px-4">
                <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
                    <h1 className="report-heading" id="report-heading">Dummy Number</h1>
                    <div className="form-container">
                        <form onSubmit={handleGenerate}>
                            <div className="form-row">
                                <Select
                                    label="Year"
                                    className="max-w-full"
                                    variant="bordered"
                                    required
                                >
                                    <SelectItem key="year1" value="year1">
                                        1 Year
                                    </SelectItem>
                                    <SelectItem key="year2" value="year2">
                                        2 Year
                                    </SelectItem>
                                    <SelectItem key="year3" value="year3">
                                        3 Year
                                    </SelectItem>
                                    <SelectItem key="year4" value="year4">
                                        4 Year
                                    </SelectItem>
                                </Select>
                                <Select
                                    label="Department"
                                    className="max-w-full"
                                    variant="bordered"
                                    required
                                    value={selectedDepartment}
                                    onChange={(e) => setSelectedDepartment(e.target.value)}
                                >
                                    <SelectItem key="be_marine" value="be_marine">
                                        BE Marine Engineering
                                    </SelectItem>
                                    <SelectItem key="be_areo" value="be_areo">
                                        BE Aerospace engineering
                                    </SelectItem>
                                    <SelectItem key="be_ice" value="be_ice">
                                        BE Instrumentation and Control Engineering
                                    </SelectItem>
                                    {/* Add more department options here */}
                                </Select>
                            </div>

                            <div className="form-row">
                                <Select
                                    label="Subject Code"
                                    className="max-w-full"
                                    variant="bordered"
                                    required
                                    value={selectedSubjectCode}
                                    onChange={(e) => setSelectedSubjectCode(e.target.value)}
                                    disabled={!subjectCodes.length}
                                >
                                    {subjectCodes.map(code => (
                                        <SelectItem key={code} value={code}>
                                            {code}
                                        </SelectItem>
                                    ))}
                                </Select>
                                <Input
                                    variant="bordered"
                                    label="Subject Name"
                                    value={subjectName}
                                    readOnly
                                />
                            </div>

                            <div className="form-row">
                                <div>
                                    <Input
                                        variant="bordered"
                                        label="Register Number From"
                                        required
                                        value={startRegisterNumber}
                                        onChange={(e) => setStartRegisterNumber(e.target.value)}
                                    />
                                    {startRegisterNumberError && <p className="text-red-500">{startRegisterNumberError}</p>}
                                </div>
                                <div>
                                    <Input
                                        variant="bordered"
                                        label="Register Number To"
                                        required
                                        value={endRegisterNumber}
                                        onChange={(e) => setEndRegisterNumber(e.target.value)}
                                    />
                                    {endRegisterNumberError && <p className="text-red-500">{endRegisterNumberError}</p>}
                                </div>
                            </div>
                            {error && <p className="text-red-500">{error}</p>}

                            <div className="flex justify-center items-center gap-4">
                                <Button className="incidentSubmitBtn" type="submit">
                                    Generate
                                </Button>
                                <Button
                                    color="danger"
                                    variant="flat"
                                >
                                    Cancel
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                <ModalContent style={{ maxHeight: "80vh", maxWidth: "500px", overflowY: "auto" }}>
                    <ModalHeader>Generated Dummy Numbers</ModalHeader>
                    <ModalBody>
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Register Number
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Dummy Number
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {dummyNumbers.map((item, index) => (
                                    <tr key={index}>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <strong>{item.registerNumber}</strong>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <strong>{item.dummyNumber}</strong>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {isCopied && <p className="text-green-500 mt-2">Numbers copied!</p>} {/* Display copy confirmation message */}
                    </ModalBody>

                    <ModalFooter style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                        <Button className="incidentSubmitBtn" onClick={handleCopyDummyNumbers}>Copy All</Button>
                        <Button className="incidentSubmitBtn" onClick={downloadPDF}>Download PDF</Button>
                        <Button className="incidentSubmitBtn" onClick={downloadExcel}>Download Excel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>


        </div>
    );
};

export default DummyNumber;
