import React, { useState } from "react";
import {
    Button,
    Dropdown,
    DropdownTrigger,
    DropdownMenu,
    DropdownItem,
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import CanvasJSReact from "@canvasjs/react-charts";

const dept = ["all", "Civil", "CSE", "EEE", "Mechanical"]
const degree = ['all', 'UG', 'PG']
const yea = ['all', '1st Year', '2nd Year', '3rd Year', '4th Year']

const DashboardPlacement = () => {
    const [selectedItem, setSelectedItem] = useState('all Year');
    const navigate = useNavigate();

    const handleChennaiClick = () => {
        navigate("/greece/chennaidashboard");
    };

    const CanvasJS = CanvasJSReact.CanvasJS;
    const CanvasJSChart = CanvasJSReact.CanvasJSChart;

    const pieChartOptions = {
        exportEnabled: true,
        animationEnabled: true,
        title: {
            text: "Skill In Demand",
        },
        height: 300,
        data: [
            {
                type: "pie",
                startAngle: 75,
                toolTipContent: "<b>{label}</b>: {y}%",
                showInLegend: "true",
                legendText: "{label}",
                indexLabelFontSize: 16,
                indexLabel: "{label} - {y}%",
                dataPoints: [
                    { y: 78.85, label: "Passed" },
                    { y: 21.14, label: "Failed" },
                ],
            },
        ],
    };

    const handleSelectItemChange = (year) => {
        setSelectedItem(year);
    };

    const getStudentData = (year) => {
        switch (year) {
            case 'all Year':
                return [
                    { label: "IT", y: 95 },
                    { label: "Mech", y: 60 },
                    { label: "ECE", y: 75 },
                    { label: "CSE", y: 90 }
                ];
            case '1st Year':
                return [
                    { label: "IT", y: 95 },
                    { label: "Mech", y: 60 },
                    { label: "ECE", y: 75 },
                    { label: "CSE", y: 90 }
                ];
            case '2nd Year':
                return [
                    { label: "IT", y: 90 },
                    { label: "Mech", y: 80 },
                    { label: "ECE", y: 70 },
                    { label: "CSE", y: 75 }
                ];
            case '3rd Year':
                return [
                    { label: "IT", y: 95 },
                    { label: "Mech", y: 82 },
                    { label: "ECE", y: 78 },
                    { label: "CSE", y: 79 }
                ];
            case '4th Year':
                return [
                    { label: "IT", y: 85 },
                    { label: "Mech", y: 79 },
                    { label: "ECE", y: 72 },
                    { label: "CSE", y: 74 }
                ];
            case 'all degree':
                return [
                    { label: "IT", y: 90 },
                    { label: "Mech", y: 60 },
                    { label: "ECE", y: 70 },
                    { label: "CSE", y: 80 }
                ];

            case 'ug':
                return [
                    { label: "IT", y: 90 },
                    { label: "Mech", y: 60 },
                    { label: "ECE", y: 70 },
                    { label: "CSE", y: 80 }
                ];
            case 'pg':
                return [
                    { label: "IT", y: 85 },
                    { label: "Mech", y: 75 },
                    { label: "ECE", y: 65 },
                    { label: "CSE", y: 70 }
                ];

            case 'all':
                return [
                    { label: "1st Year", y: 85 },
                    { label: "2nd Year", y: 70 },
                    { label: "3rd Year", y: 75 },
                    { label: "4th Year", y: 90 }
                ];
            case 'Civil':
                return [
                    { label: "1st Year", y: 85 },
                    { label: "2nd Year", y: 70 },
                    { label: "3rd Year", y: 75 },
                    { label: "4th Year", y: 90 }
                ];
            case 'Mechanical':
                return [
                    { label: "1st Year", y: 80 },
                    { label: "2nd Year", y: 70 },
                    { label: "3rd Year", y: 60 },
                    { label: "4th Year", y: 75 }
                ];
            case 'EEE':
                return [
                    { label: "1st Year", y: 90 },
                    { label: "2nd Year", y: 80 },
                    { label: "3rd Year", y: 70 },
                    { label: "4th Year", y: 95 }
                ];
            case 'CSE':
                return [
                    { label: "1st Year", y: 75 },
                    { label: "2nd Year", y: 65 },
                    { label: "3rd Year", y: 55 },
                    { label: "4th Year", y: 80 }
                ];
            default:
                return [];
        }
    };

    const columnChartOptions = {
        title: {
            text: "Top RecruIting Companies"
        },
        height: 300,
        data: [{
            type: "column",
            dataPoints: getStudentData(selectedItem)
        }]
    };

    return (
        <>
            <div className="flex flex-col h-screen overflow-hidden">
                <div className="flex flex-col gap-8 px-4 mt-2" style={{ overflow: 'hidden' }}>
                    {/* Dropdowns Row */}
                 

                    {/* Cards Row */}
                    <div className="flex flex-wrap gap-8">
                        <div className="ticket-card flex p-4 rounded-lg justify-between items-center bg-blue-500" style={{ flex: "1 1 22%", maxWidth: "calc(25% - 1rem)" }}>
                            <div className="ticket-card-content">
                                <h5 className="m-0 text-2xl text-white font-bold">Companies Visited</h5>
                                <h5 className="m-0 text-white font-bold" style={{ fontSize: "20px" }}>233</h5>
                            </div>
                        </div>
                        <div className="ticket-card flex p-4 rounded-lg justify-between items-center bg-green-500" style={{ flex: "1 1 22%", maxWidth: "calc(25% - 1rem)" }}>
                            <div className="ticket-card-content">
                                <h5 className="m-0 text-2xl text-white font-bold">Total Student Placed</h5>
                                <h5 className="m-0 text-white font-bold" style={{ fontSize: "20px" }}>547</h5>
                            </div>
                        </div>
                        <div className="ticket-card flex p-4 rounded-lg justify-between items-center bg-red-500" style={{ flex: "1 1 22%", maxWidth: "calc(25% - 1rem)" }}>
                            <div className="ticket-card-content">
                                <h5 className="m-0 text-2xl text-white font-bold">Participation Rate</h5>
                                <h5 className="m-0 text-white font-bold" style={{ fontSize: "20px" }}>87%</h5>
                            </div>
                        </div>
                        <div className="ticket-card flex p-4 rounded-lg justify-between items-center bg-yellow-500" style={{ flex: "1 1 22%", maxWidth: "calc(25% - 1rem)" }}>
                            <div className="ticket-card-content">
                                <h5 className="m-0 text-2xl text-white font-bold">Placement Rate</h5>
                                <h5 className="m-0 text-white font-bold" style={{ fontSize: "20px" }}>63%</h5>
                            </div>
                        </div>
                    </div>
                         {/* Dropdowns Row */}
                         <div className="flex flex-wrap gap-8">
                        <div className="flex items-center gap-2 mt-4">
                            <span className='text-xl font-bold'>Year:</span>
                            <Dropdown>
                                <DropdownTrigger>
                                    <Button>{yea.includes(selectedItem) ? selectedItem : "Year"}</Button>
                                </DropdownTrigger>
                                <DropdownMenu aria-label="Year Selection">
                                    <DropdownItem key="all" onClick={() => handleSelectItemChange('all')}>All</DropdownItem>
                                    <DropdownItem key="1stYear" onClick={() => handleSelectItemChange('1st Year')}>1st Year</DropdownItem>
                                    <DropdownItem key="2ndYear" onClick={() => handleSelectItemChange('2nd Year')}>2nd Year</DropdownItem>
                                    <DropdownItem key="3rdYear" onClick={() => handleSelectItemChange('3rd Year')}>3rd Year</DropdownItem>
                                    <DropdownItem key="4thYear" onClick={() => handleSelectItemChange('4th Year')}>4th Year</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <div className="flex items-center gap-2 mt-4">
                            <span className='text-xl font-bold'>Major:</span>
                            <Dropdown>
                                <DropdownTrigger>
                                    <Button>{dept.includes(selectedItem) ? selectedItem : "Major"}</Button>
                                </DropdownTrigger>
                                <DropdownMenu aria-label="Major Selection">
                                    <DropdownItem key="all" onClick={() => handleSelectItemChange('all')}>All</DropdownItem>
                                    <DropdownItem key="Civil" onClick={() => handleSelectItemChange('Civil')}>Civil</DropdownItem>
                                    <DropdownItem key="Mechanical" onClick={() => handleSelectItemChange('Mechanical')}>Mechanical</DropdownItem>
                                    <DropdownItem key="CSE" onClick={() => handleSelectItemChange('CSE')}>CSE</DropdownItem>
                                    <DropdownItem key="EEE" onClick={() => handleSelectItemChange('EEE')}>EEE</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <div className="flex items-center gap-2 mt-4">
                            <span className='text-xl font-bold'>Degree:</span>
                            <Dropdown>
                                <DropdownTrigger>
                                    <Button>{degree.includes(selectedItem) ? selectedItem : "Degree"}</Button>
                                </DropdownTrigger>
                                <DropdownMenu aria-label="Degree Selection">
                                    <DropdownItem key="all" onClick={() => handleSelectItemChange('all')}>All</DropdownItem>
                                    <DropdownItem key="ug" onClick={() => handleSelectItemChange('ug')}>UG</DropdownItem>
                                    <DropdownItem key="pg" onClick={() => handleSelectItemChange('pg')}>PG</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                </div>

                <div className="grid md:grid-cols-2 gap-4 px-2 mt-4">
                    <div className="col-span-1 p-4">
                        <CanvasJSChart options={columnChartOptions} />
                    </div>
                    <div className="col-span-1 p-4">
                        <CanvasJSChart options={pieChartOptions} />
                    </div>
                </div>
            </div>

        </>
    );
};

export default DashboardPlacement;
