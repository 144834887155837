import React, { useState } from "react";
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    Select,
    SelectItem,
    DatePicker
} from "@nextui-org/react";
import "./ModelForm.css";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function PlacementForm({ isOpen, onOpenChange, backdrop = "blur" }) {
    const [selectedMajor, setSelectedMajor] = useState(""); // State to track selected major

    const handleMajorChange = (value) => {
        setSelectedMajor(value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        toast.success("Placement added successfully!");
    };

    // Specialization options based on selected major
    const specializationOptions = {
        Arts: [
            { key: "History", value: "History" },
            { key: "Literature", value: "Literature" },
            { key: "English", value: "English" },
            { key: "Tamil", value: "Tamil" }
        ],
        Engineering: [
            { key: "AI", value: "AIDS" },
            { key: "ML", value: "Machine Learning" },
            { key: "DS", value: "Data Science" },
            { key: "CSE", value: "CSE" },
            { key: "ECE", value: "ECE" },

        ]
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                backdrop={backdrop}
                placement="center"
                style={{ maxWidth: "700px", width: "100%" }}
            >
                <ModalContent>
                    {({ onClose }) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">
                                Placement
                            </ModalHeader>
                            <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-row">
                                        <Select
                                            label="Degree Program"
                                            className="custom-select"
                                            labelPlacement="outside"
                                            variant="bordered"
                                            fullWidth
                                        >
                                            <SelectItem key="UG" value="UG">
                                                UG
                                            </SelectItem>
                                            <SelectItem key="PG" value="PG">
                                                PG
                                            </SelectItem>
                                        </Select>

                                        <Select
                                            label="Major"
                                            className="custom-select"
                                            labelPlacement="outside"
                                            variant="bordered"
                                            fullWidth
                                            onChange={(e) => handleMajorChange(e.target.value)}
                                        >
                                            <SelectItem key="Arts" value="Arts">
                                                Arts
                                            </SelectItem>
                                            <SelectItem key="Engineering" value="Engineering">
                                                Engineering
                                            </SelectItem>
                                        </Select>

                                        <Select
                                            label="Specialization"
                                            className="custom-select"
                                            labelPlacement="outside"
                                            variant="bordered"
                                            fullWidth
                                        >
                                            {selectedMajor &&
                                                specializationOptions[selectedMajor].map((item) => (
                                                    <SelectItem key={item.key} value={item.value}>
                                                        {item.value}
                                                    </SelectItem>
                                                ))}
                                        </Select>
                                    </div>

                                    <div className="form-row">
                                        <Select
                                            label="Year"
                                            className="custom-select"
                                            labelPlacement="outside"
                                            variant="bordered"
                                            fullWidth
                                        >
                                            <SelectItem key="1" value="1">
                                                1
                                            </SelectItem>
                                            <SelectItem key="2" value="2">
                                                2
                                            </SelectItem>
                                            <SelectItem key="3" value="3">
                                                3
                                            </SelectItem>
                                            <SelectItem key="4" value="4">
                                                4
                                            </SelectItem>
                                        </Select>

                                        <DatePicker
                                            variant="bordered"
                                            label="Date"
                                            labelPlacement="outside"
                                        />
                                        <Input
                                            variant="bordered"
                                            label="Time"
                                            labelPlacement="outside"
                                            type="time"
                                        />
                                    </div>

                                    <div className="form-row">
                                        <Input
                                            variant="bordered"
                                            label="Company"
                                            labelPlacement="outside"
                                        />
                                        <Input
                                            variant="bordered"
                                            label="Category"
                                            labelPlacement="outside"
                                        />
                                        <Input
                                            variant="bordered"
                                            label="Website"
                                            labelPlacement="outside"
                                        />
                                    </div>
                                    <div className="form-row">
                                        <Input
                                            variant="bordered"
                                            label="Place"
                                            labelPlacement="outside"
                                        />
                                    </div>
                                    <ModalFooter className="flex justify-center items-center gap-4">
                                        <Button color="danger" variant="flat" onClick={onClose}>
                                            Close
                                        </Button>
                                        <Button
                                            className="incidentSubmitBtn"
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </ModalFooter>
                                </form>
                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal>
            <ToastContainer />
        </>
    );
}
