import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Input,
    Select,
    SelectItem,
    Table,
    TableHeader,
    TableBody,
    TableCell,
    TableColumn,
    TableRow,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "@nextui-org/react";
import { useMark } from "../data/Context";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MarkSetup = () => {
    const { markSetup, updateMarkSetup } = useMark()
    const [sections, setSections] = useState([]);
    const [sectionName, setSectionName] = useState("");
    const [numOfQuestions, setNumOfQuestions] = useState("");
    const [mark, setMark] = useState("");
    const [totalMark, setTotalMark] = useState("");
    const [totalTheoryMark, setTotalTheoryMark] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedYear, setSelectedYear] = useState(0);
    const [selectedDept, setSelectedDept] = useState("")
    const [selectedSubject, setSelectedSubject] = useState("")
    const [subjectCode, setSubjectCode] = useState("")
    const [theoryMark, setTheoryMark] = useState("");
    // const [internalMark, setInternalMark] = useState("")


    const check = val => {
        if (val) {
            if (selectedYear && selectedDept && selectedSubject && theoryMark  && sections.length > 0) {
                return true
            } else {
                return false
            }
        } else {
            if (selectedYear && selectedDept && selectedSubject && theoryMark ) {
                return true
            } else {
                return false
            }
        }
    }

    const updateToContext = _ => {
        const data = {
            year: selectedYear,
            dept: selectedDept,
            subject: selectedSubject,
            subCode: subjectCode,
            theory: theoryMark,
            // internal: internalMark,
            questionModal: sections
        }
        // console.log("data : ", data);
        // debugger
        updateMarkSetup([...markSetup, data])
        setSelectedYear(0);
        setSelectedDept("");
        setSelectedSubject("");
        setSubjectCode("");
        setTheoryMark("");
        // setInternalMark("");
        setSections([]);
        setTotalTheoryMark(0);

        toast.success("External setup submitted successfully!");
    }

    const handleAddSection = () => {
        if (check()) {
            setIsModalVisible(true);
        }
    };

    const handleCloseModal = () => {
        setIsModalVisible(false); // Hide modal
    };

    const calculateTotalMark = _ => {
        let num = 0;
        sections.map(val => num += val.totalMark)
        setTotalTheoryMark(num);
    }

    useEffect(_ => {
        calculateTotalMark();
    }, [sections])

    const handleSubmit = () => {
        const newSection = {
            sectionName,
            numOfQuestions: Number(numOfQuestions),
            mark: Number(mark),
            totalMark: Number(numOfQuestions) * Number(mark)
        };
        setSections([...sections, newSection]);
        setSectionName("");
        setNumOfQuestions("");
        setMark("");
        setTotalMark("");
        handleCloseModal(); // Hide modal after submit
    };

    const handleSubject = (nam,code) => {
        setSelectedSubject(nam); 
        setSubjectCode(code)
    }

    const handleDept = nam => {
        setSelectedDept(nam); 
        setSubjectCode("");
    }

    const departments = [
        {
            name: "Civil",
            years: [
                {
                    year: 1,
                    subjects: [
                        { code: "CIV101", name: "Structural Analysis" },
                        { code: "CIV102", name: "Geotechnical Engineering" },
                        { code: "CIV103", name: "Hydraulics" },
                        { code: "CIV104", name: "Transportation Engineering" },
                        { code: "CIV105", name: "Environmental Engineering" }
                    ]
                },
                {
                    year: 2,
                    subjects: [
                        { code: "CIV106", name: "Surveying" },
                        { code: "CIV107", name: "Concrete Technology" },
                        { code: "CIV108", name: "Structural Dynamics" },
                        { code: "CIV109", name: "Construction Management" },
                        { code: "CIV110", name: "Water Resources Engineering" }
                    ]
                },
                {
                    year: 3,
                    subjects: [
                        { code: "CIV111", name: "Soil Mechanics" },
                        { code: "CIV112", name: "Foundation Engineering" },
                        { code: "CIV113", name: "Hydrology" },
                        { code: "CIV114", name: "Environmental Impact Assessment" },
                        { code: "CIV115", name: "Transportation Planning" }
                    ]
                },
                {
                    year: 4,
                    subjects: [
                        { code: "CIV116", name: "Advanced Structural Analysis" },
                        { code: "CIV117", name: "Seismic Design" },
                        { code: "CIV118", name: "Urban Planning" },
                        { code: "CIV119", name: "Advanced Geotechnical Engineering" },
                        { code: "CIV120", name: "Structural Design of Steel Structures" }
                    ]
                }
            ]
        },
        {
            name: "Mechanical",
            years: [
                {
                    year: 1,
                    subjects: [
                        { code: "MECH101", name: "Engineering Mechanics" },
                        { code: "MECH102", name: "Thermodynamics" },
                        { code: "MECH103", name: "Fluid Mechanics" },
                        { code: "MECH104", name: "Engineering Drawing" },
                        { code: "MECH105", name: "Manufacturing Processes" }
                    ]
                },
                {
                    year: 2,
                    subjects: [
                        { code: "MECH201", name: "Heat Transfer" },
                        { code: "MECH202", name: "Machine Design" },
                        { code: "MECH203", name: "Materials Science" },
                        { code: "MECH204", name: "Dynamics of Machinery" },
                        { code: "MECH205", name: "Manufacturing Technology" }
                    ]
                },
                {
                    year: 3,
                    subjects: [
                        { code: "MECH301", name: "Refrigeration and Air Conditioning" },
                        { code: "MECH302", name: "Automobile Engineering" },
                        { code: "MECH303", name: "Control Systems" },
                        { code: "MECH304", name: "Robotics" },
                        { code: "MECH305", name: "Power Plant Engineering" }
                    ]
                },
                {
                    year: 4,
                    subjects: [
                        { code: "MECH401", name: "Finite Element Analysis" },
                        { code: "MECH402", name: "Advanced Machine Design" },
                        { code: "MECH403", name: "Industrial Engineering" },
                        { code: "MECH404", name: "Engineering Management" },
                        { code: "MECH405", name: "Thermal Engineering" }
                    ]
                }
            ]
        },
        {
            name: "CSE",
            years: [
                {
                    year: 1,
                    subjects: [
                        { code: "CSE101", name: "Introduction to Programming" },
                        { code: "CSE102", name: "Computer Organization" },
                        { code: "CSE103", name: "Discrete Mathematics" },
                        { code: "CSE104", name: "Database Management Systems" },
                        { code: "CSE105", name: "Operating Systems" }
                    ]
                },
                {
                    year: 2,
                    subjects: [
                        { code: "CSE201", name: "Data Structures" },
                        { code: "CSE202", name: "Algorithms" },
                        { code: "CSE203", name: "Software Engineering" },
                        { code: "CSE204", name: "Computer Networks" },
                        { code: "CSE205", name: "Theory of Computation" }
                    ]
                },
                {
                    year: 3,
                    subjects: [
                        { code: "CSE301", name: "Operating Systems Design" },
                        { code: "CSE302", name: "Database Design" },
                        { code: "CSE303", name: "Artificial Intelligence" },
                        { code: "CSE304", name: "Web Technologies" },
                        { code: "CSE305", name: "Cybersecurity" }
                    ]
                },
                {
                    year: 4,
                    subjects: [
                        { code: "CSE401", name: "Machine Learning" },
                        { code: "CSE402", name: "Big Data Analytics" },
                        { code: "CSE403", name: "Cloud Computing" },
                        { code: "CSE404", name: "Human-Computer Interaction" },
                        { code: "CSE405", name: "Project Work" }
                    ]
                }
            ]
        },
        {
            name: "EEE",
            years: [
                {
                    year: 1,
                    subjects: [
                        { code: "EEE101", name: "Basic Electrical Engineering" },
                        { code: "EEE102", name: "Circuit Theory" },
                        { code: "EEE103", name: "Electronics" },
                        { code: "EEE104", name: "Electromagnetic Fields" },
                        { code: "EEE105", name: "Electrical Machines" }
                    ]
                },
                {
                    year: 2,
                    subjects: [
                        { code: "EEE201", name: "Power Systems" },
                        { code: "EEE202", name: "Control Systems" },
                        { code: "EEE203", name: "Digital Electronics" },
                        { code: "EEE204", name: "Signals and Systems" },
                        { code: "EEE205", name: "Microprocessors" }
                    ]
                },
                {
                    year: 3,
                    subjects: [
                        { code: "EEE301", name: "Power Electronics" },
                        { code: "EEE302", name: "Renewable Energy Systems" },
                        { code: "EEE303", name: "Electrical Power Distribution" },
                        { code: "EEE304", name: "Instrumentation" },
                        { code: "EEE305", name: "Electrical Drives" }
                    ]
                },
                {
                    year: 4,
                    subjects: [
                        { code: "EEE401", name: "Advanced Power Systems" },
                        { code: "EEE402", name: "High Voltage Engineering" },
                        { code: "EEE403", name: "Embedded Systems" },
                        { code: "EEE404", name: "Smart Grids" },
                        { code: "EEE405", name: "Project Work" }
                    ]
                }
            ]
        }
    ];



    const navigate = useNavigate();

    return (
        <div className="main-inner-wrapper leavemenu-wrapper">
            <div className="px-4">
                <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
                    <h1 className="report-heading" id="report-heading">Mark Setup</h1>
                    <div className="form-container">
                        <div>
                            <div className="flex gap-4 mb-6 items-center">
                                <Select
                                    labelPlacement="outside"
                                    label="Year"
                                >
                                    <SelectItem onClick={_ => { setSelectedYear(1); setSubjectCode("") }}>
                                        1st Year
                                    </SelectItem>
                                    <SelectItem onClick={_ => { setSelectedYear(2); setSubjectCode("") }}>
                                        2nd Year
                                    </SelectItem>
                                    <SelectItem onClick={_ => { setSelectedYear(3); setSubjectCode("") }}>
                                        3rd Year
                                    </SelectItem>
                                    <SelectItem onClick={_ => { setSelectedYear(4); setSubjectCode("") }}>
                                        4th Year
                                    </SelectItem>
                                </Select>
                                <Select
                                    labelPlacement="outside"
                                    label="Department"
                                // onChange={handleSelectChange}
                                >
                                    {
                                        departments.map((val, index) =>
                                            <SelectItem key={index} onClick={_ => handleDept(val.name)} value={val.name}>
                                                {val.name}
                                            </SelectItem>
                                        )
                                    }
                                </Select>
                                <Select
                                    labelPlacement="outside"
                                    label="Subject"
                                >
                                    {
                                        selectedDept && selectedYear &&
                                        departments
                                            .find(dept => dept.name === selectedDept) // Find the department object
                                            ?.years.find(year => year.year === selectedYear) // Find the year object
                                            ?.subjects.map((sub) => (
                                                <SelectItem key={sub.code} onClick={() => handleSubject(sub.name,sub.code)} value={sub.name}>
                                                    {sub.name}
                                                </SelectItem>
                                            ))
                                    }
                                </Select>
                                <Input
                                    labelPlacement="outside"
                                    label="Subject Code"
                                    readOnly
                                    value={selectedSubject ? subjectCode : ""}
                                />
                            </div>
                            <div className="flex justify-center gap-4 mb-4 items-center">
                                <Input
                                    labelPlacement="outside"
                                    label="Theory Mark"
                                    value={theoryMark}
                                    onChange={e => {
                                        const value = e.target.value;

                                        // Ensure the value is a number and less than or equal to 20
                                        if (!isNaN(value) && value <= 100) {
                                            setTheoryMark(Number(value));
                                        }
                                    }}
                                    className="max-w-[150px]"
                                />
                                {/* <Input
                                    labelPlacement="outside"
                                    label="Internal Mark"
                                    // value={internalMark}
                                    onChange={e => {
                                        const value = e.target.value;

                                        // Ensure the value is a number and less than or equal to 20
                                        if (!isNaN(value) && value <= (100 - theoryMark)) {
                                            setInternalMark(Number(value));
                                        }
                                    }}
                                    className="max-w-[150px]"
                                /> */}
                                <Button
                                    className="mt-[24px] incidentSubmitBtn"
                                    onClick={handleAddSection}
                                >
                                    Add Section
                                </Button>
                            </div>
                        </div>

                        <Table aria-label="Sections Table">
                            <TableHeader>
                                <TableColumn>Sections</TableColumn>
                                <TableColumn>No of Questions</TableColumn>
                                <TableColumn>Mark</TableColumn>
                                <TableColumn>Total Mark</TableColumn>
                            </TableHeader>
                            <TableBody>
                                {sections.map((section, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{section.sectionName}</TableCell>
                                        <TableCell>{section.numOfQuestions}</TableCell>
                                        <TableCell>{section.mark}</TableCell>
                                        <TableCell>{section.totalMark}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                        <div className="flex items-center justify-end">
                            <h1 className="m-4">Total Theory Mark - {totalTheoryMark}</h1>
                            <Button auto className="incidentSubmitBtn" onClick={updateToContext}>
                                Submit
                            </Button>
                        </div>

                        {/* Modal/Form */}
                        {isModalVisible && (
                            <Modal
                                isOpen={isModalVisible}
                                onOpenChange={handleCloseModal}
                                backdrop="opaque"
                                placement="center"
                                className="custom-modal" // Apply the class directly to the Modal
                                style={{ maxWidth: "500px", width: "100%" }}
                            >
                                <ModalContent>
                                    <ModalHeader>
                                        <h3 id="modal-title">Add Section</h3>
                                    </ModalHeader>
                                    <ModalBody>
                                        <div className="flex mb-6 gap-6">
                                            <Input
                                                labelPlacement="outside"
                                                label="Section Name"
                                                value={sectionName}
                                                onChange={(e) => setSectionName(e.target.value)}
                                            />
                                            <Input
                                                labelPlacement="outside"
                                                label="No of Questions"
                                                type="number"
                                                value={numOfQuestions}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setNumOfQuestions(value);
                                                    setTotalMark(value * mark);
                                                }}
                                            />
                                        </div>
                                        <div className="flex mb-6 gap-6">
                                            <Input
                                                labelPlacement="outside"
                                                label="Mark"
                                                type="number"
                                                value={mark}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setMark(value);
                                                    setTotalMark(numOfQuestions * value);
                                                }}
                                            />
                                            <Input
                                                labelPlacement="outside"
                                                label="Total Mark"
                                                readOnly
                                                value={totalMark}
                                            />
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button auto className="bg-[#FF4D4F99]" onClick={handleCloseModal}>
                                            Close
                                        </Button>
                                        <Button auto className="incidentSubmitBtn" onClick={handleSubmit}>
                                            Submit
                                        </Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                        )}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default MarkSetup;
