import React, { useState, useEffect } from 'react';
import { Input } from "@nextui-org/react";
import { MailIcon } from '../../components/baseComponent/MailIcon';
import { EyeFilledIcon } from "../../components/baseComponent/EyeFilledIcon";
import { EyeSlashFilledIcon } from "../../components/baseComponent/EyeSlashFilledIcon";
import { LockIcon } from '../../components/baseComponent/LockIcon';
import { Checkbox } from "@nextui-org/react";
import { Link, useNavigate } from 'react-router-dom';

const LoginData = [
  // {
  //   regId: "student_saranathan",
  //   dob: "01-01-2024",
  //   role: "student",
  //   college:"SCE"
  // },
  // {
  //   staffId: "coe_saranathan",
  //   dob: "01-01-2024",
  //   role: "coe",
  //   college:"SCE"
  // },
  // {
  //   staffId: "staff_saranathan",
  //   dob: "01-01-2024",
  //   role: "staff",
  //   college:"SCE"
  // },
  // {
  //   staffId: "admin_saranathan",
  //   dob: "01-01-2024",
  //   role: "admin",
  //   college:"SCE"
  // },
  {
    regId: "student@gmail.com",
    dob: "01-01-2024",
    role: "student",
    college:"Vels"
  },
  {
    staffId: "coe@gmail.com",
    dob: "01-01-2024",
    role: "coe",
    college:"Vels"
  },
  {
    staffId: "staff@gmail.com",
    dob: "01-01-2024",
    role: "staff",
    college:"Vels"
  },
  {
    staffId: "admin@gmail.com",
    dob: "01-01-2024",
    role: "admin",
    college:"Vels"
  },
  {
    staffId: "placement@gmail.com",
    dob: "01-01-2024",
    role: "placement",
    college:"Vels"
  },
  // {
  //   regId: "student_care",
  //   dob: "01-01-2024",
  //   role: "student",
  //   college:"CGI"
  // },
  // {
  //   staffId: "coe_care",
  //   dob: "01-01-2024",
  //   role: "coe",
  //   college:"CGI"
  // },
  // {
  //   staffId: "staff_care",
  //   dob: "01-01-2024",
  //   role: "staff",
  //   college:"CGI"
  // },
  // {
  //   staffId: "admin_care",
  //   dob: "01-01-2024",
  //   role: "admin",
  //   college:"CGI"
  // },
  // {
  //   regId: "student_mam",
  //   dob: "01-01-2024",
  //   role: "student",
  //   college:"MAM"
  // },
  // {
  //   staffId: "coe_mam",
  //   dob: "01-01-2024",
  //   role: "coe",
  //   college:"MAM"
  // },
  // {
  //   staffId: "staff_mam",
  //   dob: "01-01-2024",
  //   role: "staff",
  //   college:"MAM"
  // },
  // {
  //   staffId: "admin_mam",
  //   dob: "01-01-2024",
  //   role: "admin",
  //   college:"MAM"
  // },
  // {
  //   regId: "student_miet",
  //   dob: "01-01-2024",
  //   role: "student",
  //   college:"MIET"
  // },
  // {
  //   staffId: "coe_miet",
  //   dob: "01-01-2024",
  //   role: "coe",
  //   college:"MIET"
  // },
  // {
  //   staffId: "staff_miet",
  //   dob: "01-01-2024",
  //   role: "staff",
  //   college:"MIET"
  // },
  // {
  //   staffId: "admin_miet",
  //   dob: "01-01-2024",
  //   role: "admin",
  //   college:"MIET"
  // },
];

const Login = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [regId, setRegId] = useState('');
  const [identity , setIdentity] = useState(localStorage.getItem("identity"))
  const [staffId , setStaffId] = useState("")
  const [dob, setDob] = useState('');
  const [error, setError] = useState('');

  const toggleVisibility = () => setIsVisible(!isVisible);

  const selectLogin = id => {
    if(id == "student"){
      return "Student Login"
    }
    else if(id == "staff"){
      return "Staff Login"
    }
    else if(id == "coe"){
      return "COE Login"
    }
    else if(id == "admin"){
      return "Admin Login"
    }
    else if(id == "placement"){
      return "Placement Login"
    }
  }

  const selectPlaceholder = id => {
    if(id == "student"){
      return "Enter Your Register Id"
    }
    else if(id == "staff" || id == "coe" || id == "admin" ||id == "placement"){
      return "Enter Your Staff Id"
    }
  }

  const selectUserId = id => {
    if(id == "student"){
      return "Register Id"
    }
    else if(id == "staff" || id == "coe" || id == "admin" ||id == "placement"){
      return "Staff Id"
    }
  }

  const selectImage = id => {
    if(id == "student"){
      return "/images/student2.jpg"
    }
    else if(id == "staff"){
      return "/images/staff.jpg"
    }
    else if(id == "coe"){
      return "/images/coe.jpg"
    }
    else if(id == "admin"){
      return "/images/admin.jpg"
    }
    else if(id == "placement"){
      return "/images/placement.jpg"
    }
  }

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const user = LoginData.find(
        (user) => user.regId === regId && user.dob === dob
      ) || LoginData.find(
        (user) => user.staffId === staffId && user.dob === dob
      )

      if (user) {
        localStorage.setItem('loggedInUser', user.role);
        localStorage.setItem('UserCollege', user.college);
       
        if(user.role === "student"){
          navigate('/student/examtimetable')
        }
        else if(user.role === "staff"){
          navigate('/staff/dashboard1')
        }
        else if(user.role === "coe"){
          navigate('/coe/dashboard1')
        }
        else if(user.role === "admin"){
          navigate('/admin/admindashboard')
        }
        else if(user.role === "placement"){
          navigate('/placement/dashboardplacement')
        }
      } else {
        setError('Invalid username or password');
      }
    } catch (err) {
      console.error('Error:', err);
      setError('An error occurred. Please try again later.');
    }
  };


  return (
    <section className='auth-section'>
      <div className="auth-section__wrapper lg:flex">
        {/* <div className="left-side__wrapper min-h-dvh py-4 px-6 hidden lg:flex flex-col items-center justify-center lg:w-2/4 bg-[#6B45BC]"> */}
        {/* <div className="left-side__wrapper min-h-dvh py-4 px-6 hidden lg:flex flex-col items-center justify-center lg:w-2/4 bg-green">*/}
        <div id='loginBackground' className="left-side__wrapper min-h-dvh py-4 px-6 hidden lg:flex flex-col items-center justify-center lg:w-2/4" style={{ background : `url(${selectImage(identity)})`, backgroundRepeat : "no-repeat" , backgroundSize : "cover" ,backgroundPosition : "center"}}>


          <div className="content-wrapper flex flex-col gap-6">
            <h1 className='m-0 text-black font-bold text-4xl text-center' >EdGrader</h1>
            {/* style={{color : "#67EDD9"}} */}
            {/* <p className='m-0 text-white font-bold text-4xl text-center uppercase'>Accounting Software</p> */}
            {/* <p className='m-0 text-white font-bold text-4xl text-center uppercase'>For</p> */}
            {/* <p className='m-0 text-white font-bold text-4xl text-center uppercase'>Dynacom</p> */}
          </div>
        </div>
        <div className="right-side__wrapper min-h-dvh py-4 px-6 flex flex-col justify-center lg:w-2/4" >
          <div className="form-content__wrapper md:px-12 max-w-[600px] w-full mx-auto">
            <div className="auth-form__title" > 
              <h3 className='m-0 mb-4 text-3xl font-bold text-black dark:text-white' >{selectLogin(identity)}</h3>
              {/* <p className='m-0 text-sm text-black dark:text-white'>Welcome To DYNACOM!</p> */}
            </div>
            <form onSubmit={handleLogin} method="post">
              <div className="flex flex-col gap-6 mt-6">
                <Input
                  autoFocus
                  startContent={
                    <MailIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                  }
                  label={selectUserId(identity)}
                  placeholder={selectPlaceholder(identity)}
                  variant="bordered"
                  autoComplete='off'
                  labelPlacement='outside'
                  value={regId}
                  onChange={(e) => {setRegId(e.target.value);setStaffId(e.target.value);}}
                />
                <Input
                  startContent={
                    <LockIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0"/>
                  }
                  endContent={
                    <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                      {isVisible ? (
                        <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                      ) : (
                        <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                      )}
                    </button>
                  }
                  label={`Password - DOB(DD-MM-YYYY)`}
                  placeholder="DD-MM-YYYY"
                  autoComplete='off'
                  type={isVisible ? "text" : "password"}
                  variant="bordered"
                  labelPlacement='outside'
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                />
                {error && <p className="text-red-500 text-sm">{error}</p>}
                <div className="flex justify-between items-center w-full">
                  <Checkbox defaultSelected className='py-0' radius="sm" size="md" color="primary">Remember me</Checkbox>
                  <Link to={"/forget-password"} className='text-[#36A2EB] text-md'>
                    Forgot Password?
                  </Link>
                </div>
                <button type="submit" className="py-3 px-3 bg-[#36A2EB]  text-black text-xs font-semibold rounded-md shadow focus:outline-none incidentSubmitBtn">
                  Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
