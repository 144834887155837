import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Input,
    DatePicker,
    Textarea,
    Select,
    SelectItem,
} from "@nextui-org/react";
import "../components/baseComponent/ModelForm.css";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const StudentAddForm = () => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState("");
    const [rollNumber, setRollNumber] = useState("");
    const [courseError, setCourseError] = useState(false);
    const [photoFile, setPhotoFile] = useState(null);  // State for photograph file
    const [studentId, setStudentId] = useState("");    // State for college-specific student ID

    const fileInputRef = useRef(null);
    const photoInputRef = useRef(null);

    const handleDownloadClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handlePhotoChange = (event) => {
        setPhotoFile(event.target.files[0]);
    };

    const handleCourseChange = (value) => {
        if (value) {
            setSelectedCourse(value);
            setCourseError(false);
            generateRollNumber(value);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent form from refreshing the page
        // Add your form submission logic here
        // console.log("Form submitted!");

        // Show success toast
        toast.success("Student Created Successfully!");
    };
    const generateRollNumber = (course) => {
        const courseIdMap = {
            btech_cse: "CSE",
            btech_aids: "AIDS",
            btech_ece: "ECE",
            btech_mechanical: "MECH",
            btech_civil: "CIVIL",
            btech_chemical: "CHEM",
            btech_it: "IT",
        };

        const courseId = courseIdMap[course];
        if (courseId) {
            const uniqueNumber = Math.floor(Math.random() * 10000);
            setRollNumber(`${courseId}-${uniqueNumber}`);
        } else {
            setRollNumber("");
        }
    };


    return (
        <div className="main-inner-wrapper leavemenu-wrapper">
            <div className="px-4">
                <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
                    <div className="flex justify-between items-center gap-4">
                        <h1
                            className="report-heading"
                            style={{
                                marginLeft: "0px",
                                marginBottom: "10px",
                                fontSize: "25px",
                                fontWeight: "bold",
                            }}
                        >
                            Student Creation
                        </h1>

                        <button className="py-2 mb-4 px-3 bg-indigo-500 text-xs font-semibold rounded-md shadow focus:outline-none incidentSubmitBtn" onClick={handleDownloadClick}>
                            Import from excel
                        </button>
                        <input type='file' ref={fileInputRef} hidden onChange={handleFileChange} />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <div className="form-row">
                                <Input
                                    variant="bordered"
                                    label="Student Name"
                                />
                                <DatePicker
                                    variant="bordered"
                                    label="Date of Birth"
                                />
                            </div>
                            <div className="form-row">
                                <Select
                                    label="Gender"
                                    className="max-w-full"
                                    variant="bordered"
                                    multiple
                                >
                                    <SelectItem key="male" value="male">
                                        Male
                                    </SelectItem>
                                    <SelectItem key="female" value="female">
                                        Female
                                    </SelectItem>
                                </Select>
                                <Input
                                    variant="bordered"
                                    label="Contact Number"
                                    type="number"
                                />
                            </div>
                            <div className="form-row">
                                <Input
                                    variant="bordered"
                                    label="Previous School"
                                />
                                <Input
                                    variant="bordered"
                                    label="Previous Academic Records"
                                    type="file"
                                    style={{ opacity: "0" }}
                                />
                            </div>
                            <div className="form-row">
                                <Select
                                    label="Course Applied For"
                                    className={`max-w-full ${courseError ? 'border-red-500' : ''}`}
                                    variant="bordered"
                                    onChange={(e) => handleCourseChange(e.target.value)}
                                >
                                    <SelectItem key="btech_cse" value="btech_cse">
                                        B.Tech. Computer Science Engineering
                                    </SelectItem>
                                    <SelectItem key="btech_aids" value="btech_aids">
                                        B.Tech Artificial Intelligence and Data Science
                                    </SelectItem>
                                    <SelectItem key="btech_ece" value="btech_ece">
                                        B.Tech. Electronics and Communication Engineering
                                    </SelectItem>
                                    <SelectItem key="btech_mechanical" value="btech_mechanical">
                                        B.Tech. Mechanical Engineering
                                    </SelectItem>
                                    <SelectItem key="btech_civil" value="btech_civil">
                                        B.Tech. Civil Engineering
                                    </SelectItem>
                                    <SelectItem key="btech_chemical" value="btech_chemical">
                                        B.Tech. Chemical Engineering
                                    </SelectItem>
                                    <SelectItem key="btech_it" value="btech_it">
                                        B.Tech. Information Technology
                                    </SelectItem>
                                </Select>
                                <Input
                                    variant="bordered"
                                    label="Roll Number"
                                    value={rollNumber}
                                    readOnly
                                />
                            </div>
                            {courseError && (
                                <div style={{ color: 'red', marginBottom: '10px', marginLeft: '45px' }}>
                                    Please select a course before generating a roll number.
                                </div>
                            )}

                            <div className="form-row">
                                <Input
                                    variant="bordered"
                                    label="Email"
                                    type="email"
                                />
                                <input
                                    type="file"
                                    ref={photoInputRef}
                                    hidden
                                    onChange={handlePhotoChange}
                                />
                                <Input
                                    variant="bordered"
                                    label="Photograph"
                                    value={photoFile ? photoFile.name : ""}
                                    onClick={() => photoInputRef.current.click()}
                                    readOnly
                                />
                                {/* <Input
                                    variant="bordered"
                                    type="date"
                                    label="Admission Date"
                                    value={studentId}
                                    onChange={(e) => setStudentId(e.target.value)}
                                /> */}
                            </div>
                            <div className="form-row">
                                <Input
                                    variant="bordered"
                                    label="Guardian Name"
                                />
                                <Input
                                    variant="bordered"
                                    label="Emergency Contact Number"
                                />
                            </div>
                            <div className="form-row">
                                <Textarea
                                    variant="bordered"
                                    label="Current Address"
                                />
                                <Textarea
                                    variant="bordered"
                                    label="Permanent Address"
                                />
                            </div>

                            <div className="flex justify-center items-center gap-4">
                                <Button className="incidentSubmitBtn" type="submit">
                                    Submit
                                </Button>
                                <Button color="danger" variant="flat">
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default StudentAddForm;
