import React, { useEffect } from "react";
import { useState } from "react";
import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Input,
    Button,
    DropdownTrigger,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Chip,
    User,
    Pagination,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Select,
    SelectItem
} from "@nextui-org/react";
import { VerticalDotsIcon } from "../components/baseComponent/VerticalDotsIcon";
import { ChevronDownIcon } from "../components/baseComponent/ChevronDownIcon";
import { RevaluationColumn, RevalutionList } from "../data/data";
import { capitalize } from "../utils/common";
import { toast } from "react-toastify";
import { useMark } from "../data/Context";
import { progress } from "framer-motion";


const INITIAL_VISIBLE_COLUMNS = [
    "actions",
    "reg_no",
    "name",
    "semester",
    "department",
    "subject",
    "subject_code",
    "mark",
    "grade",
];

export default function RevaluationReviewTable() {
    const { RevaluationEditDetails, updateRevaluationEditDetails } = useMark();

    const [details, setDetails] = useState(RevalutionList);
    const [editData, setEditData] = useState(RevaluationEditDetails)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
    const [viewDetails, setViewDetails] = useState({});


    const handleCloseModal = () => {
        setIsModalVisible(false); // Hide modal
    };

    const handleOpenAssignModal = () => {
        setIsModalVisible(false);
        setIsAssignModalVisible(true)
    }

    const handleAssignCloseModal = () => {
        setIsAssignModalVisible(false); // Hide modal
    };


    const handleSubmit = (indId) => {

        const filteredDetails = details.filter(val => val.id !== indId);
        const ReviewedDetails = details.filter(val => val.id == indId);
        updateRevaluationEditDetails({...ReviewedDetails[0] , progress : "Pending"})
        setDetails(filteredDetails);
        toast.success("Revaluation Assigned successfully!", {
            autoClose: 1000, // 1 second
        });
        setIsAssignModalVisible(false);
    };

    const [isModelBox, setIsModelBox] = React.useState(false);

    const handleModelBoxClick = () => {
        setIsModelBox(!isModelBox);
    };

    const [filterValue, setFilterValue] = React.useState("");
    const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
    const [visibleColumns, setVisibleColumns] = React.useState(
        new Set(INITIAL_VISIBLE_COLUMNS)
    );
    const [statusFilter, setStatusFilter] = React.useState("all");
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [sortDescriptor, setSortDescriptor] = React.useState({
        column: "age",
        direction: "ascending",
    });
    const [page, setPage] = React.useState(1);

    const hasSearchFilter = Boolean(filterValue);

    const headerColumns = React.useMemo(() => {
        if (visibleColumns === "all") return RevaluationColumn;

        return RevaluationColumn.filter((column) =>
            Array.from(visibleColumns).includes(column.uid)
        );
    }, [visibleColumns, details]);

    const filteredItems = React.useMemo(() => {
        let filteredUsers = [...details];
        if (hasSearchFilter) {
            filteredUsers = filteredUsers.filter((user) =>
                user.regNo.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) ||
                user.name.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) ||
                user.semester.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) ||
                user.department.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) ||
                user.subject.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) ||
                user.subjectCode.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) ||
                user.mark.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) ||
                user.grade.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())
            );

        }
        return filteredUsers;
    }, [hasSearchFilter, filterValue, details]);


    const pages = Math.ceil(filteredItems.length / rowsPerPage);

    const items = React.useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [page, filteredItems, rowsPerPage, details]);


    const sortedItems = React.useMemo(() => {
        return [...items].sort((a, b) => {
            const first = a[sortDescriptor.column];
            const second = b[sortDescriptor.column];
            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
    }, [sortDescriptor, items, details]);

    const renderCell = React.useCallback((user, columnKey) => {
        const cellValue = user?.[columnKey];

        switch (columnKey) {
            case "name":
                return (
                    <p className="text-bold text-small whitespace-nowrap capitalize m-0">
                        {user?.name}
                    </p>
                );

            case "reg_no":
                return (
                    <p className="text-bold text-small whitespace-nowrap capitalize m-0">
                        {user?.regNo}
                    </p>
                );

            case "semester":
                return (
                    <p className="text-bold text-small whitespace-nowrap capitalize m-0">
                        {user?.semester}
                    </p>
                );
            case "department":
                return (
                    <p className="text-bold text-small whitespace-nowrap capitalize m-0">
                        {user?.dept}
                    </p>
                );
            case "subject":
                return (
                    <p className="text-bold text-small whitespace-nowrap capitalize m-0">
                        {user?.subject}
                    </p>
                );
            case "subject_code":
                return (
                    <p className="text-bold text-small whitespace-nowrap capitalize m-0">
                        {user?.subjectCode}
                    </p>
                );
            // case "progress":
            //     return (
            //         <p className="text-bold text-small whitespace-nowrap capitalize m-0" style={{ color: user?.progress == "Pending" ? "red" : "green", }}>
            //             {user?.progress}
            //         </p>
            //     );
            case "mark":
                return (
                    <p className="text-bold text-small whitespace-nowrap capitalize m-0">
                        {user?.mark}
                    </p>
                );
            case "grade":
                return (
                    <p className="text-bold text-small whitespace-nowrap capitalize m-0">
                        {user?.grade}
                    </p>
                );
            case "actions":
                return (
                    <div className="relative flex justify-center items-center gap-2" >
                        <Button color="primary" size="sm" variant="light" onClick={_ => { setViewDetails(user); setIsModalVisible(true) }}>
                            Review
                        </Button>
                    </div>
                );
            default:
                return cellValue;
        }
    }, []);

    const onRowsPerPageChange = React.useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    }, [details]);

    const onSearchChange = React.useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue("");
        }
    }, [details]);

    const onClear = React.useCallback(() => {
        setFilterValue("");
        setPage(1);
    }, [details]);

    const topContent = React.useMemo(() => {
        return (

            <div className="flex flex-col gap-4">
                <div className="flex justify-between items-center">
                    <span className="text-default-400 text-small">
                        Total {details.length} Revaluation
                    </span>
                    <div className="flex justify-between basis-[40%] gap-4">
                        <Input
                            isClearable
                            className="w-full"
                            placeholder="Search"
                            value={filterValue}
                            onClear={() => onClear()}
                            onValueChange={onSearchChange}
                        />
                        <div className="flex gap-3">
                            <Dropdown>
                                <DropdownTrigger className="hidden sm:flex">
                                    <Button
                                        endContent={<ChevronDownIcon className="text-small" />}
                                        variant="flat"
                                    >
                                        Columns
                                    </Button>
                                </DropdownTrigger>
                                <DropdownMenu
                                    disallowEmptySelection
                                    aria-label="Table Columns"
                                    closeOnSelect={false}
                                    selectedKeys={visibleColumns}
                                    selectionMode="multiple"
                                    onSelectionChange={setVisibleColumns}
                                >
                                    {RevaluationColumn.map((column) => (
                                        <DropdownItem key={column.uid} className="capitalize">
                                            {capitalize(column.name)}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </div>

                        <label className="flex items-center text-default-400 text-small">
                            Rows:
                            <select
                                className="bg-transparent outline-none text-default-400 text-small"
                                onChange={onRowsPerPageChange}
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                            </select>
                        </label>
                    </div>
                </div>
            </div>
        );
    }, [
        onClear,
        filterValue,
        statusFilter,
        visibleColumns,
        onRowsPerPageChange,
        onSearchChange,

    ]);

    const bottomContent = React.useMemo(() => {
        return (
            <div className="py-2 px-2 flex justify-between items-center">
                <span className="w-[30%] text-small text-default-400">
                    {selectedKeys === "all"
                        ? "All items selected"
                        : `${selectedKeys.size} of ${filteredItems.length} selected`}
                </span>
                <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="secondary"
                    id="pagination"
                    page={page}
                    total={pages}
                    onChange={setPage}
                />
            </div>
        );
    }, [selectedKeys, filteredItems.length, page, pages,]);

    return (
        <>
            <Table
                aria-label="Example table with custom cells, pagination and sorting"
                isHeaderSticky
                bottomContent={bottomContent}
                bottomContentPlacement="outside"
                classNames={{
                    wrapper: "max-h-[382px]",
                }}
                sortDescriptor={sortDescriptor}
                topContent={topContent}
                topContentPlacement="outside"
                onSelectionChange={setSelectedKeys}
                onSortChange={setSortDescriptor}
            >
                <TableHeader columns={headerColumns}>
                    {(column) => (
                        <TableColumn
                            key={column.uid}
                            align={column.uid === "actions" ? "center" : "start"}
                            allowsSorting={column.sortable}
                        >
                            {column.name}
                        </TableColumn>
                    )}
                </TableHeader>
                <TableBody emptyContent={"No users found"} items={sortedItems}>
                    {(item) => (
                        <TableRow key={item.regNo}>
                            {(columnKey) => (
                                <TableCell>{renderCell(item, columnKey)}</TableCell>
                            )}
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            <Modal
                isOpen={isModalVisible}
                onOpenChange={handleCloseModal}
                backdrop="opaque"
                placement="center"
                className="custom-modal"
                style={{ maxWidth: "500px", width: "100%" }}
            >
                <ModalContent>
                    <ModalHeader className="text-xl font-bold text-[#36A2EB]">
                        Review
                    </ModalHeader>
                    <ModalBody>
                        <div className="text-lg">
                            <div><strong>Register No : </strong>{viewDetails.regNo}</div>
                            <div><strong>Name : </strong>{viewDetails.name}</div>
                            <div><strong>Semester : </strong>{viewDetails.semester}</div>
                            <div><strong>Department : </strong>{viewDetails.dept}</div>
                            <div><strong>Subject : </strong>{viewDetails.subject}</div>
                            <div><strong>Code : </strong>{viewDetails.subjectCode}</div>
                            <div><strong>Mark : </strong>{viewDetails.mark}</div>
                            <div><strong>Grade : </strong>{viewDetails.grade}</div>
                        </div>
                    </ModalBody>
                    <ModalFooter className="flex justify-center items-center gap-4">
                        <Button auto className="bg-[#FF4D4F99]" onClick={handleCloseModal}>
                            Close
                        </Button>
                        <Button auto className="incidentSubmitBtn" onClick={handleOpenAssignModal}>
                            Assign
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal
                isOpen={isAssignModalVisible}
                onOpenChange={handleAssignCloseModal}
                backdrop="opaque"
                placement="center"
                className="custom-modal"
                style={{ maxWidth: "400px", width: "100%" }}
            >
                <ModalContent>
                    <ModalHeader className="text-xl font-bold text-[#36A2EB]">
                        Assign
                    </ModalHeader>
                    <ModalBody>
                        <div className="flex justify-center">
                            <Select label="Select Staff" labelPlacement="outside" className={`max-w-full mb-6`} variant="bordered">
                                <SelectItem key="Arjun" value="Arjun">Arjun</SelectItem>
                                <SelectItem key="Priya" value="Priya">Priya</SelectItem>
                                <SelectItem key="Rajesh" value="Rajesh">Rajesh</SelectItem>
                                <SelectItem key="Meena" value="Meena">Meena</SelectItem>
                                <SelectItem key="Vikram" value="Vikram">Vikram</SelectItem>
                            </Select>
                        </div>
                    </ModalBody>
                    <ModalFooter className="flex justify-center items-center gap-4">
                        <Button auto className="bg-[#FF4D4F99]" onClick={handleAssignCloseModal}>
                            Close
                        </Button>
                        <Button auto className="incidentSubmitBtn" onClick={_ => handleSubmit(viewDetails.id)}>
                            Submit to Review
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
