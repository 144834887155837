import React, { useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input
} from "@nextui-org/react";
import { UilPlus, UilMinus } from '@iconscout/react-unicons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./ModelForm.css";

const GradeSetupForm = ({ isOpen, onOpenChange, backdrop = "blur" }) => {
  const [gradeFields, setGradeFields] = useState([{ from: "", to: "", grade: "" }]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;

    // Ensure only numbers are entered and the length is 3 digits
    if (/^\d{0,3}$/.test(value)) {
      const updatedFields = [...gradeFields];
      updatedFields[index][name] = value;
      setGradeFields(updatedFields);
    }
  };

  const handleAddFields = () => {
    setGradeFields([...gradeFields, { from: "", to: "", grade: "" }]);
  };

  const handleRemoveFields = (index) => {
    const updatedFields = gradeFields.filter((_, i) => i !== index);
    setGradeFields(updatedFields);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    toast.success("Grades setup submitted successfully!");
    onOpenChange(false); // Close the modal after submitting
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        backdrop={backdrop}
        placement="center"
        style={{ maxWidth: "900px", width: "100%" }}
      >
        <ModalContent>
          {({ onClose }) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Grade Setup
              </ModalHeader>
              <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
                <form onSubmit={handleSubmit}>
                  {gradeFields.map((field, index) => (
                    <div className="form-row" key={index} style={{ marginBottom: "16px", display: "flex", alignItems: "center" }}>
                      <Input
                        variant="bordered"
                        label={`Mark From ${!field.from ? "*" : ""}`}
                        required
                        name="from"
                        type="number"
                        value={field.from}
                        onChange={(e) => handleInputChange(index, e)}
                        maxLength={3} // Limit input to 3 digits
                        style={{ flex: 1, marginRight: "10px" }}
                      />
                      <Input
                        variant="bordered"
                        label={`Mark To ${!field.to ? "*" : ""}`}
                        required
                        name="to"
                        type="number"
                        value={field.to}
                        onChange={(e) => handleInputChange(index, e)}
                        maxLength={3} // Limit input to 3 digits
                        style={{ flex: 1, marginRight: "10px" }}
                      />
                      <Input
                        variant="bordered"
                        label={`Grade ${!field.grade ? "*" : ""}`}
                        required
                        name="grade"
                        value={field.grade}
                        onChange={(e) => handleInputChange(index, e)}
                        style={{ flex: 1 }}
                      />
                      <div style={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
                        <button
                          type="button"
                          className="add-icon"
                          onClick={index === gradeFields.length - 1 ? handleAddFields : () => handleRemoveFields(index)}
                          style={{
                            background: index === gradeFields.length - 1 ? "rgb(54, 162, 235)" : "rgb(255, 75, 75)",
                            border: "none",
                            cursor: "pointer",
                            padding: "4px",
                            borderRadius: "4px"
                          }}
                        >
                          {index === gradeFields.length - 1 ? (
                            <UilPlus size="24" color="#fff" />
                          ) : (
                            <UilMinus size="24" color="#fff" />
                          )}
                        </button>
                      </div>
                    </div>
                  ))}

                  <ModalFooter className="flex justify-center items-center gap-4 mt-4">
                    <Button color="danger" variant="flat" onClick={() => onOpenChange(false)}>
                      Close
                    </Button>
                    <Button className="incidentSubmitBtn" type="submit">
                      Submit
                    </Button>
                  </ModalFooter>
                </form>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default GradeSetupForm;
