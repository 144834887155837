import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Select,
  SelectItem,
} from "@nextui-org/react";
import "./ModelForm.css";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function RoleForm({ isOpen, onOpenChange, backdrop = "blur" }) {
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent form from refreshing the page
    // Add your form submission logic here
    // console.log("Form submitted!");

    // Show success toast
    toast.success("Role added successfully!");
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        backdrop={backdrop}
        placement="center"
        style={{ maxWidth: "700px", width: "100%" }}
      >
        <ModalContent>
          {({ onClose }) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Add New Role
              </ModalHeader>
              <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
                <form onSubmit={handleSubmit}>
                  <div className="form-row">
                    <Select
                      label="Department"
                      className="custom-select"
                      labelPlacement="outside"
                      variant="bordered"
                      fullWidth
                    >
                      <SelectItem key="Admin" value="Admin">
                        Admin
                      </SelectItem>
                      <SelectItem key="Purchase" value="Purchase">
                        Purchase
                      </SelectItem>
                      <SelectItem key="Accounts" value="Accounts">
                        Accounts
                      </SelectItem>
                    </Select>
                    <Input
                      variant="bordered"
                      label="Role Name"
                      labelPlacement="outside"
                    />
                  </div>
                  <ModalFooter className="flex justify-center items-center gap-4">
                    <Button color="danger" variant="flat" onClick={onClose}>
                      Close
                    </Button>
                    <Button
                      className="incidentSubmitBtn"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </form>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
      <ToastContainer />
    </>
  );
}
