import React from "react";
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    Select,
    SelectItem,
    DatePicker
} from "@nextui-org/react";
import "./ModelForm.css";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function PlacementRequirementForm({ isOpen, onOpenChange, backdrop = "blur" }) {
    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent form from refreshing the page
        // Add your form submission logic here
        // console.log("Form submitted!");

        // Show success toast
        toast.success("PlaceemntRequirement added successfully!");
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                backdrop={backdrop}
                placement="center"
                style={{ maxWidth: "700px", width: "100%" }}
            >
                <ModalContent>
                    {({ onClose }) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">
                                Placement Requirement
                            </ModalHeader>
                            <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-row">
                                        <Select
                                            label="Degree Program"
                                            className="custom-select"
                                            labelPlacement="outside"
                                            variant="bordered"
                                            fullWidth
                                        >
                                            <SelectItem key="Admin" value="Admin">
                                                UG
                                            </SelectItem>
                                            <SelectItem key="Purchase" value="Purchase">
                                                PG
                                            </SelectItem>
                                            <SelectItem key="Accounts" value="Accounts">
                                                Polytechnic
                                            </SelectItem>
                                        </Select>
                                        <Select
                                            label="Major"
                                            className="custom-select"
                                            labelPlacement="outside"
                                            variant="bordered"
                                            fullWidth
                                        >
                                            <SelectItem key="Admin" value="Admin">
                                                Arts
                                            </SelectItem>
                                            <SelectItem key="Purchase" value="Purchase">
                                                University
                                            </SelectItem>

                                        </Select>
                                        <Select
                                            label="Specialization"
                                            className="custom-select"
                                            labelPlacement="outside"
                                            variant="bordered"
                                            fullWidth
                                        >
                                            <SelectItem key="Admin" value="Admin">
                                                Artificial Intelligence
                                            </SelectItem>
                                            <SelectItem key="Purchase" value="Purchase">
                                                Machine Learning
                                            </SelectItem>

                                        </Select>

                                    </div>
                                    <div className="form-row">
                                        <Select
                                            label="Year"
                                            className="custom-select"
                                            labelPlacement="outside"
                                            variant="bordered"
                                            fullWidth
                                        >
                                            <SelectItem key="Admin" value="Admin">
                                                2001
                                            </SelectItem>
                                            <SelectItem key="Purchase" value="Purchase">
                                                2002
                                            </SelectItem>
                                            <SelectItem key="Accounts" value="Accounts">
                                                2003
                                            </SelectItem>
                                        </Select>
                                        <Select
                                            label="12th Mark"
                                            className="custom-select"
                                            labelPlacement="outside"
                                            variant="bordered"
                                            fullWidth
                                        >
                                            <SelectItem key="Admin" value="Admin">
                                                80%
                                            </SelectItem>
                                            <SelectItem key="Purchase" value="Purchase">
                                                90%
                                            </SelectItem>

                                        </Select>
                                        <Select
                                            label="Required CGPA"
                                            className="custom-select"
                                            labelPlacement="outside"
                                            variant="bordered"
                                            fullWidth
                                        >
                                            <SelectItem key="Admin" value="Admin">
                                                above 9
                                            </SelectItem>
                                            <SelectItem key="Purchase" value="Purchase">
                                                avove 8
                                            </SelectItem>

                                        </Select>

                                    </div>

                                    <div className="form-row">
                                        <Select
                                            label="10th Mark"
                                            className="custom-select"
                                            labelPlacement="outside"
                                            variant="bordered"
                                            fullWidth
                                        >
                                            <SelectItem key="Admin" value="Admin">
                                                90%
                                            </SelectItem>
                                            <SelectItem key="Purchase" value="Purchase">
                                                80%
                                            </SelectItem>

                                        </Select>
                                        <Select
                                            label="Gender"
                                            className="custom-select"
                                            labelPlacement="outside"
                                            variant="bordered"
                                            fullWidth
                                        >
                                            <SelectItem key="Admin" value="Admin">
                                                Female
                                            </SelectItem>
                                            <SelectItem key="Purchase" value="Purchase">
                                                Male
                                            </SelectItem>

                                        </Select>
                                        <Select
                                            label="Backlogs"
                                            className="custom-select"
                                            labelPlacement="outside"
                                            variant="bordered"
                                            fullWidth
                                        >
                                            <SelectItem key="Admin" value="Admin">
                                                Yes
                                            </SelectItem>
                                            <SelectItem key="Purchase" value="Purchase">
                                                No
                                            </SelectItem>

                                        </Select>

                                    </div>
                                    <div className="form-row">
                                        <Select
                                            label="City"
                                            className="custom-select"
                                            labelPlacement="outside"
                                            variant="bordered"
                                            fullWidth
                                        >
                                            <SelectItem key="Admin" value="Admin">
                                                Trichy
                                            </SelectItem>
                                            <SelectItem key="Purchase" value="Purchase">
                                                Chennai
                                            </SelectItem>

                                        </Select>
                                        <Select
                                            label="Willing to Relocate"
                                            className="custom-select"
                                            labelPlacement="outside"
                                            variant="bordered"
                                            fullWidth
                                        >
                                            <SelectItem key="Admin" value="Admin">
                                                Yes
                                            </SelectItem>
                                            <SelectItem key="Purchase" value="Purchase">
                                                No
                                            </SelectItem>

                                        </Select>
                                        <Select
                                            label="Placed"
                                            className="custom-select"
                                            labelPlacement="outside"
                                            variant="bordered"
                                            fullWidth
                                        >
                                            <SelectItem key="Admin" value="Admin">
                                                Yes
                                            </SelectItem>
                                            <SelectItem key="Purchase" value="Purchase">
                                                No
                                            </SelectItem>

                                        </Select>

                                    </div>

                                    <ModalFooter className="flex justify-center items-center gap-4">
                                        <Button color="danger" variant="flat" onClick={onClose}>
                                            Close
                                        </Button>
                                        <Button
                                            className="incidentSubmitBtn"
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </ModalFooter>
                                </form>
                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal>
            <ToastContainer />
        </>
    );
}
