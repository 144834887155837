import React, { useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Select,
  SelectItem
} from "@nextui-org/react";
import { UilPlus, UilMinus } from '@iconscout/react-unicons';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./ModelForm.css";

const SubjectForm = ({ isOpen, onOpenChange, backdrop = "blur" }) => {
  const [subjectFields, setSubjectFields] = useState([
    { id: Date.now(), subjectCode: "", subjectName: "" }
  ]);

  const addSubjectField = () => {
    setSubjectFields([
      ...subjectFields,
      { id: Date.now(), subjectCode: "", subjectName: "" }
    ]);
  };

  const removeSubjectField = (id) => {
    setSubjectFields(subjectFields.filter((field) => field.id !== id));
  };

  const handleInputChange = (id, field, value) => {
    setSubjectFields(subjectFields.map((subjectField) =>
      subjectField.id === id ? { ...subjectField, [field]: value } : subjectField
    ));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    toast.success("Subject added successfully!");
    onOpenChange(false); // Close the modal after submitting
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        backdrop={backdrop}
        placement="center"
        style={{ maxWidth: "900px", width: "100%" }}
      >
        <ModalContent>
          {({ onClose }) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Add Subject
              </ModalHeader>
              <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
                <form onSubmit={handleSubmit}>
                  <div className="form-row">
                    <Select
                      label="Department"
                      className="custom-select"
                      labelPlacement="outside"
                      variant="bordered"
                    >
                      <SelectItem key="btech_cse" value="btech_cse">
                        B.Tech. Computer Science Engineering
                      </SelectItem>
                      <SelectItem key="btech_aids" value="btech_aids">
                        B.Tech Artificial Intelligence and Data Science
                      </SelectItem>
                      <SelectItem key="btech_ece" value="btech_ece">
                        B.Tech. Electronics and Communication Engineering
                      </SelectItem>
                      <SelectItem key="btech_mechanical" value="btech_mechanical">
                        B.Tech. Mechanical Engineering
                      </SelectItem>
                      <SelectItem key="btech_civil" value="btech_civil">
                        B.Tech. Civil Engineering
                      </SelectItem>
                      <SelectItem key="btech_chemical" value="btech_chemical">
                        B.Tech. Chemical Engineering
                      </SelectItem>
                      <SelectItem key="btech_it" value="btech_it">
                        B.Tech. Information Technology
                      </SelectItem>
                    </Select>
                    <Select
                      label="Semester"
                      className="custom-select"
                      labelPlacement="outside"
                      variant="bordered"
                      fullWidth
                      required
                    >
                      <SelectItem key="Sem1" value="Sem1">
                        Semester 1
                      </SelectItem>
                      <SelectItem key="Sem2" value="Sem2">
                        Semester 2
                      </SelectItem>
                      <SelectItem key="Sem3" value="Sem3">
                        Semester 3
                      </SelectItem>
                      <SelectItem key="Sem4" value="Sem4">
                        Semester 4
                      </SelectItem>
                      <SelectItem key="Sem5" value="Sem5">
                        Semester 5
                      </SelectItem>
                      <SelectItem key="Sem6" value="Sem6">
                        Semester 6
                      </SelectItem>
                      <SelectItem key="Sem7" value="Sem7">
                        Semester 7
                      </SelectItem>
                      <SelectItem key="Sem8" value="Sem8">
                        Semester 8
                      </SelectItem>
                    </Select>
                  </div>

                  {subjectFields.map((field, index) => (
                    <div key={field.id} className="form-row flex items-center">
                      <Input
                        variant="bordered"
                        label="Subject Code"
                        labelPlacement="outside"
                        required
                        value={field.subjectCode}
                        onChange={(e) => handleInputChange(field.id, "subjectCode", e.target.value)}
                        style={{ flex: 1, marginRight: "10px" }}
                      />
                      <Input
                        variant="bordered"
                        label="Subject Name"
                        labelPlacement="outside"
                        required
                        value={field.subjectName}
                        onChange={(e) => handleInputChange(field.id, "subjectName", e.target.value)}
                        style={{ flex: 1 }}
                      />
                      <button
                        type="button"
                        className="add-icon max-w-[30px] mt-6"
                        onClick={index === subjectFields.length - 1 ? addSubjectField : () => removeSubjectField(field.id)}
                        style={{
                          marginLeft: "10px",
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "none",
                          cursor: "pointer",
                          borderRadius: "4px",
                          backgroundColor: index === subjectFields.length - 1 ? "rgb(54, 162, 235)" : "rgb(255, 75, 75)"
                        }}
                      >
                        {index === subjectFields.length - 1 ? (
                          <UilPlus size="24" color="#fff" />
                        ) : (
                          <UilMinus size="24" color="#fff" />
                        )}
                      </button>
                    </div>
                  ))}

                  <ModalFooter className="flex justify-center items-center gap-4 mt-4">
                    <Button color="danger" variant="flat" onClick={() => onOpenChange(false)}>
                      Close
                    </Button>
                    <Button className="incidentSubmitBtn" type="submit">
                      Submit
                    </Button>
                  </ModalFooter>
                </form>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default SubjectForm;
