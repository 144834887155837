import React from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Pagination,
} from "@nextui-org/react";
import { VerticalDotsIcon } from "./VerticalDotsIcon";
import { ChevronDownIcon } from "./ChevronDownIcon";
import { ExamTimetablecolumn, ExameTimetableList } from "../../data/data";
import { capitalize } from "../../utils/common";

const INITIAL_VISIBLE_COLUMNS = [
  "exametable_semester",
  "exametable_subject_code",
  "examtable_subject_name",
  "examtable_date",
  "exametable_session",
  "actions",
];

export default function ExamTimetableTable() {
  const [isModelBox, setIsModelBox] = React.useState(false);
  const handleModelBoxClick = () => {
    setIsModelBox(!isModelBox);
  };

  const [filterValue, setFilterValue] = React.useState("");
  const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
  const [visibleColumns, setVisibleColumns] = React.useState(
    new Set(INITIAL_VISIBLE_COLUMNS)
  );
  const [statusFilter, setStatusFilter] = React.useState("all");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [sortDescriptor, setSortDescriptor] = React.useState({
    column: "examtable_date",
    direction: "ascending",
  });
  const [page, setPage] = React.useState(1);

  const hasSearchFilter = Boolean(filterValue);

  const headerColumns = React.useMemo(() => {
    if (visibleColumns === "all") return ExamTimetablecolumn;

    return ExamTimetablecolumn.filter((column) =>
      Array.from(visibleColumns).includes(column.uid)
    );
  }, [visibleColumns]);

  const filteredItems = React.useMemo(() => {
    let filteredTimetables = [...ExameTimetableList];
    if (hasSearchFilter) {
      filteredTimetables = filteredTimetables.filter((item) =>
        item.exametable_semester.toLowerCase().includes(filterValue.toLowerCase()) ||
        item.exametable_subject_code.toLowerCase().includes(filterValue.toLowerCase()) ||
        item.examtable_subject_name.toLowerCase().includes(filterValue.toLowerCase()) ||
        item.examtable_date.toLowerCase().includes(filterValue.toLowerCase()) ||
        item.exametable_session.toLowerCase().includes(filterValue.toLowerCase())
      );
    }
    return filteredTimetables;
  }, [hasSearchFilter, filterValue]);

  const pages = Math.ceil(filteredItems.length / rowsPerPage);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);

  const renderCell = React.useCallback((item, columnKey) => {
    const cellValue = item[columnKey];
    
    const rowStyle = item.id === 3 ? { color: "red" } : {};

    switch (columnKey) {
      case "exametable_semester":
        return <p style={rowStyle}>{cellValue}</p>;

      case "exametable_subject_code":
        return <p style={rowStyle}>{cellValue}</p>;

      case "examtable_subject_name":
        return <p style={rowStyle}>{cellValue}</p>;

      case "examtable_date":
        return <p style={rowStyle}>{cellValue}</p>;

      case "exametable_session":
        return <p style={rowStyle}>{cellValue}</p>;

      case "actions":
        return (
          <div className="relative flex justify-center items-center gap-2" style={rowStyle}>
            <Dropdown className="dark:bg-neutral-800">
              <DropdownTrigger>
                <Button isIconOnly size="sm" variant="light">
                  <VerticalDotsIcon className="text-default-300" />
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem onClick={handleModelBoxClick}>View</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        );
      default:
        return <p style={rowStyle}>{cellValue}</p>;
    }
  }, []);

  const onRowsPerPageChange = React.useCallback((e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  }, []);

  const onSearchChange = React.useCallback((value) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = React.useCallback(() => {
    setFilterValue("");
    setPage(1);
  }, []);

  const topContent = React.useMemo(() => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-1 items-center ">
          <h1
            className="report-heading"
            style={{ marginLeft: "0px", fontSize: "25px", fontWeight: "bold" }}
          >
            Upcoming TimeTable
          </h1>
          <div className="flex gap-4">
            <Input
              label="REGISTER NUMBER"
              type="text"
              labelPlacement="inside"
              defaultValue="812818114038"
              readOnly
              style={{ width: "135px" }}
            />
            <Input
              label="DEPARTMENT"
              type="text"
              labelPlacement="inside"
              defaultValue="AIDS"
              readOnly
              style={{ width: "90px" }}
            />
            <Input
              label="SEMESTER"
              type="text"
              labelPlacement="inside"
              defaultValue="04"
              readOnly
              style={{ width: "70px" }}
            />
          </div>
        </div>
        <div className="flex justify-between items-center mb-4">
          <span className="text-default-400 text-small">
            Total {ExameTimetableList.length} exam
          </span>
          <div className="flex justify-between basis-[40%] gap-4">
            <Input
              isClearable
              className="w-full"
              placeholder="Search"
              value={filterValue}
              onClear={() => onClear()}
              onValueChange={onSearchChange}
            />
            <div className="flex gap-3">
              <Dropdown>
                <DropdownTrigger className="hidden sm:flex">
                  <Button
                    endContent={<ChevronDownIcon className="text-small" />}
                    variant="flat"
                  >
                    Columns
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  disallowEmptySelection
                  aria-label="Table Columns"
                  closeOnSelect={false}
                  selectedKeys={visibleColumns}
                  selectionMode="multiple"
                  onSelectionChange={setVisibleColumns}
                >
                  {ExamTimetablecolumn.map((column) => (
                    <DropdownItem key={column.uid} className="capitalize">
                      {capitalize(column.name)}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
            <label className="flex items-center text-default-400 text-small">
              Rows:
              <select
                className="bg-transparent outline-none text-default-400 text-small"
                onChange={onRowsPerPageChange}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
              </select>
            </label>
          </div>
        </div>
      </div>
    );
  }, [
    onClear,
    filterValue,
    visibleColumns,
    onRowsPerPageChange,
    onSearchChange,
  ]);

  const bottomContent = React.useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-between items-center">
        <span className="w-[30%] text-small text-default-400">
          {selectedKeys === "all"
            ? "All items selected"
            : `${selectedKeys.size} of ${filteredItems.length} selected`}
        </span>
        <Pagination
          isCompact
          showControls
          showShadow
          color="secondary"
          id="pagination"
          page={page}
          total={pages}
          onChange={setPage}
        />
      </div>
    );
  }, [filteredItems, selectedKeys, page, pages]);

  return (
    <>
      {topContent}
      <Table
        aria-label="Exam Timetable table"
        bottomContent={bottomContent}
        selectedKeys={selectedKeys}
        onSortChange={setSortDescriptor}
      >
        <TableHeader columns={headerColumns}>
          {(column) => (
            <TableColumn
              key={column.uid}
              hideHeader={column.uid === "actions"}
              className="text-small"
              allowsSorting={column.sortable}
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody emptyContent={"No exams found"} items={sortedItems}>
          {(item) => (
            <TableRow key={item.id}>
              {(columnKey) => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}
