import React from 'react';
import appImage from '../../images/auth-screens.png'
import { Input } from "@nextui-org/react";
import { MailIcon } from '../../components/baseComponent/MailIcon';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const ForgetPassword = () => {
  const [email, setEmail] = useState("");

  const navigate = useNavigate();
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const sendResetlink = async (e) => {
    e.preventDefault();
    if (emailPattern.test(email)) {

      toast.success("Reset link sent successfully", {
        autoClose: 2500,
      })
    } else {
      toast.error("Please enter a valid email address", {
        autoClose: 2500,
      });
    }
  }

  return (
    <section className='auth-section'>
      <div className="auth-section__wrapper lg:flex justify-center items-center" style={{ background: `url('/images/landerpage.jpg')`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100vh" }}>
        <div className="right-side__wrapper min-h-dvh py-4 px-6 flex flex-col justify-center lg:w-2/4">
          <div className="form-content__wrapper border px-10 py-20 bg-[#fff] border-1 rounded-[20px] border-[#000] md:px-12 max-w-[660px] flex flex-col items-center justify-center gap-4 mx-auto">
            <div className="auth-form__title flex flex-col items-center justify-center gap-4">
              <h3 className='m-0 mb-4 text-3xl font-bold text-black dark:text-white'>Forgot Password?</h3>
              <p className='m-0 text-sm text-black dark:text-white'>Enter your email and we'll send you instructions to reset your password</p>
            </div>
            <form className='flex-1 w-full'>
              <div className="flex flex-col gap-8 mt-6 w-full">
                <Input
                  autoFocus
                  pattern='^[^\s@]+@[^\s@]+\.[^\s@]+$'
                  startContent={
                    <MailIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                  }
                  label="Email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  variant="bordered"
                  labelPlacement='outside'
                />
                <button onClick={sendResetlink} type="submit" className="py-3 px-3 text-[#000] border border-1 border-[#E2B49A] dark:border-[#fff] text-white dark:text-white text-xs font-semibold rounded-md shadow focus:outline-none incidentSubmitBtn">
                  Send Reset Link
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ForgetPassword;