import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Input,
} from "@nextui-org/react";
import "../components/baseComponent/ModelForm.css";

const ApplyExaminationForm = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    navigate("/student/Subjectdetails");
  };

  return (
    <div className="main-inner-wrapper leavemenu-wrapper">

      <div className="px-4">

        <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
          <h1 className="report-heading" style={{
            marginLeft: "0px", marginBottom: "40px", fontSize: "25px",fontWeight: "bold",}}>Apply for examination</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-container-directpage">
              <div className="form-row-directpage">
                <Input variant="bordered" label="Register Number" />
              </div>
              <div className="form-row-directpage">
                <Input variant="bordered" label="Name" />
              </div>
              <div className="form-row-directpage">
                <Input variant="bordered" label="Year" />
              </div>
              <div className="form-row-directpage">
                <Input variant="bordered" label="Semester" />
              </div>
              <div className="form-row-directpage">
                <Input variant="bordered" label="Department" />
              </div>
              <div className=" flex justify-center items-center gap-4">
                <Button className="incidentSubmitBtn" type="submit">
                  Next
                </Button>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  );
};

export default ApplyExaminationForm;
