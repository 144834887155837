import React from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Pagination,
} from "@nextui-org/react";
import { VerticalDotsIcon } from "./VerticalDotsIcon";
import { ChevronDownIcon } from "./ChevronDownIcon";
import { CoeArrearHistoryColumn, CoeArrearHistoryList } from "../../data/data";
import { capitalize } from "../../utils/common";

const INITIAL_VISIBLE_COLUMNS = [
  "arrear_serial_number",
  "arrear_semester",
  "arrear_subject_code",
  "arrear_subject_name",
  "arrear_date",
  "arrear_department",
  "arrear_year",
  "actions",
];

export default function ArrearCoeTable() {
  const [isModelBox, setIsModelBox] = React.useState(false);
  const handleModelBoxClick = () => {
    setIsModelBox(!isModelBox);
  };

  const [departmentFilter, setDepartmentFilter] = React.useState("all");
  const [yearFilter, setYearFilter] = React.useState("all");
  const [semesterFilter, setSemesterFilter] = React.useState("all");
  const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
  const [visibleColumns, setVisibleColumns] = React.useState(
    new Set(INITIAL_VISIBLE_COLUMNS)
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [sortDescriptor, setSortDescriptor] = React.useState({
    column: "age",
    direction: "ascending",
  });
  const [page, setPage] = React.useState(1);

  const hasFilters = departmentFilter !== "all" || yearFilter !== "all";

  const headerColumns = () => {
    if (visibleColumns === "all") return CoeArrearHistoryColumn;
    return CoeArrearHistoryColumn.filter((column) =>
      Array.from(visibleColumns).includes(column.uid)
    );
  };

  const filteredItems = () => {
    let filteredUsers = [...CoeArrearHistoryList];

    if (departmentFilter !== "all") {
      filteredUsers = filteredUsers.filter(
        (user) => user.arrear_department === departmentFilter
      );
    }

    if (yearFilter !== "all") {
      filteredUsers = filteredUsers.filter(
        (user) => user.arrear_year === yearFilter
      );
    }

    if (semesterFilter !== "all") {
      filteredUsers = filteredUsers.filter(
        (user) => user.arrear_semester === semesterFilter
      );
    }

    return filteredUsers;
  };

  const pages = Math.ceil(filteredItems().length / rowsPerPage);

  const items = () => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return filteredItems().slice(start, end);
  };

  const sortedItems = () => {
    return [...items()].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;
      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  };

  const renderCell = (user, columnKey) => {
    const cellValue = user[columnKey];

    switch (columnKey) {
      case "name":
        return (
          <p className="text-bold text-small whitespace-nowrap capitalize m-0">
            {user?.name}
          </p>
        );

      case "actions":
        return (
          <div className="relative flex justify-center items-center gap-2">
            <Dropdown className="dark:bg-neutral-800">
              <DropdownTrigger>
                <Button isIconOnly size="sm" variant="light">
                  <VerticalDotsIcon className="text-default-300" />
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem onClick={handleModelBoxClick}>View</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        );
      default:
        return cellValue;
    }
  };

  const onRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  };

  const onDepartmentChange = (key) => {
    setDepartmentFilter(key);
    setPage(1);
  };

  const onYearChange = (key) => {
    setYearFilter(key);
    setPage(1);
  };

  const onSemesterChange = (key) => {
    setSemesterFilter(key);
    setPage(1);
  };

  const topContent = () => {
    const departments = Array.from(
      new Set(CoeArrearHistoryList.map((item) => item.arrear_department))
    );
    const years = Array.from(
      new Set(CoeArrearHistoryList.map((item) => item.arrear_year))
    );
    const semesters = Array.from(
      new Set(CoeArrearHistoryList.map((item) => item.arrear_semester))
    );

    return (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-1 items-center">
          {/* Other top content here */}
        </div>
        <div className="flex justify-between items-center">
          <span className="text-default-400 text-small">
            Total {CoeArrearHistoryList.length} Arrear History
          </span>

          <div className="flex gap-4">
            <Dropdown>
              <DropdownTrigger className="hidden sm:flex">
                <Button
                  endContent={<ChevronDownIcon className="text-small" />}
                  variant="flat"
                >
                  {departmentFilter === "all" ? "Department" : departmentFilter}
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                selectedKeys={departmentFilter}
              >
                <DropdownItem onClick={_ => onDepartmentChange("all")} key="all">
                  All Departments
                </DropdownItem>
                {departments.map((dept) => (
                  <DropdownItem onClick={_ => onDepartmentChange(dept)} key={dept}>{dept}</DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown>
              <DropdownTrigger className="hidden sm:flex">
                <Button
                  endContent={<ChevronDownIcon className="text-small" />}
                  variant="flat"
                >
                  {yearFilter === "all" ? "Year" : yearFilter}
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                selectedKeys={yearFilter}
              >
                <DropdownItem onClick={_ => onYearChange("all")} key="all" >
                  All Year
                </DropdownItem>
                {years.map((year) => (
                  <DropdownItem onClick={_ => onYearChange(year)} key={year}>{year}</DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown>
              <DropdownTrigger className="hidden sm:flex">
                <Button
                  endContent={<ChevronDownIcon className="text-small" />}
                  variant="flat"
                >
                  {semesterFilter === "all" ? "Semester" : semesterFilter}
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                selectedKeys={semesterFilter}
              >
                <DropdownItem onClick={_ => onSemesterChange("all")} key="all" >
                  All Semester
                </DropdownItem>
                {semesters.map((sem) => (
                  <DropdownItem onClick={_ => onSemesterChange(sem)} key={sem}>{sem}</DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>

            <label className="flex items-center text-default-400 text-small">
              Rows:
              <select
                className="bg-transparent outline-none text-default-400 text-small"
                onChange={onRowsPerPageChange}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
              </select>
            </label>
          </div>
        </div>
      </div>
    );
  };

  const bottomContent = () => {
    return (
      <div className="py-2 px-2 flex justify-between items-center">
        <span className="w-[30%] text-small text-default-400">
          {selectedKeys === "all"
            ? "All items selected"
            : `${selectedKeys.size} of ${filteredItems().length} selected`}
        </span>
        <Pagination
          isCompact
          showControls
          showShadow
          color="secondary"
          id="pagination"
          page={page}
          total={pages}
          onChange={setPage}
        />
      </div>
    );
  };

  return (
    <>
      <Table
        aria-label="Example table with custom cells, pagination and sorting"
        isHeaderSticky
        bottomContent={bottomContent()}
        bottomContentPlacement="outside"
        classNames={{
          wrapper: "max-h-[382px] scrollbar-thin scrollbar-thumb-gray scrollbar-track-gray-200 pt-0 pb-4 px-0",
        }}
        sortDescriptor={sortDescriptor}
        topContent={topContent()}
        topContentPlacement="outside"
        onSelectionChange={setSelectedKeys}
        onSortChange={setSortDescriptor}
      >
        <TableHeader columns={headerColumns()}>
          {(column) => (
            <TableColumn
              key={column.uid}
              align={column.uid === "actions" ? "center" : "start"}
              allowsSorting={column.sortable}
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody emptyContent={"No items found"} items={sortedItems()}>
          {(item) => (
            <TableRow key={item.id}>
              {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
      {isModelBox && <div>ModelBox Content</div>}
    </>
  );
}
