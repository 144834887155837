import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  DatePicker,
  Select,
  SelectItem,
} from "@nextui-org/react";
import "./ModelForm.css";

export default function ExamTimetableSetupForm({ isOpen, onOpenChange, backdrop = "blur" }) {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        backdrop={backdrop}
        placement="center"
        className="custom-modal" // Apply the class directly to the Modal
        style={{ maxWidth: "700px", width: "100%" }} // Adjust width styles
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
         Time Table Setup
              </ModalHeader>
              <ModalBody className="custom-modal-body">
                <div className="form-row">
                  <Input
                    variant="bordered"
                    label="Department"
                    labelPlacement="outside"
                  />
                  <Input
                    variant="bordered"
                    label="Year"
                    labelPlacement="outside"
                    fullWidth
                  />
                </div>

                <div className="form-row">
                  <Input
                    variant="bordered"
                    label="Semester"
                    labelPlacement="outside"
                    fullWidth
                  />
                   <Input
                    variant="bordered"
                    label="Subject Name"
                    labelPlacement="outside"
                    fullWidth
                  />
                 
                </div>

                <div className="form-row">
                <Input
                    variant="bordered"
                    label="Subject Code"
                    labelPlacement="outside"
                    fullWidth
                  />
                  <Input
                    variant="bordered"
                    label="Session"
                    labelPlacement="outside"
                    fullWidth
                  />
                 
                </div>



              </ModalBody>
              <ModalFooter className="flex justify-center items-center gap-4">
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
                <Button id="apply-btn" color="primary" onPress={onClose}>
                  Submit
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
