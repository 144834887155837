import React, { useState } from 'react';
import { Button, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react";
import { useNavigate } from 'react-router-dom';
import CanvasJSReact from '@canvasjs/react-charts';

const AdminDashboard = () => {
  const [selectedYear, setSelectedYear] = useState('1st Year');
  const navigate = useNavigate();
  const CanvasJSChart = CanvasJSReact.CanvasJSChart;

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  const getStudentData = (year) => {
    switch (year) {
      case '1st Year':
        return [
          { label: "IT", y: 95 },
          { label: "Mech", y: 60 },
          { label: "ECE", y: 75 },
          { label: "CSE", y: 90 }
        ];
      case '2nd Year':
        return [
          { label: "IT", y: 90 },
          { label: "Mech", y: 80 },
          { label: "ECE", y: 70 },
          { label: "CSE", y: 75 }
        ];
      case '3rd Year':
        return [
          { label: "IT", y: 95 },
          { label: "Mech", y: 82 },
          { label: "ECE", y: 78 },
          { label: "CSE", y: 79 }
        ];
      case '4th Year':
        return [
          { label: "IT", y: 85 },
          { label: "Mech", y: 79 },
          { label: "ECE", y: 72 },
          { label: "CSE", y: 74 }
        ];
      default:
        return [];
    }
  };

  const getStaffData = (year) => {
    switch (year) {
      case '1st Year':
        return [
          { label: "IT", y: 40 },
          { label: "Mech", y: 35 },
          { label: "ECE", y: 25 },
          { label: "CSE", y: 10 }
        ];
      case '2nd Year':
        return [
          { label: "IT", y: 10 },
          { label: "Mech", y: 32 },
          { label: "ECE", y: 18 },
          { label: "CSE", y: 25 }
        ];
      case '3rd Year':
        return [
          { label: "IT", y: 35 },
          { label: "Mech", y: 13 },
          { label: "ECE", y: 27 },
          { label: "CSE", y: 15 }
        ];
      case '4th Year':
        return [
          { label: "IT", y: 45 },
          { label: "Mech", y: 10 },
          { label: "ECE", y: 23 },
          { label: "CSE", y: 16 }
        ];
      default:
        return [];
    }
  };

  const columnChartOptions1 = {
    title: {
      text: "Department Student"
    },
    height: 310,
    data: [{
      type: "column",
      dataPoints: getStudentData(selectedYear)
    }]
  };

  const columnChartOptions2 = {
    title: {
      text: "Department Staffs"
    },
    height: 310,
    data: [{
      type: "column",
      dataPoints: getStaffData(selectedYear)
    }]
  };

  return (
    <div className="main-inner-wrapper dashboard-wrapper ">
      {/* Summary Cards and Year Filter */}
      <div className="ticket-card__wrapper flex flex-wrap justify-around items-center gap-4 lg:gap-6 px-4 overflow-hidden">
        <div className="ticket-card flex p-4 rounded-lg justify-between bg-blue-600">
          <div className="ticket-card-content">
            <h5 className='m-0 text-2xl text-white font-bold'>Total Students</h5>
            <h5 className='m-0 text-2x1 text-white font-bold' style={{ fontSize: "20px" }}>550</h5>
          </div>
        </div>

        {/* Year Filter */}
        <div className="flex items-center gap-2">
          <span className='text-xl font-bold'>Filter by year:</span>
          <Dropdown>
            <DropdownTrigger>
              <Button>{selectedYear}</Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Year Selection">
              <DropdownItem key="1stYear" onClick={() => handleYearChange('1st Year')}>1st Year</DropdownItem>
              <DropdownItem key="2ndYear" onClick={() => handleYearChange('2nd Year')}>2nd Year</DropdownItem>
              <DropdownItem key="3rdYear" onClick={() => handleYearChange('3rd Year')}>3rd Year</DropdownItem>
              <DropdownItem key="4thYear" onClick={() => handleYearChange('4th Year')}>4th Year</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>

        <div className="ticket-card flex p-4 rounded-lg justify-between bg-green-600">
          <div className="ticket-card-content">
            <h5 className='m-0 text-2xl text-white font-bold'>Total Staffs</h5>
            <h5 className='m-0 text-2x1 text-white font-bold' style={{ fontSize: "20px" }}>90</h5>
          </div>
        </div>
      </div>

      {/* Charts */}
      <div className="grid md:grid-cols-2 gap-0 lg:gap-0 px-2">
        <div className="col-span-1 p-4">
          <CanvasJSChart options={columnChartOptions1} />
        </div>
        <div className="col-span-1 p-4">
          <CanvasJSChart options={columnChartOptions2} />
        </div>
      </div>
    </div>
  )
}

export default AdminDashboard;
