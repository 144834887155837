import React, { useState, useRef } from 'react'
import { useTheme } from 'next-themes';
import SubjectTable from '../components/baseComponent/SubjectTable';
import SubjectForm from '../components/baseComponent/SubjectForm';

const Subject = () => {
    const { theme } = useTheme();

    const fileInputRef = useRef(null);

    const handleDownloadClick = () => {
        fileInputRef.current.click();
    };


    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="rechart__custom-tooltip bg-black dark:bg-white">
                    <p className="value text-sm m-0 text-white dark:text-black">{`${label} month no of leaves ${payload[0]?.value}`}</p>
                </div>
            );
        }

        return null;
    };

    const [isModelBox, setIsModelBox] = useState(false);

    const handleModelBoxClick = () => {
        setIsModelBox(!isModelBox)
    }

    return (
        <div className="main-inner-wrapper leavemenu-wrapper">
            <div className="px-4 mt-10">
                <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl" style={{ marginTop: '-50px' }}>
                    <div className="flex justify-end items-center gap-4">
                        <div className="header-wrapper">
                            {/* <h1>Travel Detail List</h1> */}
                        </div>
                        <button className="py-2 px-3 bg-indigo-500 text-xs font-semibold rounded-md shadow focus:outline-none incidentSubmitBtn" onClick={handleModelBoxClick}>
             Add Subject
            </button>
                        {/* <button className="py-2 px-3 bg-indigo-500 hover:bg-indigo-800 text-white text-xs font-semibold rounded-md shadow focus:outline-none"  onClick={handleDownloadClick} >
              Download From Excel
            </button>
            <input type='file' ref={fileInputRef} hidden></input> */}
                    </div>
                    <SubjectTable />
                </div>
            </div>
            <SubjectForm isOpen={isModelBox} onOpenChange={handleModelBoxClick} backdrop="opaque"/>
        </div>

    )
}

export default Subject
