import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Input,
    DatePicker,
    Textarea,
    Select,
    SelectItem,
} from "@nextui-org/react";
import "../components/baseComponent/ModelForm.css";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const StaffAddForm = () => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState("");
    const [selectedRole, setSelectedRole] = useState("");
    const [staffId, setStaffId] = useState(""); // State for staff ID
    const [fileName, setFileName] = useState(""); // State to hold file name

    const fileInputRef = useRef(null);

    const departments = {
        admin: "ADM",
        coe: "COE",
        cse: "CSE",
        ece: "ECE",
        mechanical: "MECH",
        civil: "CIVIL",
        chemical: "CHEM",
        aids: "AIDS",
        it: "IT",
    };

    const roles = [
        "Administrative Staff",
        "Examination Officer",
        "Head of Department",
        "Coordinator",
        "Professor",
        "Assistant Professor",
        "Lecturer",
        "Lab Assistant",
    ];

    const handleDepartmentChange = (value) => {
        setSelectedDepartment(value);
        generateStaffId(value, selectedRole);
    };

    const handleRoleChange = (value) => {
        setSelectedRole(value);
        generateStaffId(selectedDepartment, value);
    };

    const generateStaffId = (department, role) => {
        if (department && role) {
            const deptCode = departments[department];
            const roleCode = role.split(" ").map(word => word.charAt(0)).join("");
            const uniqueNumber = Math.floor(Math.random() * 10000);
            setStaffId(`${deptCode}-${roleCode}-${uniqueNumber}`);
        } else {
            setStaffId("");
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setFileName(file ? file.name : "");
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent form from refreshing the page
        // Add your form submission logic here
        // console.log("Form submitted!");

        // Show success toast
        toast.success("Staff Created Successfully!");
    };

    return (
        <div className="main-inner-wrapper leavemenu-wrapper">
            <div className="px-4">
                <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
                    <div className="flex justify-between items-center gap-4">
                        <h1
                            className="report-heading"
                            style={{
                                marginLeft: "0px",
                                marginBottom: "10px",
                                fontSize: "25px",
                                fontWeight: "bold",
                            }}
                        >
                            Staff Creation
                        </h1>

                        <button className="py-2 mb-4 px-3 bg-indigo-500  text-xs font-semibold rounded-md shadow focus:outline-none incidentSubmitBtn" onClick={triggerFileInput}>
                            Import from excel
                        </button>
                        <input type='file' ref={fileInputRef} hidden onChange={handleFileChange} />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <div className="form-row">
                                <Input variant="bordered" label="Staff Name" />
                                <DatePicker
                                    variant="bordered"
                                    label="Date of Birth"
                                />
                            </div>
                            <div className="form-row">
                                <Select
                                    label="Gender"
                                    className="max-w-full"
                                    variant="bordered"
                                >
                                    <SelectItem key="male" value="male">
                                        Male
                                    </SelectItem>
                                    <SelectItem key="female" value="female">
                                        Female
                                    </SelectItem>
                                </Select>
                                <Input
                                    variant="bordered"
                                    label="Contact Number"
                                    type="number"
                                />
                            </div>
                            <div className="form-row">
                                <Input
                                    variant="bordered"
                                    label="Previous Employment"
                                />
                                <Input
                                    variant="bordered"
                                    label="Highest Qualification"
                                />
                            </div>
                            <div className="form-row">
                                <Select
                                    label="Department"
                                    className="max-w-full"
                                    variant="bordered"
                                    onChange={(e) => handleDepartmentChange(e.target.value)}
                                    value={selectedDepartment}
                                >
                                    {Object.keys(departments).map((dept) => (
                                        <SelectItem key={dept} value={dept}>
                                            {dept.toUpperCase()}
                                        </SelectItem>
                                    ))}
                                </Select>
                                <Select
                                    label="Role"
                                    className="max-w-full"
                                    variant="bordered"
                                    onChange={(e) => handleRoleChange(e.target.value)}
                                    value={selectedRole}
                                >
                                    {roles.map((role, index) => (
                                        <SelectItem key={index} value={role}>
                                            {role}
                                        </SelectItem>
                                    ))}
                                </Select>
                            </div>

                            <div className="form-row">
                                <Input
                                    variant="bordered"
                                    label="Staff ID"
                                    value={staffId}
                                    readOnly
                                />

                                <input
                                    type="file"
                                    id="hiddenFileInput"
                                    style={{ display: "none" }}
                                    onChange={handleFileChange}
                                />
                                <Input
                                    variant="bordered"
                                    label="Photograph"
                                    value={fileName}
                                    onClick={triggerFileInput}
                                    readOnly
                                />

                            </div>
                            <div className="form-row">
                                <Input
                                    variant="bordered"
                                    label="Email"
                                    type="email"
                                />
                                <DatePicker
                                    variant="bordered"
                                    label="Date of Joining"
                                />
                            </div>
                            <div className="form-row">
                                <Input
                                    variant="bordered"
                                    label="Emergency Contact Number"
                                />
                                <Input
                                    variant="bordered"
                                    label="Parent Name or Spouse Name"
                                />
                            </div>
                            <div className="form-row">
                                <Textarea
                                    variant="bordered"
                                    label="Current Address"
                                />
                                <Textarea
                                    variant="bordered"
                                    label="Permanent Address"
                                />
                            </div>
                            <div className="flex justify-center items-center gap-4">
                                <Button className="incidentSubmitBtn" type="submit">
                                    Submit
                                </Button>
                                <Button color="danger" variant="flat">
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default StaffAddForm;
