import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "@nextui-org/react";
import "../components/baseComponent/ModelForm.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const InternalMarkEntry = () => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState(""); // State to hold file name
    const fileInputRef = useRef(null);
    const [formData, setFormData] = useState({
        registerNumber: "",
        name: "",
        department: "",
        subjectCode: "",
        assignmentMarks: "",
        theoryMarks: "",
        quizMark: "",
        attendance: "",
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        // Show success toast message
        toast.success("Internal marks submitted successfully!");
    
        // Add any other form submission logic here
        // For example, you might navigate to another page or reset the form
        // navigate("/student/Subjectdetails");
    };
    

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setFileName(file ? file.name : "");
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    return (
        <div className="main-inner-wrapper leavemenu-wrapper">
            <ToastContainer /> {/* Add ToastContainer here */}
            <div className="px-4">
                <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
                    <div className="flex justify-between items-center gap-4">
                        <h1
                            className="report-heading"
                            style={{
                                marginLeft: "0px",
                                marginBottom: "40px",
                                fontSize: "25px",
                                fontWeight: "bold",
                            }}
                        >
                            Internal Mark Entry
                        </h1>
    
                        <button className="py-2 mb-4 px-3 bg-indigo-500 text-xs font-semibold rounded-md shadow focus:outline-none incidentSubmitBtn" onClick={triggerFileInput}>
                            Import from excel
                        </button>
                        <input type="file" ref={fileInputRef} hidden onChange={handleFileChange} />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <Input
                                variant="bordered"
                                label={`Register Number ${!formData.registerNumber ? "*" : ""}`}
                                required
                                name="registerNumber"
                                value={formData.registerNumber}
                                onChange={handleInputChange}
                            />
                            <Input
                                label={`Name `}
                                readOnly
                                required
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-row">
                            <Input
                                label={`Department `}
                                readOnly
                                required
                                name="department"
                                value={formData.department}
                                onChange={handleInputChange}
                            />
                            <Input
                                variant="bordered"
                                label={`Subject Code ${!formData.subjectCode ? "*" : ""}`}
                                required
                                name="subjectCode"
                                value={formData.subjectCode}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-row">
                            <Input
                                variant="bordered"
                                label={`Assignment Marks ${!formData.assignmentMarks ? "*" : ""}`}
                                type="number"
                                required
                                name="assignmentMarks"
                                value={formData.assignmentMarks}
                                onChange={handleInputChange}
                            />
                            <Input
                                variant="bordered"
                                label={`Theory Marks ${!formData.theoryMarks ? "*" : ""}`}
                                type="number"
                                required
                                name="theoryMarks"
                                value={formData.theoryMarks}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-row">
                            <Input
                                variant="bordered"
                                label={`Quiz Mark ${!formData.quizMark ? "*" : ""}`}
                                type="number"
                                required
                                name="quizMark"
                                value={formData.quizMark}
                                onChange={handleInputChange}
                            />
                            <Input
                                variant="bordered"
                                label={`Attendance (%) ${!formData.attendance ? "*" : ""}`}
                                required
                                name="attendance"
                                value={formData.attendance}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="flex justify-center items-center gap-4">
                            <Button className="incidentSubmitBtn" type="submit">
                                Submit
                            </Button>
                            <Button color="danger" variant="flat">
                                Cancel
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
    
};

export default InternalMarkEntry;
