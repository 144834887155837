import { Route, Routes } from "react-router-dom";
import Layout from './components/groupComponent/layout';
import Providers from "./components/contentApi/Providers";

import Dashboard1 from "./page/Dashboard1";
import { MarkProvider } from "./data/Context";
import ForgetPassword from "./page/auth/ForgetPassword";
import LandingPage from "./page/auth/LandingPage";
import MarkEntry from "./page/MarkEntry";
import MarkEntry2 from "./page/MarkEntry2";
import ApplyExaminationForm from "./page/ApplyForExaminationForm";
import PaymentExamPage from "./page/PaymentExamPage";
import SubjectPaymentDetailsForm from "./page/SubjectPaymentDetailsForm";
import ExamTimetable from "./page/ExamTimetable";
import PaymentDisplayScreenPage from "./page/PaymentDisplayScreen";
import SeatArrangement from "./page/SeatArrangement";
import Login from "./page/auth/Login";
import MarkSetup from "./page/MarkSetup";
import ResultTable from "./page/ResultTable";
import StudentAddForm from "./page/StudentAddForm";
import User from "./page/User";
import DummyNumber from "./page/DummyNumber";
import HallTicket from "./page/HallTicket1";
import StaffAddForm from "./page/StaffAddForm";
import AdminDashboard from "./page/AdminDashboard";
import HallTicketDownlode from "./page/HallTicketDownlode";
import Role from "./page/Role";
import Department from "./page/Department";
import InternalMarkEntry from "./page/InternalMarkEntry";
import InternalMarkSetup from "./page/InternalMarkSetup";
import Feesetup from "./page/Feesetup";
import StudentResult from "./page/StudentResult";
import Roleaccess from "./page/RoleaccessForm";
import GradeSetup from "./page/GradeSetup";
import MarkModeration from "./page/MarkModeration";
import Subject from "./page/Subject";
import IssueResult from "./page/IssueResult";
import RevalutionSubjectTable from "./page/RevalutionList";
import ExamTimetableSetup from "./page/ExamTimetableSetup";
import RevaluationReview from "./page/RevaluationReview";
import RevaluationEdit from "./page/RevaluationEdit";
import ArrearHistory from "./page/ArrearHistory";
import Arrearcoe from "./page/Arrearcoe";
import Arrearstaff from "./page/Arrearstaff";
import DownloadStudent from "./page/Download";
import StudentPlacement from "./page/StudentPlacement";
import Placement from "./page/Placement";
import PlacementReport from "./page/PlacementReport";
import PlacementRequirement from "./page/PlacementRequirement";
import DashboardPlacement from "./page/DashboardPlacement";





function App() {

  return (
    <>
      <MarkProvider>
        <Providers>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="login" element={<Login />} />
            <Route path="/forget-password" element={<ForgetPassword />} />


            {/* Student Login */}

            <Route path="/student" element={<Layout />}>
              <Route path="examtimetable" element={<ExamTimetable />} />
              <Route path="applyexamination" element={<ApplyExaminationForm />} />
              <Route path="Subjectdetails" element={<SubjectPaymentDetailsForm />} />
              <Route path="paymentforexam" element={<PaymentExamPage />} />
              <Route path="paymentdisplay" element={<PaymentDisplayScreenPage />} />
              <Route path="hallticketdownlode" element={<HallTicketDownlode />} />
              <Route path="arrear" element={<ArrearHistory />} />
              <Route path="Studentresult" element={<StudentResult />} />
              <Route path="revaluation" element={<RevalutionSubjectTable />} />
              <Route path="download" element={<DownloadStudent />} />


              {/* --------------------------------------------------------- */}
              {/* <Route path="placement" element={<Placement/>} />    */}
              <Route path="studentplacement" element={<StudentPlacement />} />
              {/* <Route path="placementreport" element={<PlacementReport />} /> */}
              {/* <Route path="placementrequirement" element={<PlacementRequirement />} /> */}
              {/* <Route path="dashboardplacement" element={<DashboardPlacement />} /> */}




            </Route>

            {/* Placement login */}

            <Route path="/placement" element={<Layout />}>
      
            <Route path="placement" element={<Placement/>} />
              <Route path="placementreport" element={<PlacementReport />} />
              <Route path="placementrequirement" element={<PlacementRequirement />} />
              <Route path="dashboardplacement" element={<DashboardPlacement />} />
            </Route>

            {/* Staff Login */}

            <Route path="/staff" element={<Layout />}>
              <Route path="dashboard1" element={<Dashboard1 />} />
              <Route path="markentry" element={<MarkEntry2 />} />
              <Route path="result" element={<ResultTable />} />
              <Route path="internalmarkentry" element={<InternalMarkEntry />} />
              <Route path="revaluation" element={<RevaluationEdit />} />
              <Route path="arrear" element={<Arrearstaff />} />

            </Route>

            {/* COE Login */}

            <Route path="/coe" element={<Layout />}>
              <Route path="dashboard1" element={<Dashboard1 />} />
              <Route path="seatarrangement" element={<SeatArrangement />} />
              <Route path="markEntry" element={<MarkSetup />} />
              <Route path="dummynumber" element={<DummyNumber />} />
              <Route path="hallticket" element={<HallTicket />} />
              <Route path="internamarksetup" element={<InternalMarkSetup />} />
              <Route path="feesetup" element={<Feesetup />} />
              <Route path="gradesetup" element={<GradeSetup />} />
              <Route path="markmoderation" element={<MarkModeration />} />
              <Route path="issueresult" element={<IssueResult />} />
              <Route path="timetablesetup" element={<ExamTimetableSetup />} />
              <Route path="revaluation" element={<RevaluationReview />} />
              <Route path="Arrear" element={<Arrearcoe />} />







            </Route>

            {/* Admin Login */}
            <Route path="/admin" element={<Layout />}>
              <Route path="admindashboard" element={<AdminDashboard />} />
              <Route path="studentacreation" element={<StudentAddForm />} />
              <Route path="staffcreation" element={<StaffAddForm />} />
              <Route path="userlist" element={<User />} />
              <Route path="role" element={<Role />} />
              <Route path="department" element={<Department />} />
              <Route path="roleaccess" element={<Roleaccess/>} />
              <Route path="subject" element={<Subject/>} />





            </Route>


            <Route path="*" element={<LandingPage />} />
          </Routes>
        </Providers>
      </MarkProvider>
    </>
  );
}

export default App;