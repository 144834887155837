import React, { useState } from "react";
import {
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import CanvasJSReact from "@canvasjs/react-charts";

const dept = ["Civil", "CSE", "EEE", "Mechanical"]
const sem = ['1st Semester', '2nd Semester', '3rd Semester', '4th Semester']
const yea = ['1st Year', '2nd Year', '3rd Year', '4th Year']

const Dashboard1 = () => {
  const [selectedItem, setSelectedItem] = useState('1st Year');
  const navigate = useNavigate();

  const handleChennaiClick = () => {
    navigate("/greece/chennaidashboard");
  };

  const CanvasJS = CanvasJSReact.CanvasJS;
  const CanvasJSChart = CanvasJSReact.CanvasJSChart;

  const pieChartOptions = {
    exportEnabled: true,
    animationEnabled: true,
    title: {
      text: "Result Percentage",
    },
    height: 300,
    data: [
      {
        type: "pie",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y}%",
        showInLegend: "true",
        legendText: "{label}",
        indexLabelFontSize: 16,
        indexLabel: "{label} - {y}%",
        dataPoints: [
          { y: 78.85, label: "Passed" },
          { y: 21.14, label: "Failed" },
        ],
      },
    ],
  };

  const handleSelectItemChange = (year) => {
    setSelectedItem(year);
  };

  const getStudentData = (year) => {
    switch (year) {
      case '1st Year':
        return [
          { label: "IT", y: 95 },
          { label: "Mech", y: 60 },
          { label: "ECE", y: 75 },
          { label: "CSE", y: 90 }
        ];
      case '2nd Year':
        return [
          { label: "IT", y: 90 },
          { label: "Mech", y: 80 },
          { label: "ECE", y: 70 },
          { label: "CSE", y: 75 }
        ];
      case '3rd Year':
        return [
          { label: "IT", y: 95 },
          { label: "Mech", y: 82 },
          { label: "ECE", y: 78 },
          { label: "CSE", y: 79 }
        ];
      case '4th Year':
        return [
          { label: "IT", y: 85 },
          { label: "Mech", y: 79 },
          { label: "ECE", y: 72 },
          { label: "CSE", y: 74 }
        ];
      case '1st Semester':
        return [
          { label: "IT", y: 90 },
          { label: "Mech", y: 60 },
          { label: "ECE", y: 70 },
          { label: "CSE", y: 80 }
        ];
      case '2nd Semester':
        return [
          { label: "IT", y: 85 },
          { label: "Mech", y: 75 },
          { label: "ECE", y: 65 },
          { label: "CSE", y: 70 }
        ];
      case '3rd Semester':
        return [
          { label: "IT", y: 95 },
          { label: "Mech", y: 85 },
          { label: "ECE", y: 75 },
          { label: "CSE", y: 85 }
        ];
      case '4th Semester':
        return [
          { label: "IT", y: 80 },
          { label: "Mech", y: 70 },
          { label: "ECE", y: 60 },
          { label: "CSE", y: 70 }
        ];
      case 'Civil':
        return [
          { label: "1st Year", y: 85 },
          { label: "2nd Year", y: 70 },
          { label: "3rd Year", y: 75 },
          { label: "4th Year", y: 90 }
        ];
      case 'Mechanical':
        return [
          { label: "1st Year", y: 80 },
          { label: "2nd Year", y: 70 },
          { label: "3rd Year", y: 60 },
          { label: "4th Year", y: 75 }
        ];
      case 'EEE':
        return [
          { label: "1st Year", y: 90 },
          { label: "2nd Year", y: 80 },
          { label: "3rd Year", y: 70 },
          { label: "4th Year", y: 95 }
        ];
      case 'CSE':
        return [
          { label: "1st Year", y: 75 },
          { label: "2nd Year", y: 65 },
          { label: "3rd Year", y: 55 },
          { label: "4th Year", y: 80 }
        ];
      default:
        return [];
    }
  };

  const columnChartOptions = {
    title: {
      text: "Department Student"
    },
    height: 300,
    data: [{
      type: "column",
      dataPoints: getStudentData(selectedItem)
    }]
  };

  return (
    <>
      <div className="flex flex-col h-screen overflow-hidden">
        <div className="ticket-card__wrapper flex flex-wrap gap-4 px-4 mt-2" style={{ overflow: 'hidden' }}>
          <div className="ticket-card flex p-2 rounded-lg justify-between items-center bg-blue-500 " style={{ flex: "1 1 18%", maxWidth: "calc(20% - 1rem)" }}>
            <div className="ticket-card-content">
              <h5 className="m-0 text-2xl text-white font-bold">Total Students Appeared</h5>
              <h5 className="m-0 text-white font-bold" style={{ fontSize: "20px" }}>350</h5>
            </div>
          </div>
          <div className="ticket-card flex p-4 rounded-lg justify-between items-center bg-green-500" style={{ flex: "1 1 18%", maxWidth: "calc(20% - 1rem)" }}>
            <div className="ticket-card-content">
              <h5 className="m-0 text-2xl text-white font-bold">Passed</h5>
              <h5 className="m-0 text-white font-bold" style={{ fontSize: "20px" }}>276</h5>
            </div>
          </div>
          <div className="ticket-card flex p-4 rounded-lg justify-between items-center bg-red-500" style={{ flex: "1 1 18%", maxWidth: "calc(20% - 1rem)" }}>
            <div className="ticket-card-content">
              <h5 className="m-0 text-2xl text-white font-bold">Failed</h5>
              <h5 className="m-0 text-white font-bold" style={{ fontSize: "20px" }}>74</h5>
            </div>
          </div>
          <div className="ticket-card flex p-4 rounded-lg justify-between items-center bg-yellow-500" style={{ flex: "1 1 18%", maxWidth: "calc(20% - 1rem)" }}>
            <div className="ticket-card-content">
              <h5 className="m-0 text-2xl text-white font-bold">Pass Percentage</h5>
              <h5 className="m-0 text-white font-bold" style={{ fontSize: "20px" }}>78.85%</h5>
            </div>
          </div>
          <div className="ticket-card flex p-4 rounded-lg justify-between items-center bg-orange-500" style={{ flex: "1 1 18%", maxWidth: "calc(20% - 1rem)" }}>
            <div className="ticket-card-content">
              <h5 className="m-0 text-2xl text-white font-bold">Fail Percentage</h5>
              <h5 className="m-0 text-white font-bold" style={{ fontSize: "20px" }}>21.14%</h5>
            </div>
          </div>
          <div className="flex items-center gap-2 mt-4">
            <span className='text-xl font-bold'>Filter by year:</span>
            <Dropdown>
              <DropdownTrigger>
                <Button>{yea.includes(selectedItem) ? selectedItem : "Year"}</Button>
              </DropdownTrigger>
              <DropdownMenu aria-label="Year Selection">
                <DropdownItem key="1stYear" onClick={() => handleSelectItemChange('1st Year')}>1st Year</DropdownItem>
                <DropdownItem key="2ndYear" onClick={() => handleSelectItemChange('2nd Year')}>2nd Year</DropdownItem>
                <DropdownItem key="3rdYear" onClick={() => handleSelectItemChange('3rd Year')}>3rd Year</DropdownItem>
                <DropdownItem key="4thYear" onClick={() => handleSelectItemChange('4th Year')}>4th Year</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="flex items-center gap-2 mt-4">
            <span className='text-xl font-bold'>Filter by Dept:</span>
            <Dropdown>
              <DropdownTrigger>
                <Button>{dept.includes(selectedItem) ? selectedItem : "Department"}</Button>
              </DropdownTrigger>
              <DropdownMenu aria-label="Year Selection">
                <DropdownItem key="Civil" onClick={() => handleSelectItemChange('Civil')}>Civil</DropdownItem>
                <DropdownItem key="Mechanical" onClick={() => handleSelectItemChange('Mechanical')}>Mechanical</DropdownItem>
                <DropdownItem key="CSE" onClick={() => handleSelectItemChange('CSE')}>CSE</DropdownItem>
                <DropdownItem key="EEE" onClick={() => handleSelectItemChange('EEE')}>EEE</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="flex items-center gap-2 mt-4">
            <span className='text-xl font-bold'>Filter by Semester:</span>
            <Dropdown>
              <DropdownTrigger>
                <Button>{sem.includes(selectedItem) ? selectedItem : "Semester"}</Button>
              </DropdownTrigger>
              <DropdownMenu aria-label="Year Selection">
                <DropdownItem key="1stSemester" onClick={() => handleSelectItemChange('1st Semester')}>1st Semester</DropdownItem>
                <DropdownItem key="2ndSemester" onClick={() => handleSelectItemChange('2nd Semester')}>2nd Semester</DropdownItem>
                <DropdownItem key="3rdSemester" onClick={() => handleSelectItemChange('3rd Semester')}>3rd Semester</DropdownItem>
                <DropdownItem key="4thSemester" onClick={() => handleSelectItemChange('4th Semester')}>4th Semester</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-4 px-2 mt-4">
          <div className="col-span-1 p-4">
            <CanvasJSChart options={columnChartOptions} />
          </div>
          <div className="col-span-1 p-4">
            <CanvasJSChart options={pieChartOptions} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard1;
