import React, { useState } from "react";
import { Button, Input, Select, SelectItem } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { FaUniversity, FaCreditCard, FaQrcode } from "react-icons/fa";
import "../components/baseComponent/ModelForm.css";

const PaymentExamPage = () => {
  const navigate = useNavigate();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [upiNumber, setUpiNumber] = useState("");
  const [selectedBank, setSelectedBank] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');

  const handlePaymentMethodClick = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
    setUpiNumber("");
    setSelectedBank("");
    setCardNumber("");
    setExpiryDate("");
    setCvv("");
  };

  const handleBankChange = (e) => {
    setSelectedBank(e.target.value);
  };

  const renderPaymentContent = () => {
    switch (selectedPaymentMethod) {
      case "UPI":
        return (
          <div>
            <h2 style={{ fontWeight: "bold", color: "rgb(54, 162, 235)", fontSize: "20px" }}>UPI Payment</h2>
            <p className="mt-4">Enter your UPI number below:</p>
            <div className="form-row-directpage mt-4" style={{ maxWidth: "45%" }}>
              <Input
                variant="bordered"
                label="UPI"
                maxLength={20}
                value={upiNumber}
                onChange={(e) => setUpiNumber(e.target.value)}
              />
            </div>
            <Button
              className="incidentSubmitBtn font-bold"
              onClick={() => navigate("/student/paymentdisplay")}
              style={{ marginTop: "20px", display: 'block' }}
            >
              Continue
            </Button>
            <p style={{ marginTop: "20px", color: "gray", fontSize: "16px" }}>
              The payment process adheres to the <strong>PCI DSS</strong> certification
            </p>
          </div>
        );
      case "NetBanking":
        return (
          <div>
            <h2 style={{ fontWeight: "bold", color: "rgb(54, 162, 235)", fontSize: "20px" }}>Net Banking Payment</h2>
            <p className="mt-4">Select your bank to proceed with Net Banking:</p>
            <div className="mt-4">
              <Select
                placeholder="Select Bank"
                value={selectedBank}
                onChange={handleBankChange}
                style={{ maxWidth: "50%" }}
              >
                <SelectItem value="HDFC Bank">HDFC Bank</SelectItem>
                <SelectItem value="ICICI Bank">ICICI Bank</SelectItem>
                <SelectItem value="State Bank of India">State Bank of India</SelectItem>
                <SelectItem value="Axis Bank">Axis Bank</SelectItem>
                <SelectItem value="Kotak Mahindra Bank">Kotak Mahindra Bank</SelectItem>
              </Select>
            </div>
            <Button
              className="incidentSubmitBtn font-bold"
              onClick={() => navigate("/student/paymentdisplay")}
              style={{ marginTop: "20px", display: 'block' }}
            >
              Continue
            </Button>
            <p style={{ marginTop: "20px", color: "gray", fontSize: "16px" }}>
              The payment process adheres to the <strong>PCI DSS</strong> certification
            </p>
          </div>
        );
      case "Card":
        return (
          <div>
            <h2 style={{ fontWeight: "bold", color: "rgb(54, 162, 235)", fontSize: "20px" }}>Card Payment</h2>
            <p className="mt-4">Enter your card details below:</p>
            <div className="mt-4">
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', maxWidth: "400px" }}>
                <Input
                  variant="bordered"
                  label="Card Number"
                  maxLength={15}
                  value={cardNumber}
                  onChange={(e) => setCardNumber(e.target.value)}
                />
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Input
                    variant="bordered"
                    label="Valid Thru MM/YY"
                    value={expiryDate}
                    onChange={(e) => setExpiryDate(e.target.value)}
                    style={{ flex: 1 }}
                  />
                  <Input
                    variant="bordered"
                    label="CVV"
                    value={cvv}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value) && value.length <= 3) {
                        setCvv(value);
                      }
                    }}
                    type="text"
                    style={{ flex: 1 }}
                  />
                </div>
              </div>
              <Button
                className="incidentSubmitBtn font-bold"
                onClick={() => navigate("/student/paymentdisplay")}
                style={{ marginTop: "20px", display: 'block', }}
              >
                Continue
              </Button>
              <p style={{ marginTop: "20px", color: "gray", fontSize: "16px" }}>
                The payment process adheres to the <strong>PCI DSS</strong> certification
              </p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="main-inner-wrapper leavemenu-wrapper">
      <div className="px-4">
        <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
          <h1
            className="report-heading"
            style={{
              marginLeft: "0px",
              marginBottom: "40px",
              fontSize: "25px",
              fontWeight: "bold",
            }}
          >
            Payment Page
          </h1>
          <div style={{ display: "flex" }}>
            {/* Left Column - Payment Methods */}
            <div style={{ width: "30%", marginRight: "20px" }}>
              <Button
                className={`payment-method-button incidentSubmitBtn mb-4 font-bold ${selectedPaymentMethod === "UPI" ? "active" : ""}`}
                onClick={() => handlePaymentMethodClick("UPI")}
                style={{ display: "flex", alignItems: "center" }}
              >
                <FaQrcode size={24} style={{ marginRight: "8px" }} />
                UPI
              </Button>
              <Button
                className={`payment-method-button incidentSubmitBtn mb-4 font-bold ${selectedPaymentMethod === "NetBanking" ? "active" : ""}`}
                onClick={() => handlePaymentMethodClick("NetBanking")}
                style={{ display: "flex", alignItems: "center" }}
              >
                <FaUniversity size={24} style={{ marginRight: "8px" }} />
                Net Banking
              </Button>
              <Button
                className={`payment-method-button incidentSubmitBtn mb-4 font-bold ${selectedPaymentMethod === "Card" ? "active" : ""}`}
                onClick={() => handlePaymentMethodClick("Card")}
                style={{ display: "flex", alignItems: "center" }}
              >
                <FaCreditCard size={24} style={{ marginRight: "8px" }} />
                Card
              </Button>
            </div>

            {/* Right Column - Payment Content */}
            <div style={{ width: "70%" }}>{renderPaymentContent()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentExamPage;
